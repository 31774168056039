import React from 'react'
import PropTypes from 'prop-types'

import {
  length,
} from 'ramda'

// Material UI
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'

const styles = theme => ({
  field: {
    margin: theme.spacing()
  }
})

const GridFilterSelectField = ({ classes, options, filter, onFilter }) => (
  <TextField
    className={classes.field}
    fullWidth
    select
    SelectProps={{
      multiple: true
    }}
    value={filter ? filter.value : []}
    onChange={e => onFilter(
      length(e.target.value)
      ? { value: e.target.value }
      : null
    )}
  >
    {options.map((option, key) => (
      <MenuItem key={key} value={option}>
        {option}
      </MenuItem>
    ))}
  </TextField>
)

GridFilterSelectField.propTypes = {
  options: PropTypes.array.isRequired,
  filter: PropTypes.shape({
    value: PropTypes.array.isRequired,
  }),
  onFilter: PropTypes.func.isRequired,
}

export default withStyles(styles)(GridFilterSelectField)
