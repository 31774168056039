import React from 'react'
import PropTypes from 'prop-types'

import {
  length, pipe, pluck, sum, reject, last,
  sortBy, compose, toLower, prop, isEmpty
} from 'ramda'
import moment from 'moment'
import { encode } from 'html-entities'

// Material UI
import { withStyles } from '@material-ui/core/styles'
import { yellow, red } from '@material-ui/core/colors'

import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Input from '@material-ui/core/Input'

const highlight = string => {
  const canHighlight = !!string.match(/\(([^)]+)\)/)
  const stringHtmlEntities = encode(string)

  if (canHighlight) {
    return stringHtmlEntities.replaceAll('(', `<span style="color: ${red['A400']}">(`).replaceAll(')', ')</span>')
  }

  return stringHtmlEntities
}

const extractVintage = string => {
  const numbers = string.match(/\d+/g)
  const vintage = pipe(
    reject(x => x.toString().length !== 4),
    last
  )(numbers || [])
  if (vintage) return vintage
  return ''
}

const styles = theme => ({
  container: {
    pageBreakAfter: 'always',
    padding: theme.spacing(2),
    margin: 0,
    width: '100%',
  },
  table: {
    tableLayout: 'fixed'
  },
  cell: {
    fontSize: 10,
  },
  cellLast: {
    paddingTop: theme.spacing(),
    paddingRight: theme.spacing(0.5),
    paddingBottom: theme.spacing(),
    paddingLeft: theme.spacing(0.5),
  },
  noWrap: {
    whiteSpace: 'nowrap',
  },
  barcode: {
    fontSize: 8,
  },
  bold: {
    fontWeight: 700,
  },
  warning: {
    color: theme.palette.warning.dark,
  },
  error: {
    color: theme.palette.error.main,
  },
  input: {
    // backgroundColor: yellow['A100'],
    border: '1px solid rgba(0, 0, 0, 0.15)',
    color: 'rgba(0, 0, 0, 0.15)',
    textAlign: 'center',
    fontSize: 14,
  }
})


const PrintDelivery = ({
  classes,
  supplier,
  products,
}) => {
  const sortedProducts = sortBy(compose(toLower, prop('description')))(products)
  const totalProducts = length(products)
  const totalQuantity = pipe(pluck('total_notified'), sum)(products)

  return (
    <Grid className={classes.container} container spacing={2}>
      <Grid item xs={6}>
        <Typography variant="body1" gutterBottom>
          <strong className={classes.bold}>{'Supplier:'}</strong> {supplier}
        </Typography>
        <Typography variant="body1" gutterBottom>
          <strong className={classes.bold}>{'Date Printed:'}</strong> {moment().format('DD/MM/YYYY H:mma')}
        </Typography>
        <Typography variant="body1" gutterBottom>
          <strong className={classes.bold}>{'Total Products:'}</strong> {totalProducts}
        </Typography>
        <Typography variant="body1" gutterBottom>
          <strong className={classes.bold}>{'Total Quantity:'}</strong> {totalQuantity}
        </Typography>
      </Grid>
      {sortedProducts && (
        <Grid item xs={12}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell width={80} classes={{ root: classes.cell }} className={classes.noWrap} padding="none">
                  {'SKU'}
                </TableCell>
                <TableCell nowrap="nowrap" classes={{ root: classes.cell }} className={classes.noWrap} padding="none">
                  {'Description'}
                </TableCell>
                {
                // <TableCell classes={{ root: classes.cell }} className={classes.noWrap} padding="none">
                //   {'Location'}
                // </TableCell>
                }
                <TableCell width={60} classes={{ root: classes.cell }} className={classes.noWrap} padding="none" align="center">
                  {'Expected'}
                </TableCell>
                <TableCell width={60} classes={{ root: classes.cell }} className={classes.noWrap} padding="none" align="center">
                  {'Quantity'}
                </TableCell>
                <TableCell width={60} classes={{ root: classes.cell }} className={classes.noWrap} padding="none" align="center">
                  {'Vintage'}
                </TableCell>
                <TableCell width={60} classes={{ root: classes.cell }} className={classes.noWrap} padding="none" align="center">
                  {'Required'}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedProducts.map(product => (
                <TableRow key={product.id}>
                  <TableCell classes={{ root: classes.cell }} className={classes.noWrap} padding="none">
                    {product.sku}<br />
                    {
                      isEmpty(product.barcode)
                      ? (
                        <span className={`${classes.barcode} ${classes.error}`}>
                          {'missing barcode'}
                        </span>
                      )
                      : (
                        <span className={`${classes.barcode} ${!Number(product.barcode) ? classes.warning : null}`}>
                          {product.barcode ? product.barcode.toLowerCase() : product.barcode}
                        </span>
                      )
                    }
                  </TableCell>
                  <TableCell
                    classes={{ root: classes.cell }}
                    padding="none"
                    dangerouslySetInnerHTML={{ __html: highlight(product.description) }}
                  />
                  {
                  // <TableCell classes={{ root: classes.cell }} className={classes.noWrap} padding="none">
                  //   {product.location}
                  // </TableCell>
                  }
                  <TableCell classes={{ root: classes.cell }} className={classes.cellLast} align="center">
                    {product.total_notified}
                  </TableCell>
                  <TableCell classes={{ root: classes.cell }} className={classes.cellLast} align="center">
                    <Input classes={{ disabled: classes.input }} disabled disableUnderline />
                  </TableCell>
                  <TableCell classes={{ root: classes.cell }} className={classes.cellLast} align="center">
                    <Input classes={{ disabled: classes.input }} value={extractVintage(product.description)} disabled disableUnderline />
                  </TableCell>
                  <TableCell classes={{ root: classes.cell }} className={classes.cellLast} align="center">
                    {product.total_ordered}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Grid>
      )}
    </Grid>
  )
}

PrintDelivery.propTypes = {
  classes: PropTypes.object.isRequired,
  supplier: PropTypes.string.isRequired,
  products: PropTypes.array.isRequired,
}

export default withStyles(styles)(PrintDelivery)
