import React, { Component } from 'react'
import PropTypes from 'prop-types'

import {
  equals, or, length, path, prop, pipe, pluck, sum, isEmpty,
} from 'ramda'
import moment from 'moment'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import { Creators as Actions } from '../actions'
import {
  activeCouriersSelector,
  ordersReadyToShipSelector,
  ordersDatedDeliverySelector,
  shipmentsActiveSelector
} from '../util/selectors'

// Material UI
import { withStyles } from '@material-ui/core/styles'
import { indigo, blueGrey } from '@material-ui/core/colors'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import Toolbar from '@material-ui/core/Toolbar'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import IconVisibility from '@material-ui/icons/Visibility'
import IconNotInterested from '@material-ui/icons/NotInterested'

// React Grid
import {
  Grid as ReactGrid,
  Table, TableHeaderRow,
} from '@devexpress/dx-react-grid-material-ui'

// Components
import LinkButton from '../components/LinkButton'
import Header from '../components/Header'
import RefreshButton from '../components/RefreshButton'
import Loading from '../components/Loading'
import GridOrders from '../components/GridOrders'
import GridDateTypeProvider from '../components/GridDateTypeProvider'

// Charts
import {
  ResponsiveContainer, BarChart, Bar, Tooltip as ChartTooltip, Legend,
  LabelList, XAxis, YAxis, CartesianGrid,
} from 'recharts'

const anyReadyToShip = cases => pipe(
  pluck('case'),
  sum,
  x => x > 0,
)(cases)

const colorPrimary = process.env.REACT_APP_COLOR_PRIMARY || indigo[500]
const styles = theme => ({
  flex: {
    flex: 1,
  },
  paper: {
    position: 'relative',
    padding: theme.spacing(2),
    paddingTop: 0,
  },
  divider: {
    marginBottom: theme.spacing(2),
  },
  flexCenter: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  iconEmpty: {
    display: 'block',
    width: 64,
    height: 64,
    opacity: 0.1,
  },
  marginLeft: {
    marginLeft: theme.spacing(1),
  }
})


class Dashboard extends Component {
  state = {
    shipToday: true
  }

  componentDidMount() {
    this.handleMount()
  }

  shouldComponentUpdate(nextProps, nextState) {
    return or(
      !equals(this.props, nextProps),
      !equals(this.state, nextState)
    )
  }

  handleMount = () => {
    this.setState({ shipToday: true })
    this.props.getOrdersReadyToShipAttempt()
    // TODO this should be done via sagas cancel task
    if (!this.props.uiLoadingGetOrdersDatedDelivery) {
      this.props.getOrdersDatedDeliveryAttempt()
    }
    this.props.getShipmentsActiveAttempt()
  }

  onShipToday = () => {
    this.setState({ shipToday: true })
    this.props.getOrdersReadyToShipAttempt()
  }

  onShipNextDay = () => {
    this.setState({ shipToday: false })
    let days = 2
    if (moment().day() === 5) {
      days = 4
    } else if (moment().day() === 6) {
      days = 3
    }
    this.props.getOrdersReadyToShipAttempt({
      shipping_date: moment().add(days, 'days').format('YYYY-MM-DD')
    })
  }

  // Grid
  rowComponent = rowProps => {
    return (
      <Table.Row hover {...rowProps} />
    )
  }

  cellComponent = ({ style, ...cellProps }) => {
    const columnName = path(['column', 'name'], cellProps)
    let cellStyle = style

    // Wrap cell
    if (equals(columnName, 'tracking')) {
      cellStyle = {
        ...cellStyle,
        whiteSpace: 'pre-line',
      }
    }

    // Tracking
    if (equals(columnName, 'tracking')) {
      const comments = path(['row', 'comments'], cellProps)
      if (!isEmpty(comments)) {
        return (
          <Table.Cell style={cellStyle} {...cellProps}>
            <Tooltip
              title={
                <div>
                  {comments.map((comment, index) => (
                    <div key={index}>{comment}</div>
                  ))}
                </div>
              }
              placement="top-end"
            >
              <span>{cellProps.value}</span>
            </Tooltip>
          </Table.Cell>
        )
      }
    }

    // Actions
    if (equals(columnName, 'action')) {
      const { location: routerLocation } = this.props
      const pathname = prop('pathname', routerLocation)
      const shipmentId = path(['row', 'id'], cellProps)

      return (
        <Table.Cell style={cellStyle} {...cellProps}>
          <LinkButton
            title={`Open shipment ${shipmentId}`}
            to={{
              pathname: `/shipments/${shipmentId}`,
              state: { from: pathname }
            }}
          >
            <IconVisibility color="action" />
          </LinkButton>
        </Table.Cell>
      )
    }

    return <Table.Cell style={cellStyle} {...cellProps} />
  }

  cellComponentNoData = () => {
    const { shipmentsActiveColumns } = this.props
    return (
      <Table.Cell colSpan={length(shipmentsActiveColumns)}>
        {<Typography variant="body1" align="center">{'No shipments in transit.'}</Typography>}
      </Table.Cell>
    )
  }

  render() {
    const {
      classes,
      couriers,
      // Orders Ready To Ship
      uiLoadingGetOrdersReadyToShip,
      readyToShip,
      // Orders Dated Delivery
      uiLoadingGetOrdersDatedDelivery,
      datedDelivery,
      datedDeliveryColumns,
      datedDeliveryColumnExtensions,
      // Recent Orders
      uiLoadingGetShipmentsActive,
      shipmentsActive,
      shipmentsActiveColumns,
      shipmentsActiveColumnExtensions,
    } = this.props
    const { shipToday } = this.state
    const readyToShipTotal = pipe(pluck('total'), sum)(readyToShip)
    const readyToShipPackfleet = pipe(pluck('packfleet'), sum)(readyToShip)
    const readyToShipOther = pipe(pluck('other'), sum)(readyToShip)

    return (
      <div>
        <Header
          title={'Dashboard'}
          subtitle={'See your stats'}
        >
          <RefreshButton
            onClick={this.handleMount}
          />
        </Header>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Paper className={classes.paper}>
              <Toolbar disableGutters>
                <Typography className={classes.flex} variant="subtitle1">
                  {'Boxes Required'}
                </Typography>
                <Button
                  size="small"
                  variant={shipToday ? 'contained' : 'outlined'}
                  color={shipToday ? 'primary' : 'default'}
                  className={classes.marginLeft}
                  onClick={this.onShipToday}
                >
                  Today
                </Button>
                <Button
                  size="small"
                  variant={!shipToday ? 'contained' : 'outlined'}
                  color={!shipToday ? 'primary' : 'default'}
                  className={classes.marginLeft}
                  onClick={this.onShipNextDay}
                >
                  Next Day
                </Button>
              </Toolbar>
              <Divider className={classes.divider} />
              <ResponsiveContainer width={'100%'} height={300}>
                <BarChart
                  data={readyToShip}
                  margin={{
                    top: 5, right: 0, left: -30, bottom: 5,
                  }}
                >
                  <CartesianGrid
                    stroke='rgba(255, 255, 255, 0.1)'
                    strokeDasharray="3 3"
                  />
                  <XAxis
                    dataKey="name"
                    axisLine={false}
                    tick={{
                      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                      fontSize: '80%',
                      fill: 'rgba(255, 255, 255, 0.7)',
                    }}
                  />
                  <YAxis
                    axisLine={false}
                    allowDecimals={false}
                    tick={{
                      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                      fontSize: '80%',
                      fill: 'rgba(255, 255, 255, 0.7)',
                    }}
                  />
                  <ChartTooltip
                    cursor={{ fill: 'rgba(255, 255, 255, 0.1)' }}
                    wrapperStyle={{
                      backgroundColor: 'red'
                    }}
                    contentStyle={{
                      border: 'none',
                      backgroundColor: 'rgba(255, 255, 255, 0.9)'
                    }}
                    labelStyle={{
                      color: blueGrey[900],
                      fontSize: '120%'
                    }}
                    itemStyle={{
                      lineHeight: '1em'
                    }}
                  />
                  <Legend
                    wrapperStyle={{
                      left: 0
                    }}
                    formatter={(value, bar, index) => {
                      return `${value} (${bar.payload.parcels || 0})`
                    }}
                  />
                  <Bar dataKey="total" name="Total" parcels={readyToShipTotal} fill={colorPrimary}>
                    <LabelList
                      dataKey="total"
                      position="top"
                      style={{
                        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                        fontSize: '80%',
                        fill: 'white',
                      }}
                    />
                  </Bar>
                  <Bar dataKey="packfleet" name="Packfleet" parcels={readyToShipPackfleet} fill="#008a70">
                    <LabelList
                      dataKey="packfleet"
                      position="top"
                      style={{
                        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                        fontSize: '80%',
                        fill: 'white',
                      }}
                    />
                  </Bar>
                  <Bar dataKey="other" name="Other" parcels={readyToShipOther} fill="#60a5fa">
                    <LabelList
                      dataKey="other"
                      position="top"
                      style={{
                        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                        fontSize: '80%',
                        fill: 'white',
                      }}
                    />
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
              {uiLoadingGetOrdersReadyToShip && (
                <Loading
                  variant="absolute"
                  message="Loading..."
                />
              )}
            </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper className={classes.paper}>
              <Toolbar disableGutters>
                <Typography className={classes.flex} variant="subtitle1">
                  {'Dated Delivery Orders'}
                </Typography>
              </Toolbar>
              <Divider className={classes.divider} />
              <ResponsiveContainer width={'100%'} height={300}>
                {
                  uiLoadingGetOrdersDatedDelivery || length(datedDelivery)
                  ? (
                    <GridOrders
                      virtual={true}
                      rows={datedDelivery}
                      couriers={couriers}
                      columns={datedDeliveryColumns.asMutable()}
                      columnExtensions={datedDeliveryColumnExtensions.asMutable()}
                      visibilityColumnExtensions={[
                        { columnName: 'order_reference', togglingEnabled: false },
                        { columnName: 'delivery_date', togglingEnabled: false },
                        { columnName: 'customer_delivery_contact', togglingEnabled: false },
                        { columnName: 'customer_delivery_postcode', togglingEnabled: false },
                        { columnName: 'action', togglingEnabled: false },
                      ]}
                    />
                  )
                  : (
                    <div className={classes.flexCenter}>
                      <IconNotInterested className={classes.iconEmpty} color="action" />
                    </div>
                  )
                }
              </ResponsiveContainer>
              {uiLoadingGetOrdersDatedDelivery && (
                <Loading
                  variant="absolute"
                  message="Loading..."
                />
              )}
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <Toolbar disableGutters>
                <Typography className={classes.flex} variant="subtitle1">
                  {'Shipments In Transit'}
                </Typography>
              </Toolbar>
              <Divider className={classes.divider} />
              <ReactGrid
                rows={shipmentsActive}
                columns={shipmentsActiveColumns.asMutable()}
              >
                <GridDateTypeProvider
                  columns={['created_at', 'dispatched_at']}
                />
                <Table
                  columnExtensions={shipmentsActiveColumnExtensions.asMutable()}
                  rowComponent={this.rowComponent}
                  cellComponent={this.cellComponent}
                  noDataCellComponent={this.cellComponentNoData}
                />
                <TableHeaderRow />
              </ReactGrid>
              {uiLoadingGetShipmentsActive && (
                <Loading
                  variant="absolute"
                  message="Loading shipments..."
                />
              )}
            </Paper>
          </Grid>
        </Grid>
      </div>
    )
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
  couriers: PropTypes.array.isRequired,
  // Orders Ready To Ship
  uiLoadingGetOrdersReadyToShip: PropTypes.bool.isRequired,
  getOrdersReadyToShipAttempt: PropTypes.func.isRequired,
  readyToShip: PropTypes.array.isRequired,
  // Orders Dated Delivery
  uiLoadingGetOrdersDatedDelivery: PropTypes.bool.isRequired,
  getOrdersDatedDeliveryAttempt: PropTypes.func.isRequired,
  datedDelivery: PropTypes.array.isRequired,
  datedDeliveryColumns: PropTypes.array.isRequired,
  datedDeliveryColumnExtensions: PropTypes.array.isRequired,
  // Shipments Active
  uiLoadingGetShipmentsActive: PropTypes.bool.isRequired,
  getShipmentsActiveAttempt: PropTypes.func.isRequired,
  shipmentsActive: PropTypes.array.isRequired,
  shipmentsActiveColumns: PropTypes.array.isRequired,
  shipmentsActiveColumnExtensions: PropTypes.array.isRequired,
}

const {
  getOrdersReadyToShipAttempt,
  getOrdersDatedDeliveryAttempt,
  getShipmentsActiveAttempt,
} = Actions

const mapStateToProps = state => ({
  ...state.dashboard,
  couriers: activeCouriersSelector(state),
  readyToShip: ordersReadyToShipSelector(state),
  datedDelivery: ordersDatedDeliverySelector(state),
  shipmentsActive: shipmentsActiveSelector(state),
})

const mapDispatchToProps = dispatch => bindActionCreators({
  getOrdersReadyToShipAttempt,
  getOrdersDatedDeliveryAttempt,
  getShipmentsActiveAttempt,
}, dispatch)

export default compose(
  withStyles(styles), connect(mapStateToProps, mapDispatchToProps)
)(Dashboard)
