import React from 'react'
import PropTypes from 'prop-types'

import {
  prop, length, join,
} from 'ramda'
import moment from 'moment'

// Material UI
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import Checkbox from '@material-ui/core/Checkbox'
import TextField from '@material-ui/core/TextField'

// Components
import CourierFormParcels from './CourierFormParcels'

const instructionLength = 50

const styles = theme => ({
  legend: {
    marginBottom: theme.spacing(2),
  },
})

const CourierFormDpd = ({
  classes,
  uiLoading,
  onReload,
  collectionDate,
  onCollectionDateChange,
  services,
  onServicesChange,
  serviceCode,
  smsNotifications,
  onSmsNotificationsChange,
  extendedLiability,
  onExtendedLiabilityChange,
  parcels,
  parcelsChanged,
  onParcelChange,
  onAddParcel,
  onRemoveParcel,
  instructions,
  onInstructionsChange,
  onCancel,
  onSubmit,
}) => [
  <DialogContent key="dpdForm">
    <Grid container spacing={3}>
      <Grid item xs={6}>
        <FormControl component="fieldset" margin="dense" fullWidth>
          <FormLabel component="legend" className={classes.legend}>{'Collection Date'}</FormLabel>
          <TextField
            id="collectionDate"
            helperText="Why not today?"
            type="date"
            inputProps={{ min: moment().format('YYYY-MM-DD') }}
            value={collectionDate}
            onChange={onCollectionDateChange}
            disabled={uiLoading}
          />
          {length(services) ? (
            <FormControlLabel
              control={
                <Checkbox
                  checked={smsNotifications}
                  onChange={onSmsNotificationsChange}
                  value="smsNotifications"
                />
              }
              label="SMS notifications required?"
            />
          ) : null}
          {length(services) ? (
            <FormControlLabel
              control={
                <Checkbox
                  checked={extendedLiability}
                  onChange={onExtendedLiabilityChange}
                  value="extendedLiability"
                />
              }
              label="Extended Liability (Cost £7.00)"
            />
          ) : null}
          <br />
          {
            length(services)
            ? (
              <RadioGroup
                name="serviceCode"
                value={serviceCode}
                margin="normal"
                onChange={onServicesChange}
              >
                {services.map((option, key) => {
                  const label = join(' - ', [
                    `(${prop('code', option)})`,
                    prop('name', option),
                  ]) || 'Unknown'
                  const value = prop('code', option) || ''

                  return (
                    <FormControlLabel
                      key={key}
                      label={label}
                      value={value}
                      control={<Radio />}
                      disabled={uiLoading}
                    />
                  )
                })}
              </RadioGroup>
            )
            : (
              <Typography variant="body1">{'No services available'}</Typography>
            )
          }
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <CourierFormParcels
          uiLoading={uiLoading}
          parcels={parcels}
          onParcelChange={onParcelChange}
          onAddParcel={onAddParcel}
          onRemoveParcel={onRemoveParcel}
        />
      </Grid>
    </Grid>
    <TextField
      id="instructions"
      label="Delivery Instructions"
      helperText={`${length(instructions)}/${instructionLength}`}
      error={length(instructions) > instructionLength}
      multiline
      rows="3"
      fullWidth
      value={instructions}
      margin="normal"
      onChange={onInstructionsChange}
      disabled={uiLoading}
      variant="outlined"
    />
  </DialogContent>,
  <DialogActions key="dpdActions">
    <Button
      onClick={onCancel}
      disabled={uiLoading}
    >
      {'Cancel'}
    </Button>
    {
      parcelsChanged
      ? (
        <Button
          variant="contained"
          color="primary"
          onClick={onReload}
          disabled={uiLoading}
        >
          {uiLoading
            ? <CircularProgress color="inherit" size={20} />
            : 'Reload Services'
          }
        </Button>
      )
      : null
    }
    <Button
      variant="contained"
      color="primary"
      onClick={onSubmit}
      disabled={!collectionDate || !serviceCode || uiLoading || parcelsChanged}
    >
      {uiLoading
        ? <CircularProgress color="inherit" size={20} />
        : 'Submit'
      }
    </Button>
  </DialogActions>
]

CourierFormDpd.propTypes = {
  classes: PropTypes.object.isRequired,
  uiLoading: PropTypes.bool.isRequired,
  onReload: PropTypes.func.isRequired,
  collectionDate: PropTypes.string.isRequired,
  onCollectionDateChange: PropTypes.func.isRequired,
  services: PropTypes.array.isRequired,
  onServicesChange: PropTypes.func.isRequired,
  serviceCode: PropTypes.string.isRequired,
  smsNotifications: PropTypes.bool.isRequired,
  onSmsNotificationsChange: PropTypes.func.isRequired,
  extendedLiability: PropTypes.bool.isRequired,
  onExtendedLiabilityChange: PropTypes.func.isRequired,
  parcels: PropTypes.array.isRequired,
  parcelsChanged: PropTypes.bool.isRequired,
  onParcelChange: PropTypes.func.isRequired,
  onAddParcel: PropTypes.func.isRequired,
  onRemoveParcel: PropTypes.func.isRequired,
  instructions: PropTypes.string.isRequired,
  onInstructionsChange: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

export default withStyles(styles)(CourierFormDpd)
