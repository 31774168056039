import { path, prop } from 'ramda'
import {
  take, put, call,
  fork, cancel, cancelled,
  // delay
} from 'redux-saga/effects'
import { Types, Creators as Actions } from '../actions'


export default api => {
  // GET Couriers
  function* getCouriers() {
    try {
      // yield call(delay, 2000)
      const couriersResp = yield call(api.getCouriers)
      
      // Did couriers load?
      if (prop('ok', couriersResp)) {
        const data = path(['data', 'data'], couriersResp) || []
        yield put(Actions.getCouriersSuccess(data))
      } else {
        yield put(Actions.getCouriersFailure())
      }
    } catch (error) {
      yield put(Actions.getCouriersFailure(error))
    } finally {
      if (yield cancelled()) {
        // TODO task cancelled
        console.log('GET Couriers task cancelled.')
      }
    }
  }
  
  function* getFlow() {
    let lastTask
    while (true) {
      yield take(Types.GET_COURIERS_ATTEMPT)
      
      if (lastTask) {
        yield cancel(lastTask)
      }
      
      lastTask = yield fork(getCouriers)
    }
  }
  
  return {
    getFlow,
  }
}
