import React, { Component } from 'react'
import PropTypes from 'prop-types'

import {
  prop, path, pathOr, equals, or, length, contains,
} from 'ramda'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import { Creators as Actions } from '../actions'
import {
  isGridColumnSortingApplied,
  sortDecimal,
} from '../util/helpers'
import {
  restockSelector,
  supplierSelector,
} from '../util/selectors'

// Material UI
import { withStyles } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import IconButton from '@material-ui/core/IconButton'
import IconEdit from '@material-ui/icons/Edit'
import IconLocalOffer from '@material-ui/icons/LocalOffer'

// React Grid
import {
  SortingState, PagingState,
  IntegratedSorting, IntegratedPaging,
} from '@devexpress/dx-react-grid'
import {
  Grid as ReactGrid,
  Table, TableHeaderRow,
  PagingPanel,
} from '@devexpress/dx-react-grid-material-ui'

// Components
import LinkButton from '../components/LinkButton'
import Header from '../components/Header'
import RefreshButton from '../components/RefreshButton'
import Loading from '../components/Loading'
import GridDateTypeProvider from '../components/GridDateTypeProvider'
import GridCurrencyTypeProvider from '../components/GridCurrencyTypeProvider'
import DialogSupplierUpdate from '../components/DialogSupplierUpdate'

const styles = theme => ({

})


class Restock extends Component {
  state = {
    editingSupplier: false,
  }
  
  componentDidMount() {
    this.handleMount()
  }
  
  UNSAFE_componentWillReceiveProps(nextProps) {
    // Supplier updated?
    if (
      this.props.uiLoadingUpdateSupplier !== nextProps.uiLoadingUpdateSupplier
      && !nextProps.uiLoadingUpdateSupplier
      && !nextProps.errorSupplier
    ) {
      this.toggleEditingSupplierClose()
    }
  }
  
  shouldComponentUpdate(nextProps, nextState) {
    return or(
      !equals(this.props, nextProps),
      !equals(this.state, nextState)
    )
  }
  
  handleMount = () => {
    this.props.getRestockAttempt()
  }
  
  toggleEditingSupplierOpen = supplierId => {
    this.props.setSupplierId(supplierId)
    this.setState({
      editingSupplier: true,
    })
  }
  
  toggleEditingSupplierClose = () => {
    this.props.setSupplierId(0)
    this.setState({
      editingSupplier: false,
    })
  }
  
  handleUpdateSupplierSubmit = supplier => {
    const { updateSupplierAttempt } = this.props
    const { supplierId, name, email, comment } = supplier
    
    // TODO redux forms ???
    updateSupplierAttempt(supplierId, { name, email, comment })
  }
  
  handleGridSorting = sort => {
    const { sorting, onSortingChange } = this.props
    const { columnName } = sort[0]
    
    // Descending as default direction
    if (
      contains(columnName, [
        'total_products', 'total_items', 'total_cost_ex_vat', 'total_orders', 'last_delivery'
      ])
      && !isGridColumnSortingApplied(columnName, sorting)
    ) {
      sort[0].direction = 'desc';
    }
    
    onSortingChange(sort)
  }
  
  // Grid
  headerCellComponent = cellProps => {
    const columnName = path(['column', 'name'], cellProps)
    
    // No label and sorting
    if (equals(columnName, 'action')) {
      return <Table.Cell />
    }
    
    return <TableHeaderRow.Cell {...cellProps} />
  }
  
  rowComponent = rowProps => {
    return (
      <Table.Row hover {...rowProps} />
    )
  }
  
  cellComponent = ({ style, ...cellProps }) => {
    const columnName = path(['column', 'name'], cellProps)
    let cellStyle = style
    
    // Wrap cell
    if (equals(columnName, 'comment')) {
      cellStyle = {
        ...cellStyle,
        whiteSpace: 'pre-line',
      }
    }
    
    // Actions
    if (equals(columnName, 'action')) {
      const { location: routerLocation } = this.props
      const pathname = prop('pathname', routerLocation)
      const supplierId = path(['row', 'supplier_id'], cellProps)
      
      return (
        <Table.Cell style={cellStyle} {...cellProps}>
          {
            supplierId
            ? (
              <Tooltip
                id={`tooltip-${supplierId}`}
                title="Update supplier"
                placement="left"
              >
                <IconButton onClick={() => this.toggleEditingSupplierOpen(supplierId)}>
                  <IconEdit color="action" />
                </IconButton>
              </Tooltip>
            )
            : null
          }
          <LinkButton
            title="Order more stock"
            to={{
              pathname: `/delivery/${supplierId}/0`,
              state: { from: pathname }
            }}
          >
            <IconLocalOffer color="action" />
          </LinkButton>
        </Table.Cell>
      )
    }
    
    return <Table.Cell style={cellStyle} {...cellProps} />
  }
  
  cellComponentNoData = () => {
    return (
      <Table.Cell colSpan={length(this.props.columns)}>
        {<Typography variant="body1" align="center">{'No restock required.'}</Typography>}
      </Table.Cell>
    )
  }
  
  render() {
    const {
      // classes,
      // Grid
      uiLoadingGet,
      data,
      columns,
      columnExtensions,
      sorting,
      // Update supplier
      uiLoadingUpdateSupplier,
      supplier,
    } = this.props
    const {
      editingSupplier,
    } = this.state
    
    return (
      <div>
        <Header
          title={'Restock'}
          subtitle={'Out of stock products'}
        >
          <RefreshButton
            onClick={this.handleMount}
          />
        </Header>
        <Paper>
          <ReactGrid
            rows={data}
            columns={columns.asMutable()}
          >
            <GridDateTypeProvider
              columns={['oldest_order']}
            />
            <GridDateTypeProvider
              columns={['last_delivery']}
              format={'DD/MM/YYYY'}
            />
            <GridCurrencyTypeProvider
              columns={['total_cost_ex_vat']}
            />
            <SortingState
              sorting={sorting.asMutable()}
              onSortingChange={this.handleGridSorting}
            />
            <PagingState
              pageSize={0}
            />
            <IntegratedSorting
              columnExtensions={[{ columnName: 'total_cost_ex_vat', compare: sortDecimal }]}
            />
            <IntegratedPaging />
            <Table
              columnExtensions={columnExtensions.asMutable()}
              rowComponent={this.rowComponent}
              cellComponent={this.cellComponent}
              noDataCellComponent={this.cellComponentNoData}
            />
            <TableHeaderRow
              showSortingControls
              cellComponent={this.headerCellComponent}
            />
            <PagingPanel />
          </ReactGrid>
        </Paper>
        <DialogSupplierUpdate
          open={editingSupplier}
          onClose={this.toggleEditingSupplierClose}
          disableBackdropClick={uiLoadingUpdateSupplier}
          disableEscapeKeyDown={uiLoadingUpdateSupplier}
          supplier={supplier}
          onSubmit={this.handleUpdateSupplierSubmit}
          submitting={uiLoadingUpdateSupplier}
        />
        {uiLoadingGet && <Loading message="Loading restock..." />}
      </div>
    )
  }
}

Restock.propTypes = {
  classes: PropTypes.object.isRequired,
  // Grid
  getRestockAttempt: PropTypes.func.isRequired,
  uiLoadingGet: PropTypes.bool.isRequired,
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  columnExtensions: PropTypes.array.isRequired,
  sorting: PropTypes.array.isRequired,
  onSortingChange: PropTypes.func.isRequired,
  // Update supplier
  supplier: PropTypes.object.isRequired,
  setSupplierId: PropTypes.func.isRequired,
  updateSupplierAttempt: PropTypes.func.isRequired,
  uiLoadingUpdateSupplier: PropTypes.bool.isRequired,
  errorSupplier: PropTypes.string.isRequired,
}

const {
  getRestockAttempt,
  setRestockGridState,
  setSupplierId,
  updateSupplierAttempt,
} = Actions

const mapStateToProps = state => ({
  ...state.restock,
  data: restockSelector(state),
  supplier: supplierSelector(state),
  uiLoadingUpdateSupplier: pathOr(false, ['suppliers', 'uiLoadingUpdate'], state),
  errorSupplier: pathOr('', ['suppliers', 'error'], state),
})

const mapDispatchToProps = dispatch => bindActionCreators({
  getRestockAttempt,
  onSortingChange: sorting => setRestockGridState('sorting', sorting),
  setSupplierId,
  updateSupplierAttempt,
}, dispatch)

export default compose(
  withStyles(styles), connect(mapStateToProps, mapDispatchToProps)
)(Restock)
