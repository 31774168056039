import { path, prop, map } from 'ramda'
import {
  take, put, call, fork,
  cancel, cancelled, select,
  delay
} from 'redux-saga/effects'
import { Types, Creators as Actions } from '../actions'


export default api => {
  // GET Deliveries
  // TODO temporary throttle/debounce
  function* watchDeliveriesFilter() {
    yield delay(500)
    yield put(Actions.getDeliveriesAttempt())
  }

  function* watchFlow() {
    let lastTask
    while (true) {
      yield take(Types.WATCH_DELIVERIES_FILTER)

      if (lastTask) {
        yield cancel(lastTask)
      }

      lastTask = yield fork(watchDeliveriesFilter)
    }
  }

  function* getDeliveries() {
    try {
      const filters = yield select(state => state.deliveries.filters)
      const sorting = yield select(state => state.deliveries.sorting)
      const pageSize = yield select(state => state.deliveries.pageSize)
      const currentPage = yield select(state => state.deliveries.currentPage)

      let params = {}

      map((s) => {
        params[s.columnName] = s.value
        params[s.columnName + '_operator'] = s.operation
      }, filters)

      map((s) => {
        params.sort = s.columnName
        params.order = s.direction
      }, sorting)

      params.limit = pageSize
      params.page = (currentPage + 1)

      // yield call(delay, 2000)
      const deliveriesResp = yield call(api.getDeliveries, params)

      // Did deliveries load?
      if (prop('ok', deliveriesResp)) {
        const deliveries = path(['data', 'data', 'deliveries'], deliveriesResp) || []
        const totalCount = path(['data', 'data', 'total'], deliveriesResp) || 0
        const success = path(['data', 'message'], deliveriesResp) || '[200] Deliveries loaded.'

        yield put(Actions.getDeliveriesSuccess({
          data: deliveries,
          totalCount: totalCount
        }, success))
      } else {
        const error = path(['data', 'message'], deliveriesResp) || '[500] Failed to load deliveries.'
        const problem = prop('problem', deliveriesResp)
        const status = prop('status', deliveriesResp)
        yield put(Actions.getDeliveriesFailure(error, problem, status))
      }
    } catch (error) {
      yield put(Actions.getDeliveriesFailure(error))
    } finally {
      if (yield cancelled()) {
        // TODO task cancelled
        console.log('GET Deliveries task cancelled.')
        yield put(Actions.getDeliveriesFailure())
      }
    }
  }

  function* getFlow() {
    let lastTask
    while (true) {
      yield take(Types.GET_DELIVERIES_ATTEMPT)

      if (lastTask) {
        yield cancel(lastTask)
      }

      lastTask = yield fork(getDeliveries)
    }
  }

  return {
    watchFlow,
    getFlow,
  }
}
