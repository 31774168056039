import React from 'react'
import PropTypes from 'prop-types'

import { urlify } from '../util/helpers'

// React Grid
import {
  DataTypeProvider,
} from '@devexpress/dx-react-grid'

const GridUrlTypeProvider = ({ columns }) => (
  <DataTypeProvider
    formatterComponent={
      ({ value }) => value ? <div dangerouslySetInnerHTML={{ __html: urlify(value) }} /> : value
    }
    for={columns}
  />
)

GridUrlTypeProvider.propTypes = {
  columns: PropTypes.array.isRequired,
}

export default GridUrlTypeProvider
