import { all } from 'redux-saga/effects'
import api from '../services/api'

// Sagas
import startup from './startup'
import runtime from './runtime'
import broadcasting from './broadcasting'
import config from './config'
import auth from './auth'
import dashboard from './dashboard'
import orders from './orders'
import order from './order'
import couriers from './couriers'
import shipments from './shipments'
import shipment from './shipment'
import products from './products'
import product from './product'
import suppliers from './suppliers'
import deliveries from './deliveries'
import delivery from './delivery'
import restock from './restock'
import jobs from './jobs'

// Start the daemons
export default function* root() {
  yield all([
    startup(api).bootingFlow(),
    runtime(api).watchersFlow(),
    broadcasting(api).broadcastingFlow(),
    // config
    config(api).getConfigFlow(),
    // Auth
    auth(api).loginFlow(),
    auth(api).logoutFlow(),
    auth(api).getUsersFlow(),
    auth(api).forgotPasswordFlow(),
    auth(api).resetPasswordFlow(),
    // Dashboard
    dashboard(api).getOrdersReadyToShipFlow(),
    dashboard(api).getOrdersDatedDeliveryFlow(),
    dashboard(api).getShipmentsActiveFlow(),
    // Orders
    orders(api).watchFlow(),
    orders(api).getFlow(),
    orders(api).getStatusesFlow(),
    orders(api).changeOrdersStatusFlow(),
    order(api).getFlow(),
    order(api).statusChangeFlow(),
    order(api).adjustOrderItemsStockFlow(),
    order(api).getCourierServicesFlow(),
    order(api).shipOrderFlow(),
    order(api).getOrderShipmentLabelsFlow(),
    order(api).getOrderShipmentDocumentsFlow(),
    order(api).syncFlow(),
    order(api).deleteFlow(),
    // Couriers
    couriers(api).getFlow(),
    // Shipments
    shipments(api).watchFlow(),
    shipments(api).getFlow(),
    shipment(api).getFlow(),
    shipment(api).deliverFlow(),
    shipment(api).deleteFlow(),
    // Products
    products(api).watchFlow(),
    products(api).getFlow(),
    product(api).getFlow(),
    product(api).updateFlow(),
    product(api).adjustmentFlow(),
    product(api).unreserveStockFlow(),
    product(api).deallocateFlow(),
    // Suppliers
    suppliers(api).getFlow(),
    suppliers(api).updateFlow(),
    // Deliveries
    deliveries(api).watchFlow(),
    deliveries(api).getFlow(),
    // Delivery
    delivery(api).getFlow(),
    delivery(api).saveFlow(),
    delivery(api).completeFlow(),
    delivery(api).saveInvoiceFlow(),
    delivery(api).deleteFlow(),
    // Restock
    restock(api).getFlow(),
    // Jobs
    jobs(api).getFlow(),
    jobs(api).retryFailedJobFlow(),
    jobs(api).deleteFailedJobFlow(),
  ])
}
