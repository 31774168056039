import React, { Component } from 'react'
import PropTypes from 'prop-types'

import {
  equals, or, length, contains, path, pipe, filter, pluck
} from 'ramda'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import { Creators as Actions } from '../actions'
import {
  isGridColumnSortingApplied,
  searchInString,
  naturalSort
} from '../util/helpers'

// Material UI
import { withStyles } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import AppBar from '@material-ui/core/AppBar'
import Paper from '@material-ui/core/Paper'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import IconReplay from '@material-ui/icons/Replay'
import IconDelete from '@material-ui/icons/Delete'

// React Grid
import {
  SortingState, FilteringState, PagingState,
  IntegratedSorting, IntegratedFiltering, IntegratedPaging,
} from '@devexpress/dx-react-grid'
import {
  Grid as ReactGrid,
  Table, TableHeaderRow, TableColumnVisibility, TableFilterRow,
  PagingPanel,
} from '@devexpress/dx-react-grid-material-ui'

// Components
import Header from '../components/Header'
import RefreshButton from '../components/RefreshButton'
import Loading from '../components/Loading'
import GridColumnChooserCell from '../components/GridColumnChooserCell'
import GridDateTypeProvider from '../components/GridDateTypeProvider'

const isJobsTab = tab => tab === 1
const isFailedJobsTab = tab => tab === 2

const styles = theme => ({
  paper: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  divider: {
    marginBottom: theme.spacing(1),
  },
})


class Jobs extends Component {
  state = {
    tab: 1,
  }

  // componentDidMount() {
  //   this.handleMount()
  // }

  shouldComponentUpdate(nextProps, nextState) {
    return or(
      !equals(this.props, nextProps),
      !equals(this.state, nextState)
    )
  }

  handleMount = () => {
    this.props.getJobsAttempt()
  }

  handleTabsChange = (event, value) => {
    this.setState({ tab: value })
  }

  // Grid
  handleGridSorting = sort => {
    const {
      sorting,
      onSortingChange,
      failedSorting,
      onFailedSortingChange,
    } = this.props
    const { tab } = this.state
    const { columnName } = sort[0]
    const rowsSorting = isFailedJobsTab(tab) ? failedSorting : sorting
    const rowsOnSortingChange = isFailedJobsTab(tab) ? onFailedSortingChange : onSortingChange

    // Descending as default direction
    if (
      contains(columnName, [
        'attempts',
      ])
      && !isGridColumnSortingApplied(columnName, rowsSorting)
    ) {
      sort[0].direction = 'desc';
    }

    rowsOnSortingChange(sort)
  }

  // Grid
  headerCellComponent = cellProps => {
    const columnName = path(['column', 'name'], cellProps)

    // No label and sorting
    if (equals(columnName, 'action')) {
      if (isJobsTab(this.state.tab)) {
        const {
          columns,
          visibilityColumnExtensions,
          hiddenColumnNames,
          onColumnVisibilityChange,
        } = this.props
        const disabledColumns = pipe(
          filter(column => !column.togglingEnabled),
          pluck('columnName'),
        )(visibilityColumnExtensions)
        return (
          <GridColumnChooserCell
            columns={columns}
            hiddenColumns={hiddenColumnNames}
            disabledColumns={disabledColumns}
            onChange={onColumnVisibilityChange}
          />
        )
      } else {
        return <Table.Cell />
      }
    }

    return <TableHeaderRow.Cell {...cellProps} />
  }

  filterCellComponent = cellProps => {
    const columnName = path(['column', 'name'], cellProps)

    // No filter
    if (equals(columnName, 'action')) {
      return <Table.Cell />
    }

    return <TableFilterRow.Cell {...cellProps} />
  }

  rowComponent = rowProps => {
    return (
      <Table.Row hover {...rowProps} />
    )
  }

  cellComponent = ({ style, ...cellProps }) => {
    const columnName = path(['column', 'name'], cellProps)
    let cellStyle = style

    // Wrap cell
    if (contains(columnName, [
      'description'
    ])) {
      cellStyle = {
        ...cellStyle,
        whiteSpace: 'pre-line',
      }
    }

    // Actions
    if (equals(columnName, 'action') && isFailedJobsTab(this.state.tab)) {
      const {
        retryFailedJobAttempt,
        deleteFailedJobAttempt
      } = this.props
      const failedJobId = path(['row', 'id'], cellProps)
      const failedJobUuid = path(['row', 'uuid'], cellProps)

      return (
        <Table.Cell style={cellStyle} {...cellProps}>
          <Tooltip
            id={`tooltip-retry-${failedJobId}`}
            title="Retry"
            placement="left"
          >
            <IconButton onClick={() => retryFailedJobAttempt(failedJobUuid)}>
              <IconReplay color="action" />
            </IconButton>
          </Tooltip>
          <Tooltip
            id={`tooltip-delete-${failedJobId}`}
            title="Delete"
            placement="left"
          >
            <IconButton onClick={() => deleteFailedJobAttempt(failedJobUuid)}>
              <IconDelete color="error" />
            </IconButton>
          </Tooltip>
        </Table.Cell>
      )
    }

    return <Table.Cell style={cellStyle} {...cellProps} />
  }

  cellComponentNoData = (colSpan, message) => {
    return (
      <Table.Cell colSpan={colSpan}>
        {<Typography variant="body1" align="center">{message}</Typography>}
      </Table.Cell>
    )
  }

  render() {
    const {
      classes,
      // Grid
      uiLoadingGet,
      jobs,
      failedJobs,
      columns,
      columnExtensions,
      hiddenColumnNames,
      failedColumns,
      failedColumnExtensions,
      sorting,
      filters,
      onFiltersChange,
      failedSorting,
      failedFilters,
      onFailedFiltersChange,
      currentPage,
      pageSize,
      onCurrentPageChange,
      failedCurrentPage,
      failedPageSize,
      onFailedCurrentPageChange,
    } = this.props
    const { tab } = this.state
    const rows = isFailedJobsTab(tab) ? failedJobs : jobs
    const rowsColumns = isFailedJobsTab(tab) ? failedColumns : columns
    const rowsColumnExtensions = isFailedJobsTab(tab) ? failedColumnExtensions : columnExtensions
    const rowsColumnVisibility = isFailedJobsTab(tab) ? [] : hiddenColumnNames
    const rowsSorting = isFailedJobsTab(tab) ? failedSorting : sorting
    const rowsFilters = isFailedJobsTab(tab) ? failedFilters : filters
    const rowsOnFiltersChange = isFailedJobsTab(tab) ? onFailedFiltersChange : onFiltersChange
    const rowsCurrentPage = isFailedJobsTab(tab) ? failedCurrentPage : currentPage
    const rowsPageSize = isFailedJobsTab(tab) ? failedPageSize : pageSize
    const rowsOnCurrentPageChange = isFailedJobsTab(tab) ? onFailedCurrentPageChange : onCurrentPageChange

    return (
      <div>
        <Header
          title={'Jobs'}
          subtitle={`Browsing page ${rowsCurrentPage + 1}`}
        >
          <RefreshButton
            onClick={this.handleMount}
          />
        </Header>
        <Paper className={classes.paper}>
          <AppBar position="static" color="inherit">
            <Tabs
              value={tab}
              onChange={this.handleTabsChange}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="off"
            >
              <Tab key="tab-jobs" label={`Jobs (${length(jobs)})`} value={1} />
              <Tab key="tab-failed-jobs" label={`Failed (${length(failedJobs)})`} value={2} />
            </Tabs>
          </AppBar>
          <Divider className={classes.divider} />
          <ReactGrid
            rows={rows}
            columns={rowsColumns.asMutable()}
          >
            <GridDateTypeProvider
              columns={['reserved_at', 'created_at', 'available_at', 'failed_at']}
              format={'DD/MM/YYYY HH:mm:ss'}
            />
            <FilteringState
              filters={rowsFilters.asMutable()}
              onFiltersChange={rowsOnFiltersChange}
            />
            <SortingState
              sorting={rowsSorting.asMutable()}
              onSortingChange={this.handleGridSorting}
            />
            <PagingState
              currentPage={rowsCurrentPage}
              onCurrentPageChange={rowsOnCurrentPageChange}
              pageSize={rowsPageSize}
            />
            <IntegratedFiltering
              columnExtensions={[
                { columnName: 'name', predicate: searchInString },
              ]}
            />
            <IntegratedSorting
              columnExtensions={[
                { columnName: 'name', compare: naturalSort },
              ]}
            />
            <IntegratedPaging />
            <Table
              columnExtensions={rowsColumnExtensions.asMutable()}
              rowComponent={this.rowComponent}
              cellComponent={this.cellComponent}
              noDataCellComponent={() =>
                this.cellComponentNoData(
                  (length(rowsColumns) - length(rowsColumnVisibility)),
                  'No jobs.'
                )
              }
            />
            <TableHeaderRow
              showSortingControls
              cellComponent={this.headerCellComponent}
            />
            <TableColumnVisibility
              hiddenColumnNames={hiddenColumnNames}
            />
            <TableFilterRow
              cellComponent={this.filterCellComponent}
            />
            <PagingPanel />
          </ReactGrid>
        </Paper>
        {uiLoadingGet && <Loading message="Loading jobs..." />}
      </div>
    )
  }
}

Jobs.propTypes = {
  classes: PropTypes.object.isRequired,
  // Jobs
  getJobsAttempt: PropTypes.func.isRequired,
  retryFailedJobAttempt: PropTypes.func.isRequired,
  deleteFailedJobAttempt: PropTypes.func.isRequired,
  uiLoadingGet: PropTypes.bool.isRequired,
  jobs: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  columnExtensions: PropTypes.array.isRequired,
  visibilityColumnExtensions: PropTypes.array.isRequired,
  hiddenColumnNames: PropTypes.array.isRequired,
  onColumnVisibilityChange: PropTypes.func.isRequired,
  sorting: PropTypes.array.isRequired,
  onSortingChange: PropTypes.func.isRequired,
  filters: PropTypes.array.isRequired,
  onFiltersChange: PropTypes.func.isRequired,
  currentPage: PropTypes.number.isRequired,
  onCurrentPageChange: PropTypes.func.isRequired,
  pageSize: PropTypes.number.isRequired,
  // Failed Jobs
  failedJobs: PropTypes.array.isRequired,
  failedColumns: PropTypes.array.isRequired,
  failedColumnExtensions: PropTypes.array.isRequired,
  failedSorting: PropTypes.array.isRequired,
  onFailedSortingChange: PropTypes.func.isRequired,
  failedFilters: PropTypes.array.isRequired,
  onFailedFiltersChange: PropTypes.func.isRequired,
  failedCurrentPage: PropTypes.number.isRequired,
  onFailedCurrentPageChange: PropTypes.func.isRequired,
  failedPageSize: PropTypes.number.isRequired,
}

const {
  getJobsAttempt,
  retryFailedJobAttempt,
  deleteFailedJobAttempt,
  setJobsGridState,
} = Actions

const mapStateToProps = state => ({
  ...state.jobs
})

const mapDispatchToProps = dispatch => bindActionCreators({
  getJobsAttempt,
  retryFailedJobAttempt,
  deleteFailedJobAttempt,
  onColumnVisibilityChange: columnNames => setJobsGridState('hiddenColumnNames', columnNames),
  onSortingChange: sorting => setJobsGridState('sorting', sorting),
  onFiltersChange: filters => setJobsGridState('filters', filters),
  onCurrentPageChange: currentPage => setJobsGridState('currentPage', currentPage),
  onFailedSortingChange: sorting => setJobsGridState('failedSorting', sorting),
  onFailedFiltersChange: filters => setJobsGridState('failedFilters', filters),
  onFailedCurrentPageChange: currentPage => setJobsGridState('failedCurrentPage', currentPage),
}, dispatch)

export default compose(
  withStyles(styles), connect(mapStateToProps, mapDispatchToProps)
)(Jobs)
