import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import {
  equals, path, pathOr, length, contains, split, head, last, isEmpty
} from 'ramda'
import { encode } from 'html-entities'
import {
  isGridColumnSortingApplied,
  sortDecimal,
  naturalSort
} from '../util/helpers'

// Material UI
import { withStyles } from '@material-ui/core/styles'
import { red } from '@material-ui/core/colors'
import Typography from '@material-ui/core/Typography'
import IconFlightLand from '@material-ui/icons/FlightLand'
import IconVisibility from '@material-ui/icons/Visibility'
import IconDone from '@material-ui/icons/Done'

// React Grid
import { Getter, Plugin } from '@devexpress/dx-react-core'
import {
  SortingState, SelectionState,
  IntegratedSelection, IntegratedSorting,
  DataTypeProvider,
} from '@devexpress/dx-react-grid'
import {
  Grid as ReactGrid,
  Table, TableHeaderRow, TableSelection,
} from '@devexpress/dx-react-grid-material-ui'

// Components
import GridCurrencyTypeProvider from './GridCurrencyTypeProvider'
import LinkButton from './LinkButton'

const highlight = string => {
  const canHighlight = !!string.match(/\(([^)]+)\)/)
  const stringHtmlEntities = encode(string)

  if (canHighlight) {
    return stringHtmlEntities.replaceAll('(', `<span style="color: ${red['A400']}">(`).replaceAll(')', ')</span>')
  }

  return stringHtmlEntities
}

const PatchedIntegratedSelection = ({ rowSelectionEnabled, ...restProps }) => {
  let computedRows = []
  return (
    <Plugin>
      <Getter
        name="rows"
        computed={({ rows }) => {
          computedRows = rows
          return rows.filter(rowSelectionEnabled)
        }}
      />
      <IntegratedSelection {...restProps} />
      <Getter
        name="rows"
        computed={() => computedRows}
      />
    </Plugin>
  )
}

const PatchedTableSelection = ({ rowSelectionEnabled, ...restProps }) => {
  return (
    <TableSelection
      cellComponent={(props) => {
        // Order line is shipped
        const ordered = path(['row', 'ordered'], props)
        const shipped = path(['row', 'shipped'], props)
        if (shipped === ordered) {
          return (
            <Table.Cell {...props}>
              <IconDone style={{ marginLeft: -3 }} color="primary" />
            </Table.Cell>
          )
        }

        // Anything to action?
        if (rowSelectionEnabled(props.tableRow.row)) {
          return <TableSelection.Cell {...props} />
        }

        return <Table.StubCell {...props} />
      }}
      {...restProps}
    />
  )
}

const styles = theme => ({
  itemInfo: {
    fontSize: '0.6rem'
  },
  error: {
    color: theme.palette.error.main,
  },
})


class GridOrderItems extends PureComponent {
  // state = {
  //
  // }

  // Grid
  handleGridSorting = sort => {
    const { sorting, onSortingChange } = this.props
    const { columnName } = sort[0]

    // Descending as default direction
    if (
      contains(columnName, [
        'cost_ex_vat', 'ordered', 'items_in_stock', 'notified', 'allocated', 'shipped'
      ])
      && !isGridColumnSortingApplied(columnName, sorting)
    ) {
      sort[0].direction = 'desc';
    }

    onSortingChange(sort)
  }

  headerCellComponent = cellProps => {
    const columnName = path(['column', 'name'], cellProps)

    // No label and sorting
    if (equals(columnName, 'action')) {
      return <Table.Cell />
    }

    return <TableHeaderRow.Cell {...cellProps} />
  }

  rowComponent = rowProps => {
    return (
      <Table.Row hover {...rowProps} />
    )
  }

  cellComponent = ({ style, ...cellProps }) => {
    const { classes } = this.props
    const columnName = path(['column', 'name'], cellProps)
    let cellStyle = style

    // Wrap cell
    if (contains(columnName, ['description', 'location', 'supplier'])) {
      cellStyle = {
        ...cellStyle,
        whiteSpace: 'pre-line',
      }
    }

    // Description
    if (equals(columnName, 'description')) {
      const kitDescription = pathOr('', ['row', 'kit', 'description'], cellProps)

      return (
        <Table.Cell style={cellStyle} {...cellProps}>
          <span dangerouslySetInnerHTML={{ __html: highlight(cellProps.value) }} />
          {kitDescription && (
            <div className={classes.itemInfo}>
              <i className={classes.error}>({kitDescription})</i>
            </div>
          )}
        </Table.Cell>
      )
    }

    // Actions
    if (equals(columnName, 'action')) {
      const skuCode = path(['row', 'sku'], cellProps)
      const supplierId = pathOr(0, ['row', 'supplier_id'], cellProps)
      const deliveryId = path(['row', 'received_delivery_id'], cellProps)

      return (
        <Table.Cell style={cellStyle} {...cellProps}>
          {deliveryId && (
            <LinkButton
              title={`Open delivery`}
              to={{
                pathname: `/delivery/${supplierId}/${deliveryId}`,
                state: { from: '/orders' }
              }}
            >
              <IconFlightLand color="action" />
            </LinkButton>
          )}
          <LinkButton
            title={`Open product ${skuCode}`}
            to={{
              pathname: `/products/${skuCode}`,
              state: { from: '/orders' }
            }}
          >
            <IconVisibility color="action" />
          </LinkButton>
        </Table.Cell>
      )
    }

    return <Table.Cell style={cellStyle} {...cellProps} />
  }

  cellComponentNoData = colSpan => {
    return (
      <Table.Cell colSpan={colSpan}>
        {<Typography variant="body1" align="center">{'No order items.'}</Typography>}
      </Table.Cell>
    )
  }

  render() {
    const {
      classes,
      rows,
      columns,
      columnExtensions,
      sorting,
      onSortingChange,
      selection,
      onSelectionChange,
      rowSelectionEnabled,
    } = this.props
    const showSorting = sorting && onSortingChange ? true : false
    const showSelection = selection && onSelectionChange && rowSelectionEnabled ? true : false
    let columnsLength = length(columns)
    if (showSelection) columnsLength += 1

    return (
      <ReactGrid
        rows={rows}
        columns={columns}
      >
        <GridCurrencyTypeProvider
          columns={['cost_ex_vat']}
        />
        <DataTypeProvider
          formatterComponent={
            ({ value }) => {
              const columnValue = split(' ', value)
              const allocated = head(columnValue)
              const toAllocate = last(columnValue)
              if (!equals('(0)', toAllocate)) {
                return (
                  <span>
                    {allocated} <span className={classes.error}>{toAllocate}</span>
                  </span>
                )
              }
              return value
            }
          }
          for={['allocated']}
        />
        {
        // <DataTypeProvider
        //   formatterComponent={
        //     ({ row, value }) => {
        //       if (prop('end_of_line', row)) {
        //         return (
        //           <span>
        //             {value} <span className={classes.error}>End of line item</span>
        //           </span>
        //         )
        //       }
        //       return value
        //     }
        //   }
        //   for={['description']}
        // />
        }
        {showSorting && (
          <SortingState
            // defaultSorting={[
            //   {
            //     columnName: 'description', 'direction': 'asc', compare: naturalSort
            //   }
            // ]}
            onSortingChange={this.handleGridSorting}
          />
        )}
        {showSelection && (
          <SelectionState
            selection={selection}
            onSelectionChange={onSelectionChange}
          />
        )}
        {showSorting && (
          <IntegratedSorting
            columnExtensions={[
              { columnName: 'description', compare: naturalSort },
              { columnName: 'cost_ex_vat', compare: sortDecimal },
            ]}
          />
        )}
        {showSelection && (
          <PatchedIntegratedSelection
            rowSelectionEnabled={rowSelectionEnabled}
          />
        )}
        <Table
          columnExtensions={columnExtensions}
          rowComponent={this.rowComponent}
          cellComponent={this.cellComponent}
          noDataCellComponent={() => this.cellComponentNoData(columnsLength)}
        />
        <TableHeaderRow
          showSortingControls={showSorting}
          cellComponent={this.headerCellComponent}
        />
        {showSelection && (
          <PatchedTableSelection
            showSelectAll
            rowSelectionEnabled={rowSelectionEnabled}
          />
        )}
      </ReactGrid>
    )
  }
}

GridOrderItems.propTypes = {
  classes: PropTypes.object.isRequired,
  rows: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  columnExtensions: PropTypes.array.isRequired,
  sorting: PropTypes.array,
  onSortingChange: PropTypes.func,
  selection: PropTypes.array,
  onSelectionChange: PropTypes.func,
  rowSelectionEnabled: PropTypes.func,
}

GridOrderItems.defaultProps = {
  columnExtensions: [],
}

export default withStyles(styles)(GridOrderItems)
