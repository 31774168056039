import { path, prop } from 'ramda'
import {
  take, put, call, fork,
  cancel, cancelled,
  delay
} from 'redux-saga/effects'
import { Types, Creators as Actions } from '../actions'


export default api => {
  // GET Jobs
  function* getJobs() {
    try {
      // yield call(delay, 3000)
      const jobsResp = yield call(api.getJobs)

      // Did jobs load?
      if (prop('ok', jobsResp)) {
        const data = path(['data', 'data'], jobsResp) || []
        const success = path(['data', 'message'], jobsResp) || '[200] Jobs loaded.'

        yield put(Actions.getJobsSuccess(data, success))
      } else {
        const error = path(['data', 'message'], jobsResp) || '[500] Failed to load jobs.'
        const problem = prop('problem', jobsResp)
        const status = prop('status', jobsResp)
        yield put(Actions.getJobsFailure(error, problem, status))
      }
    } catch (error) {
      yield put(Actions.getJobsFailure(error))
    } finally {
      if (yield cancelled()) {
        // TODO task cancelled
        console.log('GET Jobs task cancelled.')
        yield put(Actions.getJobsFailure())
      }
    }
  }

  function* getFlow() {
    let lastTask
    while (true) {
      yield take(Types.GET_JOBS_ATTEMPT)

      if (lastTask) {
        yield cancel(lastTask)
      }

      lastTask = yield fork(getJobs)
    }
  }

  // RETRY failed job
  function* retryFailedJob(failedJobUuid) {
    try {
      // yield call(delay, 3000)
      const jobResp = yield call(api.retryFailedJob, failedJobUuid)

      // Did failed job retry?
      if (prop('ok', jobResp)) {
        const success = path(['data', 'message'], jobResp) || '[200] Failed job queued.'
        yield put(Actions.retryFailedJobSuccess(failedJobUuid, success))
      } else {
        const error = path(['data', 'message'], jobResp) || '[500] Failed to queue failed job.'
        const problem = prop('problem', jobResp)
        const status = prop('status', jobResp)
        yield put(Actions.retryFailedJobFailure(error, problem, status))
      }
    } catch (error) {
      yield put(Actions.retryFailedJobFailure(error))
    } finally {
      if (yield cancelled()) {
        // TODO task cancelled
        // NOTE Nothing to do here, order state cleared on LOCATION_CHANGE
        console.log('RETRY failed job task cancelled.')
      }
    }
  }

  function* retryFailedJobFlow() {
    let lastTask
    while (true) {
      const { failedJobUuid } = yield take(Types.RETRY_FAILED_JOB_ATTEMPT)

      if (lastTask) {
        yield cancel(lastTask)
      }

      lastTask = yield fork(retryFailedJob, failedJobUuid)
    }
  }

  // DELETE failed job
  function* deleteFailedJob(failedJobUuid) {
    try {
      // yield call(delay, 3000)
      const jobResp = yield call(api.deleteFailedJob, failedJobUuid)

      // Did failed job delete?
      if (prop('ok', jobResp)) {
        const success = path(['data', 'message'], jobResp) || '[200] Failed job deleted.'
        yield put(Actions.deleteFailedJobSuccess(failedJobUuid, success))
      } else {
        const error = path(['data', 'message'], jobResp) || '[500] Failed to delete failed job.'
        const problem = prop('problem', jobResp)
        const status = prop('status', jobResp)
        yield put(Actions.deleteFailedJobFailure(error, problem, status))
      }
    } catch (error) {
      yield put(Actions.deleteFailedJobFailure(error))
    } finally {
      if (yield cancelled()) {
        // TODO task cancelled
        // NOTE Nothing to do here, order state cleared on LOCATION_CHANGE
        console.log('DELETE failed job task cancelled.')
      }
    }
  }

  function* deleteFailedJobFlow() {
    let lastTask
    while (true) {
      const { failedJobUuid } = yield take(Types.DELETE_FAILED_JOB_ATTEMPT)

      if (lastTask) {
        yield cancel(lastTask)
      }

      lastTask = yield fork(deleteFailedJob, failedJobUuid)
    }
  }

  return {
    getFlow,
    retryFailedJobFlow,
    deleteFailedJobFlow,
  }
}
