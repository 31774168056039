// import {
//
// } from 'ramda'
import { Types } from '../actions'
import Immutable from 'seamless-immutable'
import { createReducer } from 'reduxsauce'

const INITIAL_STATE = Immutable({
  // Grid
  uiLoadingGet: false,
  uiLoadingUpdate: false,
  // TODO Suppliers - why in app reducer since using reselect ???
  // NOTE suppliers in app reducer
  // data: [],
  supplierId: 0,
  error: null,
  columns: [
    { name: 'name', title: 'Name' },
    { name: 'email', title: 'Email' },
    { name: 'total_products', title: 'Total Products' },
    { name: 'updated_at', title: 'Date Updated' },
    { name: 'comment', title: 'Comment' },
    { name: 'action', title: 'Order', getCellValue: row => row.id },
  ],
  columnExtensions: [
    { columnName: 'action', width: 130, align: 'right' },
  ],
  sorting: [
    { columnName: 'name', direction: 'asc' },
  ],
  filters: [],
  currentPage: 0,
  pageSizes: [10, 25, 50],
  pageSize: 10,
})

// GET Suppliers
const getSuppliersAttempt = state => {
  return state.merge({
    uiLoadingGet: true,
  })
}

const getSuppliersSuccess = state => {
  return state.merge({
    uiLoadingGet: false,
  })
}

const getSuppliersFailure = state => {
  return state.merge({
    uiLoadingGet: false,
  })
}

const setSuppliersGridState = (state, { name, value }) => {
  return state.set(name, value)
}

// PUT Supplier
const setSupplierId = (state, { supplierId }) => {
  return state.merge({
    supplierId: supplierId,
  })
}

const updateSupplierAttempt = state => {
  return state.merge({
    uiLoadingUpdate: true,
    error: INITIAL_STATE.error,
  })
}

const updateSupplierSuccess = state => {
  return state.merge({
    uiLoadingUpdate: false,
  })
}

const updateSupplierFailure = (state, { error }) => {
  return state.merge({
    uiLoadingUpdate: false,
    error: error,
  })
}

const logout = state => INITIAL_STATE

// map our types to our handlers
const ACTION_HANDLERS = {
  // GET Suppliers
  [Types.GET_SUPPLIERS_ATTEMPT]: getSuppliersAttempt,
  [Types.GET_SUPPLIERS_SUCCESS]: getSuppliersSuccess,
  [Types.GET_SUPPLIERS_FAILURE]: getSuppliersFailure,
  [Types.SET_SUPPLIERS_GRID_STATE]: setSuppliersGridState,
  // PUT Supplier
  [Types.SET_SUPPLIER_ID]: setSupplierId,
  [Types.UPDATE_SUPPLIER_ATTEMPT]: updateSupplierAttempt,
  [Types.UPDATE_SUPPLIER_SUCCESS]: updateSupplierSuccess,
  [Types.UPDATE_SUPPLIER_FAILURE]: updateSupplierFailure,
  // Reset
  [Types.LOGOUT]: logout,
}

export default createReducer(INITIAL_STATE, ACTION_HANDLERS)
