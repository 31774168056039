import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import {
  equals, length, sum,
} from 'ramda'

// Material UI
import { withStyles } from '@material-ui/core/styles'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import TextField from '@material-ui/core/TextField'
import IconButton from '@material-ui/core/IconButton'
import IconAdd from '@material-ui/icons/Add'
import IconDelete from '@material-ui/icons/Delete'
import InputAdornment from '@material-ui/core/InputAdornment'

const getTotalWeight = parcels => sum(parcels)

const styles = theme => ({
  legend: {
    marginBottom: theme.spacing(1),
  },
  parcel: {
    marginBottom: theme.spacing(1),
  },
  add: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
})

class CourierFormParcels extends PureComponent {
  state = {
    totalWeight: getTotalWeight(this.props.parcels),
    totalParcels: length(this.props.parcels),
  }
  
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!equals(this.props.parcels, nextProps.parcels)) {
      this.setState({
        totalWeight: getTotalWeight(nextProps.parcels),
        totalParcels: length(nextProps.parcels),
      })
    }
  }
  
  render() {
    const {
      classes,
      uiLoading,
      parcels,
      onParcelChange,
      onAddParcel,
      onRemoveParcel,
    } = this.props
    const {
      totalParcels,
      totalWeight,
    } = this.state
    
    return (
      <div>
        <FormControl component="fieldset" margin="dense" fullWidth>
          <FormLabel component="legend" className={classes.legend}>
            {`${totalParcels} Parcel${totalParcels > 1 ? 's' : ''} (${totalWeight}kg)`}
          </FormLabel>
          {parcels.map((weight, key) => (
            <TextField
              className={classes.parcel}
              key={key}
              fullWidth
              type="number"
              value={weight}
              onChange={onParcelChange(key)}
              disabled={uiLoading}
              helperText="Weight (kg)"
              InputProps={{
                endAdornment: (
                  (key !== 0) && (key + 1) === totalParcels
                  ? (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={onRemoveParcel}
                        disabled={uiLoading}
                        >
                          <IconDelete />
                        </IconButton>
                      </InputAdornment>
                    )
                    : null
                  )
                }}
            />
          ))}
        </FormControl>
        <div className={classes.add}>
          <IconButton
            onClick={onAddParcel}
            disabled={uiLoading}
          >
            <IconAdd />
          </IconButton>
        </div>
      </div>
    )
  }
}

CourierFormParcels.propTypes = {
  classes: PropTypes.object.isRequired,
  uiLoading: PropTypes.bool.isRequired,
  parcels: PropTypes.array.isRequired,
  onParcelChange: PropTypes.func.isRequired,
  onAddParcel: PropTypes.func.isRequired,
  onRemoveParcel: PropTypes.func.isRequired,
}

export default withStyles(styles)(CourierFormParcels)
