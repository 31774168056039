import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import {
  equals, path, length, pluck, contains, isEmpty, pipe, filter, split, head, propOr
} from 'ramda'

import {
  isGridColumnSortingApplied,
  formatDate,
} from '../util/helpers'

// Material UI
import { withStyles } from '@material-ui/core/styles'
import { green, yellow, lightBlue } from '@material-ui/core/colors'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import IconVisibility from '@material-ui/icons/Visibility'
import IconDone from '@material-ui/icons/Done'
import IconCardGiftcard from '@material-ui/icons/CardGiftcard'
import IconDateRange from '@material-ui/icons/DateRange'
import CircularProgress from '@material-ui/core/CircularProgress'

// React Grid
import {
  SortingState, SelectionState, FilteringState, PagingState, RowDetailState,
  IntegratedSelection, DataTypeProvider, CustomPaging,
} from '@devexpress/dx-react-grid'
import {
  Grid as ReactGrid,
  Table, VirtualTable, TableHeaderRow, TableSelection, TableFilterRow, TableRowDetail,
  PagingPanel, TableColumnVisibility,
} from '@devexpress/dx-react-grid-material-ui'

// Components
import LinkButton from '../components/LinkButton'
import GridOrderItems from '../components/GridOrderItems'
import GridColumnChooserCell from '../components/GridColumnChooserCell'
import GridFilterDateField from './GridFilterDateField'
import GridFilterSelectField from './GridFilterSelectField'
import GridDateTypeProvider from '../components/GridDateTypeProvider'

const ReactGridRoot = props => <ReactGrid.Root {...props} style={{ height: '100%' }} />

const styles = theme => ({
  iconAlert: {
    verticalAlign: 'middle',
    marginRight: 8,
    pointerEvents: 'none',
    fontSize: 22
  },
  cellCompleted: {
    display: 'flex',
    alignItems: 'center'
  },
  rowDetails: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  warning: {
    color: yellow['A400'],
  },
})


class GridOrders extends PureComponent {
  // state = {
  //
  // }

  // Grid
  handleGridSorting = sort => {
    const { sorting, onSortingChange } = this.props
    const { columnName } = sort[0]

    // Descending as default direction
    if (
      contains(columnName, [
        'completed', 'order_reference', 'order_date'
      ])
      && !isGridColumnSortingApplied(columnName, sorting)
    ) {
      sort[0].direction = 'desc';
    }

    onSortingChange(sort)
  }

  headerCellComponent = cellProps => {
    const {
      columns,
      visibilityColumnExtensions,
      hiddenColumnNames,
      onColumnVisibilityChange,
    } = this.props
    const columnName = path(['column', 'name'], cellProps)
    const disabledColumns = pipe(
      filter(column => !column.togglingEnabled),
      pluck('columnName'),
    )(visibilityColumnExtensions)

    // No label and sorting
    if (equals(columnName, 'action')) {
      return (
        <GridColumnChooserCell
          columns={columns}
          hiddenColumns={hiddenColumnNames}
          disabledColumns={disabledColumns}
          onChange={onColumnVisibilityChange}
        />
      )
    }

    return <TableHeaderRow.Cell {...cellProps} />
  }

  filterCellComponent = cellProps => {
    const columnName = path(['column', 'name'], cellProps)

    // Date filter
    if (equals(columnName, 'order_date')) {
      return (
        <TableFilterRow.Cell {...cellProps}>
          <GridFilterDateField
            filter={cellProps.filter}
            onFilter={cellProps.onFilter}
          />
        </TableFilterRow.Cell>
      )
    }

    // Status filter
    if (equals(columnName, 'status')) {
      const { statuses } = this.props
      const options = pluck('title', statuses)
      return (
        <TableFilterRow.Cell {...cellProps}>
          <GridFilterSelectField
            options={options}
            filter={cellProps.filter}
            onFilter={cellProps.onFilter}
          />
        </TableFilterRow.Cell>
      )
    }

    // No filter
    if (equals(columnName, 'action')) {
      return <Table.Cell />
    }

    return <TableFilterRow.Cell {...cellProps} />
  }

  rowComponent = rowProps => {
    return (
      <Table.Row hover {...rowProps} />
    )
  }

  rowDetailComponent = ({ row }) => {
    const {
      classes,
      orderItemsColumns,
      orderItemsColumnExtensions,
      orderItemsSorting,
      onOrderItemsSortingChange,
    } = this.props
    const {
      order_items: orderItems,
      gift_message: giftMessage,
      warehouse_instructions: warehouseInstructions,
      delivery_date: deliveryDate,
    } = row

    return (
      <div className={classes.rowDetails}>
        <Typography variant="subtitle1" paragraph>
          <strong>{'Gift Message:'}</strong> {giftMessage || 'n/a'}
        </Typography>
        <Typography variant="subtitle1" paragraph>
          <strong>{'Warehouse Instructions:'}</strong> {warehouseInstructions || 'n/a'}
        </Typography>
        <Typography variant="subtitle1" paragraph>
          <strong>{'Delivery Date:'}</strong> {formatDate(deliveryDate, 'dddd, MMM Do YYYY') || 'n/a'}
        </Typography>
        {
          (!isEmpty(orderItemsColumns) && !isEmpty(orderItems))
          ? (
            <Paper square>
              <GridOrderItems
                rows={orderItems}
                columns={orderItemsColumns}
                columnExtensions={orderItemsColumnExtensions}
                sorting={orderItemsSorting}
                onSortingChange={onOrderItemsSortingChange}
              />
            </Paper>
          )
          : null
        }
      </div>
    )
  }

  cellComponent = ({ style, ...cellProps }) => {
    const { classes } = this.props
    const columnName = path(['column', 'name'], cellProps)
    let cellStyle = style

    // TODO Wrap cell
    if (contains(columnName, [])) {
      cellStyle = {
        ...cellStyle,
        whiteSpace: 'pre-line',
      }
    }

    // Indicate order fulfilment percentage
    if (equals(columnName, 'completed')) {
      const {
        items_ordered: itemsOrdered,
        items_allocated: itemsAllocated,
        items_notified: itemsNotified,
        items_shipped: itemsShipped,
        items_fulfilled: itemsFulfilled,
        items_can_be_fulfilled: itemsCanBeFulfilled,
      } = cellProps.row

      const percentFulfilled = cellProps.value
      const percentCanBeFulfilled = Math.round(((itemsFulfilled + itemsCanBeFulfilled) / itemsOrdered) * 100)
      const percentItemsNotified = Math.round(((itemsFulfilled + itemsCanBeFulfilled + itemsNotified) / itemsOrdered) * 100)
      const allShipped = (itemsShipped === itemsOrdered)

      return (
        <Table.Cell
          style={cellStyle}
          {...cellProps}
        >
          <div className={classes.cellCompleted}>
            {!allShipped && (
              <CircularProgress
                style={{
                  verticalAlign: 'inherit',
                  marginRight: -20,
                  opacity: 0.2,
                  pointerEvents: 'none',
                }}
                variant="static"
                size={20}
                value={100}
                thickness={3.6}
              />
            )}
            {
              (!allShipped && percentItemsNotified)
              ? (
                <CircularProgress
                  style={{
                    verticalAlign: 'inherit',
                    marginRight: -20,
                    color: lightBlue['A100'],
                    pointerEvents: 'none',
                  }}
                  variant="static"
                  size={20}
                  value={percentItemsNotified}
                  thickness={3.4}
                />
              )
              : null
            }
            {
              (!allShipped && itemsCanBeFulfilled)
              ? (
                <CircularProgress
                  style={{
                    verticalAlign: 'inherit',
                    marginRight: -20,
                    color: yellow['A400'],
                    pointerEvents: 'none',
                  }}
                  variant="static"
                  size={20}
                  value={percentCanBeFulfilled}
                  thickness={3.4}
                />
              )
              : null
            }
            {!allShipped && (
              <CircularProgress
                style={{
                  verticalAlign: 'inherit',
                  marginRight: 8,
                  color: green['A400'],
                  pointerEvents: 'none',
                }}
                variant="static"
                size={20}
                value={percentFulfilled}
                thickness={3.6}
              />
            )}
            {allShipped && (
              <IconDone
                style={{
                  verticalAlign: 'inherit',
                  marginRight: 8,
                  pointerEvents: 'none',
                  fontSize: 22
                }}
                color="primary"
              />
            )}
            <div>
              {`${cellProps.value}% (${itemsOrdered}/${itemsFulfilled})`}<br />
              {
                (!allShipped & percentFulfilled === 100 & itemsOrdered !== itemsAllocated)
                ? (
                  <i className={classes.warning}>
                    {`${itemsAllocated} allocated`}
                  </i>
                )
                : null
              }
            </div>
          </div>
        </Table.Cell>
      )
    }

    // Indicate order is dated delivery
    if (equals(columnName, 'order_reference')) {
      const rowId = path(['row', 'id'], cellProps)
      const deliveryDate = path(['row', 'delivery_date'], cellProps)
      let warehouseInstructions = path(['row', 'warehouse_instructions'], cellProps)

      if (deliveryDate) {
        return (
          <Table.Cell style={cellStyle} {...cellProps}>
            <Tooltip
              id={`tooltip-delivery-${rowId}`}
              title={`Delivery date: ${formatDate(deliveryDate, 'dddd, MMM Do YYYY')}`}
              placement="top-start"
            >
              <span>
                <IconDateRange
                  className={classes.iconAlert}
                  color="error"
                />
                {cellProps.value}
                {warehouseInstructions && [
                  <br key="br" />,
                  <i key="warning" className={classes.warning}>{warehouseInstructions}</i>]
                }
              </span>
            </Tooltip>
          </Table.Cell>
        )
      } else if (warehouseInstructions) {
        return (
          <Table.Cell style={cellStyle} {...cellProps}>
            {cellProps.value}
            {[
              <br key="br" />,
              <i key="warning" className={classes.warning}>{warehouseInstructions}</i>
            ]}
          </Table.Cell>
        )
      }
    }

    // Indicate order is a gift
    if (equals(columnName, 'customer_delivery_contact')) {
      const rowId = path(['row', 'id'], cellProps)
      const giftMessage = path(['row', 'gift_message'], cellProps)

      if (giftMessage) {
        return (
          <Table.Cell style={cellStyle} {...cellProps}>
            <Tooltip
              id={`tooltip-gift-${rowId}`}
              title="Gift order"
              placement="top-start"
            >
              <span>
                <IconCardGiftcard
                  className={classes.iconAlert}
                  color="error"
                />
                {cellProps.value}
              </span>
            </Tooltip>
          </Table.Cell>
        )
      }
    }

    if (equals(columnName, 'customer_delivery_postcode')) {
      const { couriers } = this.props
      // NOTE UK specific..
      const postcodeArea = pipe(split(' '), head)(cellProps.value || '')
      const collectionPostcode = process.env.REACT_APP_COLLECTION_POSTCODE

      return (
        <Table.Cell style={cellStyle} {...cellProps}>
          {cellProps.value}
          {
            cellProps.value === collectionPostcode
            ? [<br key="br" />, <i key="warning" className={classes.warning}>{'(Collection)'}</i>]
            : couriers.map(courier => {
              const coverage = propOr([], 'coverage_postcodes', courier)
              if (contains(postcodeArea, coverage)) {
                return [<br key="br" />, <i key="warning" className={classes.warning}>{'(Packfleet)'}</i>]
              }
            })
          }
        </Table.Cell>
      )
    }

    // Actions
    if (equals(columnName, 'action')) {
      const orderReference = path(['row', 'order_reference'], cellProps)

      return (
        <Table.Cell style={cellStyle} {...cellProps}>
          <LinkButton
            title={`Open order ${orderReference}`}
            to={{
              pathname: `/orders/${orderReference}`,
              state: { from: '/orders' }
            }}
          >
            <IconVisibility color="action" />
          </LinkButton>
        </Table.Cell>
      )
    }

    return <Table.Cell style={cellStyle} {...cellProps} />
  }

  cellComponentNoData = colSpan => {
    // TODO
    return (
      <Table.Cell colSpan={colSpan}>
        {<Typography variant="body1" align="center">{'No orders.'}</Typography>}
      </Table.Cell>
    )
  }

  render() {
    const {
      // classes,
      virtual,
      rows,
      columns,
      columnExtensions,
      hiddenColumnNames,
      sorting,
      onSortingChange,
      expandedRowIds,
      onExpandedRowIdsChange,
      selection,
      onSelectionChange,
      filters,
      onFiltersChange,
      currentPage,
      onCurrentPageChange,
      totalCount,
      pageSize,
      onPageSizeChange,
      pageSizes,
    } = this.props
    const showSorting = sorting && onSortingChange ? true : false
    const showRowDetail = expandedRowIds && onExpandedRowIdsChange ? true : false
    const showSelection = selection && onSelectionChange ? true : false
    const showFilters = filters && onFiltersChange ? true : false
    const showPaging = (currentPage >= 0) && onCurrentPageChange && totalCount && pageSize && onPageSizeChange ? true : false
    let columnsLength = length(columns) - length(hiddenColumnNames)
    if (showRowDetail) columnsLength += 1
    if (showSelection) columnsLength += 1

    const ReactGridTable = virtual ? VirtualTable : Table

    return (
      <ReactGrid
        rows={rows}
        columns={columns}
        rootComponent={ReactGridRoot}
      >
        <GridDateTypeProvider
          columns={['order_date']}
        />
        <GridDateTypeProvider
          columns={['delivery_date']}
          format={'DD/MM/YYYY'}
        />
        <DataTypeProvider
          for={['completed']}
          availableFilterOperations={[
            'greaterThanOrEqual',
            'greaterThan',
            'lessThanOrEqual',
            'lessThan',
          ]}
        />
        <DataTypeProvider
          for={[
            'order_reference',
            'order_date',
            'customer_billing_contact',
            'customer_delivery_contact',
            'status',
            'customer_delivery_city',
            'customer_delivery_country',
            'customer_delivery_postcode',
          ]}
          availableFilterOperations={[]}
        />
        {showFilters && (
          <FilteringState
            filters={filters}
            onFiltersChange={onFiltersChange}
          />
        )}
        {showSorting && (
          <SortingState
            sorting={sorting}
            onSortingChange={this.handleGridSorting}
          />
        )}
        {showRowDetail && (
          <RowDetailState
            expandedRowIds={expandedRowIds}
            onExpandedRowIdsChange={onExpandedRowIdsChange}
          />
        )}
        {showSelection && (
          <SelectionState
            selection={selection}
            onSelectionChange={onSelectionChange}
          />
        )}
        {showPaging && (
          <PagingState
            currentPage={currentPage}
            onCurrentPageChange={onCurrentPageChange}
            pageSize={pageSize}
            onPageSizeChange={onPageSizeChange}
          />
        )}
        {showPaging && (
          <CustomPaging
            totalCount={totalCount}
          />
        )}
        {showSelection && (
          <IntegratedSelection />
        )}
        <ReactGridTable
          columnExtensions={columnExtensions}
          rowComponent={this.rowComponent}
          cellComponent={this.cellComponent}
          noDataCellComponent={() => this.cellComponentNoData(columnsLength)}
        />
        <TableHeaderRow
          showSortingControls={showSorting}
          cellComponent={this.headerCellComponent}
        />
        <TableColumnVisibility
          hiddenColumnNames={hiddenColumnNames}
        />
        {showRowDetail && (
          <TableRowDetail
            contentComponent={this.rowDetailComponent}
          />
        )}
        {showSelection && (
          <TableSelection
            showSelectAll
          />
        )}
        {showFilters && (
          <TableFilterRow
            showFilterSelector
            cellComponent={this.filterCellComponent}
          />
        )}
        {showPaging && (
          <PagingPanel
            pageSizes={pageSizes}
          />
        )}
      </ReactGrid>
    )
  }
}

GridOrders.propTypes = {
  classes: PropTypes.object.isRequired,
  virtual: PropTypes.bool.isRequired,
  statuses: PropTypes.array.isRequired,
  rows: PropTypes.array.isRequired,
  couriers: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  columnExtensions: PropTypes.array.isRequired,
  visibilityColumnExtensions: PropTypes.array.isRequired,
  hiddenColumnNames: PropTypes.array.isRequired,
  onColumnVisibilityChange: PropTypes.func.isRequired,
  sorting: PropTypes.array,
  onSortingChange: PropTypes.func,
  expandedRowIds: PropTypes.array,
  onExpandedRowIdsChange: PropTypes.func,
  selection: PropTypes.array,
  onSelectionChange: PropTypes.func,
  filters: PropTypes.array,
  onFiltersChange: PropTypes.func,
  currentPage: PropTypes.number,
  onCurrentPageChange: PropTypes.func,
  totalCount: PropTypes.number,
  pageSize: PropTypes.number,
  onPageSizeChange: PropTypes.func,
  pageSizes: PropTypes.arrayOf(PropTypes.number),
  // Row details
  orderItemsColumns: PropTypes.array,
  orderItemsColumnExtensions: PropTypes.array,
  orderItemsSorting: PropTypes.array,
  onOrderItemsSortingChange: PropTypes.func,
}

GridOrders.defaultProps = {
  virtual: false,
  statuses: [],
  columnExtensions: [],
  visibilityColumnExtensions: [],
  hiddenColumnNames: [],
  onColumnVisibilityChange: () => {},
  orderItemsColumns: [],
  orderItemsColumnExtensions: [],
  orderItemsSorting: [],
  onOrderItemsSortingChange: () => {},
}

export default withStyles(styles)(GridOrders)
