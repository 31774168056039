import React from 'react'
import PropTypes from 'prop-types'

import { Link } from 'react-router-dom'

// Material UI
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'

const LinkButton = ({
  title,
  to,
  children,
}) => (
  <Tooltip
    title={title}
    placement="left"
  >
    <IconButton
      component={React.forwardRef((props, ref) => <Link to={to} ref={ref} {...props} />)}
    >
      {children}
    </IconButton>
  </Tooltip>
)

LinkButton.propTypes = {
  title: PropTypes.string.isRequired,
  to: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
}

export default LinkButton
