import React from 'react'
import PropTypes from 'prop-types'

import {
  take, takeLast, toString, length, divide, subtract,
} from 'ramda'
import { compose } from 'redux'

// Material UI
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import TextField from '@material-ui/core/TextField'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import CircularProgress from '@material-ui/core/CircularProgress'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import withMobileDialog from '@material-ui/core/withMobileDialog'

const styles = theme => ({

})


const DialogOrderStatusChange = props => {
  const {
    fullScreen,
    // classes,
    open,
    onClose,
    disableBackdropClick,
    disableEscapeKeyDown,
    statuses,
    // TODO not sure if this is needed...
    // defaultStatusId,
    statusId,
    comment,
    onChange,
    onSubmit,
    submitting,
  } = props
  const columnOne = Math.ceil(divide(length(statuses), 2))
  const columnTwo = subtract(length(statuses), columnOne)

  return (
    <Dialog
      fullScreen={fullScreen}
      fullWidth
      open={open}
      onClose={onClose}
      disableBackdropClick={disableBackdropClick}
      disableEscapeKeyDown={disableEscapeKeyDown}
    >
      <DialogTitle>Order Status Update</DialogTitle>
      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <RadioGroup
              name="orderStatusId"
              // defaultValue={toString(defaultStatusId)}
              value={toString(statusId)}
              onChange={onChange('orderStatusId')}
            >
              {take(columnOne, statuses).map(status => (
                <FormControlLabel
                  key={status.id}
                  value={toString(status.id)}
                  control={<Radio />}
                  label={status.title}
                  disabled={submitting}
                />
              ))}
            </RadioGroup>
          </Grid>
          <Grid item xs={6}>
            <RadioGroup
              name="orderStatusId"
              // defaultValue={toString(defaultStatusId)}
              value={toString(statusId)}
              onChange={onChange('orderStatusId')}
            >
              {takeLast(columnTwo, statuses).map(status => (
                <FormControlLabel
                  key={status.id}
                  value={toString(status.id)}
                  control={<Radio />}
                  label={status.title}
                  disabled={submitting}
                />
              ))}
            </RadioGroup>
          </Grid>
        </Grid>
        <TextField
          id="comment"
          // label="Comment"
          placeholder="Why are you changing status?"
          multiline
          rows="5"
          fullWidth
          value={comment}
          margin="normal"
          onChange={onChange('orderStatusComment')}
          disabled={submitting}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          disabled={submitting}
        >
          {'Cancel'}
        </Button>
        <Button
          variant="contained"
          style={{ marginLeft: '10px'}}
          color="primary"
          onClick={onSubmit}
          disabled={!statusId || submitting}
        >
          {submitting
            ? <CircularProgress color="inherit" size={20} />
            : 'Submit'
          }
        </Button>
      </DialogActions>
    </Dialog>
  )
}

DialogOrderStatusChange.propTypes = {
  fullScreen: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  disableBackdropClick: PropTypes.bool.isRequired,
  disableEscapeKeyDown: PropTypes.bool.isRequired,
  statuses: PropTypes.array.isRequired,
  defaultStatusId: PropTypes.number.isRequired,
  statusId: PropTypes.number.isRequired,
  comment: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
}

DialogOrderStatusChange.defaultProps = {
  defaultStatusId: 0,
}

export default compose(
  withMobileDialog(), withStyles(styles)
)(DialogOrderStatusChange)
