import { path, prop } from 'ramda'
import {
  take, put, call,
  fork, cancel, cancelled,
  // delay
} from 'redux-saga/effects'
import { Types, Creators as Actions } from '../actions'


export default api => {
  // AUTH
  function* auth(user) {
    try {
      const body = {
        client_id: process.env.REACT_APP_API_CLIENT_ID,
        client_secret: process.env.REACT_APP_API_CLIENT_SECRET,
        grant_type: 'password',
        ...user,
      }
      
      // yield call(delay, 3000)
      let error = '[500] Authentication failed.'
      const authResp = yield call(api.auth, body)
      
      // Did user login?
      if (prop('ok', authResp)) {
        const authData = prop('data', authResp)
        yield put(Actions.loginAuthSuccess(authData))
        
        // Attempt to get user
        const userResp = yield call(api.getUser)
        
        // Finally logged in?
        if (prop('ok', userResp)) {
          const userData = path(['data', 'data'], userResp)
          const success = path(['data', 'message'], authResp) || '[200] Login successful.'
          return yield put(Actions.loginUserSuccess(userData, success))
        }
      } else {
        error = path(['data', 'message'], authResp) || error
      }
      
      // User did not login
      yield put(Actions.loginFailure(error))
    } catch (error) {
      yield put(Actions.loginFailure(error))
    } finally {
      if (yield cancelled()) {
        // TODO task cancelled
        console.log('Auth task cancelled.')
      }
    }
  }
  
  function* loginFlow() {
    let lastTask
    while (true) {
      const { user } = yield take(Types.LOGIN_ATTEMPT)
      
      if (lastTask) {
        yield cancel(lastTask)
      }
      
      lastTask = yield fork(auth, user)
    }
  }
  
  // LOGOUT
  function* logoutFlow() {
    while (true) {
      yield take(Types.LOGOUT)
      yield call(api.logout)
      api.deleteAuthHeader()
    }
  }
  
  // Forgot Password
  function* forgotPassword(email) {
    try {
      let error = '[500] Forgot Password failed.'
      const forgotResp = yield call(api.forgotPassword, email)
      
      if (prop('ok', forgotResp)) {
        const userData = path(['data', 'data'], forgotResp)
        const success = path(['data', 'message'], forgotResp) || '[200] Forgot Password successful.'
        yield put(Actions.forgotPasswordSuccess(userData, success))
        
      } else {
        error = path(['data', 'message'], forgotResp) || error
        yield put(Actions.forgotPasswordFailure(error))
      }
      
    } catch (error) {
      yield put(Actions.forgotPasswordFailure(error))
    } finally {
      if (yield cancelled()) {
        // TODO task cancelled
        console.log('POST Forgot Password task cancelled.')
      }
    }
  }
  
  function* forgotPasswordFlow() {
    let lastTask
    while (true) {
      const { email } = yield take(Types.FORGOT_PASSWORD_ATTEMPT)
      
      if (lastTask) {
        yield cancel(lastTask)
      }
      
      lastTask = yield fork(forgotPassword, email)
    }
  }
  
  // Reset Password
  function* resetPassword(data) {
    try {
      let error = '[500] Reset Password failed.'
      const resetResp = yield call(api.resetPassword, data)
      
      if (prop('ok', resetResp)) {
        const userData = path(['data', 'data'], resetResp)
        const success = path(['data', 'message'], resetResp) || '[200] Reset Password successful.'
        yield put(Actions.resetPasswordSuccess(userData, success))
      } else {
        error = path(['data', 'message'], resetResp) || error
        yield put(Actions.resetPasswordFailure(error))
      }
      
    } catch (error) {
      yield put(Actions.resetPasswordFailure(error))
    } finally {
      if (yield cancelled()) {
        // TODO task cancelled
        console.log('POST Reset Password task cancelled.')
      }
    }
  }
  
  function* resetPasswordFlow() {
    let lastTask
    while (true) {
      const { data } = yield take(Types.RESET_PASSWORD_ATTEMPT)
      
      if (lastTask) {
        yield cancel(lastTask)
      }
      
      lastTask = yield fork(resetPassword, data)
    }
  }
  
  // GET Users
  function* getUsers() {
    try {
      // yield call(delay, 2000)
      const usersResp = yield call(api.getUsers)
      
      // Did users load?
      if (prop('ok', usersResp)) {
        const data = path(['data', 'data'], usersResp) || []
        yield put(Actions.getUsersSuccess(data))
      } else {
        yield put(Actions.getUsersFailure())
      }
    } catch (error) {
      yield put(Actions.getUsersFailure(error))
    } finally {
      if (yield cancelled()) {
        // TODO task cancelled
        console.log('GET Users task cancelled.')
      }
    }
  }
  
  function* getUsersFlow() {
    let lastTask
    while (true) {
      yield take(Types.GET_USERS_ATTEMPT)
      
      if (lastTask) {
        yield cancel(lastTask)
      }
      
      lastTask = yield fork(getUsers)
    }
  }
  
  return {
    loginFlow,
    logoutFlow,
    getUsersFlow,
    forgotPasswordFlow,
    resetPasswordFlow
  }
}
