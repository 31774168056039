import React, { Component } from 'react'
import PropTypes from 'prop-types'

import {
  path, prop, equals, or, length, pluck, contains, pipe, filter,
} from 'ramda'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import { Creators as Actions } from '../actions'
import {
  isGridColumnSortingApplied,
} from '../util/helpers'
import { productsSelector } from '../util/selectors'

// Material UI
import { withStyles } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import IconWhatshot from '@material-ui/icons/Whatshot'
import IconWarning from '@material-ui/icons/Warning'
import IconVisibility from '@material-ui/icons/Visibility'

// React Grid
import {
  SortingState, FilteringState, PagingState,
  DataTypeProvider, CustomPaging,
} from '@devexpress/dx-react-grid'
import {
  Grid as ReactGrid,
  Table, TableHeaderRow, TableColumnVisibility, TableFilterRow,
  PagingPanel,
} from '@devexpress/dx-react-grid-material-ui'

// Components
import LinkButton from '../components/LinkButton'
import Header from '../components/Header'
import RefreshButton from '../components/RefreshButton'
import Loading from '../components/Loading'
import GridColumnChooserCell from '../components/GridColumnChooserCell'
import GridFilterSelectField from '../components/GridFilterSelectField'
import GridCurrencyTypeProvider from '../components/GridCurrencyTypeProvider'

const styles = theme => ({

})


class Products extends Component {
  // state = {
  //
  // }

  componentDidMount() {
    this.handleMount()
  }

  shouldComponentUpdate(nextProps, nextState) {
    return or(
      !equals(this.props, nextProps),
      !equals(this.state, nextState)
    )
  }

  handleMount = () => {
    this.props.getProductsAttempt({
      // currentPage: 0
    })
  }

  handleGridSorting = sort => {
    const { sorting, onSortingChange } = this.props
    const { columnName } = sort[0]

    // Descending as default direction
    if (
      contains(columnName, [
        'hot', 'cost_ex_vat', 'total_items', 'items_in_stock', 'items_reserved',
        'items_required', 'items_notified', 'items_allocated', 'items_shipped',
        'items_lost_stolen'
      ])
      && !isGridColumnSortingApplied(columnName, sorting)
    ) {
      sort[0].direction = 'desc';
    }

    onSortingChange(sort)
  }

  // Grid
  headerCellComponent = cellProps => {
    const {
      columns,
      visibilityColumnExtensions,
      hiddenColumnNames,
      onColumnVisibilityChange,
    } = this.props
    const columnName = path(['column', 'name'], cellProps)
    const disabledColumns = pipe(
      filter(column => !column.togglingEnabled),
      pluck('columnName'),
    )(visibilityColumnExtensions)

    // No label and sorting
    if (equals(columnName, 'action')) {
      return (
        <GridColumnChooserCell
          columns={columns}
          hiddenColumns={hiddenColumnNames}
          disabledColumns={disabledColumns}
          onChange={onColumnVisibilityChange}
        />
      )
    }

    return <TableHeaderRow.Cell {...cellProps} />
  }

  filterCellComponent = cellProps => {
    const { suppliers } = this.props
    const supplierOptions = pluck('name', suppliers)
    const columnName = path(['column', 'name'], cellProps)

    // Suppliers filter
    if (equals(columnName, 'supplier') && length(supplierOptions)) {
      return (
        <TableFilterRow.Cell {...cellProps}>
          <GridFilterSelectField
            options={supplierOptions}
            filter={cellProps.filter}
            onFilter={cellProps.onFilter}
          />
        </TableFilterRow.Cell>
      )
    }

    // Hot filter
    if (equals(columnName, 'hot')) {
      const hotOptions = ['Yes', 'No']
      return (
        <TableFilterRow.Cell {...cellProps}>
          <GridFilterSelectField
            options={hotOptions}
            filter={cellProps.filter}
            onFilter={cellProps.onFilter}
          />
        </TableFilterRow.Cell>
      )
    }

    // No filter
    if (equals(columnName, 'action')) {
      return <Table.Cell />
    }

    return <TableFilterRow.Cell {...cellProps} />
  }

  rowComponent = rowProps => {
    return (
      <Table.Row hover {...rowProps} />
    )
  }

  cellComponent = ({ style, ...cellProps }) => {
    const columnName = path(['column', 'name'], cellProps)
    let cellStyle = style

    // Wrap cell
    if (contains(columnName, ['description', 'location', 'supplier'])) {
      cellStyle = {
        ...cellStyle,
        whiteSpace: 'pre-line',
      }
    }

    // Hot icon
    if (equals(columnName, 'hot') && cellProps.value) {
      return (
        <Table.Cell style={cellStyle} {...cellProps}>
          <Tooltip
            title="Hot"
            placement="top"
          >
            <IconWhatshot color="error" />
          </Tooltip>
        </Table.Cell>
      )
    }

    // Warning icon if no location
    if (equals(columnName, 'location') && !cellProps.value) {
      return (
        <Table.Cell style={cellStyle} {...cellProps}>
          <IconWarning color="disabled" />
        </Table.Cell>
      )
    }

    // Actions
    if (equals(columnName, 'action')) {
      const { location: routerLocation } = this.props
      const pathname = prop('pathname', routerLocation)
      const skuCode = path(['row', 'sku'], cellProps)

      return (
        <Table.Cell style={cellStyle} {...cellProps}>
          <LinkButton
            title={`Open product ${skuCode}`}
            to={{
              pathname: `/products/${skuCode}`,
              state: { from: pathname }
            }}
          >
            <IconVisibility color="action" />
          </LinkButton>
        </Table.Cell>
      )
    }

    return <Table.Cell style={cellStyle} {...cellProps} />
  }

  cellComponentNoData = () => {
    const { columns, hiddenColumnNames } = this.props
    const colSpan = length(columns) - length(hiddenColumnNames)

    return (
      <Table.Cell colSpan={colSpan}>
        {<Typography variant="body1" align="center">{'No products.'}</Typography>}
      </Table.Cell>
    )
  }

  render() {
    const {
      // classes,
      // Grid
      uiLoadingGet,
      data,
      columns,
      columnExtensions,
      hiddenColumnNames,
      sorting,
      filters,
      onFiltersChange,
      currentPage,
      onCurrentPageChange,
      totalCount,
      pageSizes,
      pageSize,
      onPageSizeChange,
    } = this.props

    return (
      <div>
        <Header
          title={'Products'}
          subtitle={`Browsing page ${currentPage + 1}`}
        >
          <RefreshButton
            onClick={this.handleMount}
          />
        </Header>
        <Paper>
          <ReactGrid
            rows={data}
            columns={columns.asMutable()}
          >
            <GridCurrencyTypeProvider
              columns={['cost_ex_vat']}
            />
            <DataTypeProvider
              for={[
                'sku',
                'description',
                'location',
                'supplier',
              ]}
              availableFilterOperations={[]}
            />
            <DataTypeProvider
              for={[
                'cost_ex_vat',
                'total_items',
                'items_in_stock',
                'items_reserved',
                'items_required',
                'items_notified',
                'items_allocated',
                'items_shipped',
                'items_lost_stolen'
              ]}
              availableFilterOperations={[
                'greaterThanOrEqual',
                'greaterThan',
                'lessThanOrEqual',
                'lessThan',
              ]}
            />
            <FilteringState
              filters={filters.asMutable()}
              onFiltersChange={onFiltersChange}
            />
            <SortingState
              sorting={sorting.asMutable()}
              onSortingChange={this.handleGridSorting}
            />
            <PagingState
              currentPage={currentPage}
              onCurrentPageChange={onCurrentPageChange}
              pageSize={pageSize}
              onPageSizeChange={onPageSizeChange}
            />
            <CustomPaging
              totalCount={totalCount}
            />
            <Table
              columnExtensions={columnExtensions.asMutable()}
              rowComponent={this.rowComponent}
              cellComponent={this.cellComponent}
              noDataCellComponent={this.cellComponentNoData}
            />
            <TableHeaderRow
              showSortingControls
              cellComponent={this.headerCellComponent}
            />
            <TableColumnVisibility
              hiddenColumnNames={hiddenColumnNames}
            />
            <TableFilterRow
              showFilterSelector
              cellComponent={this.filterCellComponent}
            />
            <PagingPanel pageSizes={pageSizes} />
          </ReactGrid>
        </Paper>
        {uiLoadingGet && <Loading message="Loading products..." />}
      </div>
    )
  }
}

Products.propTypes = {
  classes: PropTypes.object.isRequired,
  // Grid
  getProductsAttempt: PropTypes.func.isRequired,
  uiLoadingGet: PropTypes.bool.isRequired,
  suppliers: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  columnExtensions: PropTypes.array.isRequired,
  visibilityColumnExtensions: PropTypes.array.isRequired,
  hiddenColumnNames: PropTypes.array.isRequired,
  onColumnVisibilityChange: PropTypes.func.isRequired,
  sorting: PropTypes.array.isRequired,
  onSortingChange: PropTypes.func.isRequired,
  filters: PropTypes.array.isRequired,
  onFiltersChange: PropTypes.func.isRequired,
  currentPage: PropTypes.number.isRequired,
  onCurrentPageChange: PropTypes.func.isRequired,
  totalCount: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  onPageSizeChange: PropTypes.func.isRequired,
  pageSizes: PropTypes.arrayOf(PropTypes.number).isRequired,
}

const {
  watchProductsFilter,
  getProductsAttempt,
  setProductsGridState,
} = Actions

const mapStateToProps = state => ({
  ...state.products,
  data: productsSelector(state),
  suppliers: state.app.suppliers,
})

const mapDispatchToProps = dispatch => bindActionCreators({
  getProductsAttempt,
  onColumnVisibilityChange: columnNames => setProductsGridState('hiddenColumnNames', columnNames),
  onSortingChange: sorting => getProductsAttempt({
    sorting: sorting
  }),
  onFiltersChange: filters => watchProductsFilter({
    currentPage: 0,
    filters: filters
  }),
  onCurrentPageChange: currentPage => getProductsAttempt({
    currentPage: currentPage
  }),
  onPageSizeChange: pageSize => getProductsAttempt({
    currentPage: 0,
    pageSize: pageSize
  }),
}, dispatch)

export default compose(
  withStyles(styles), connect(mapStateToProps, mapDispatchToProps)
)(Products)
