import { path, prop } from 'ramda'
import {
  take, put, call,
  fork, cancel, cancelled,
  // delay
} from 'redux-saga/effects'
import { Types, Creators as Actions } from '../actions'


export default api => {
  // GET Restock
  function* getRestock() {
    try {
      // yield call(delay, 2000)
      const restockResp = yield call(api.getRestock)
      
      // Did restock load?
      if (prop('ok', restockResp)) {
        const data = path(['data', 'data'], restockResp) || []
        const success = path(['data', 'message'], restockResp) || '[200] Restock loaded.'
        yield put(Actions.getRestockSuccess(data, success))
      } else {
        const error = path(['data', 'message'], restockResp) || '[500] Failed to load restock.'
        const problem = prop('problem', restockResp)
        const status = prop('status', restockResp)
        yield put(Actions.getRestockFailure(error, problem, status))
      }
    } catch (error) {
      yield put(Actions.getRestockFailure(error))
    } finally {
      if (yield cancelled()) {
        // TODO task cancelled
        console.log('GET Restock task cancelled.')
        yield put(Actions.getRestockFailure())
      }
    }
  }
  
  function* getFlow() {
    let lastTask
    while (true) {
      yield take(Types.GET_RESTOCK_ATTEMPT)
      
      if (lastTask) {
        yield cancel(lastTask)
      }
      
      lastTask = yield fork(getRestock)
    }
  }
  
  return {
    getFlow,
  }
}
