import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import moment from 'moment'
import { equals, length } from 'ramda'

// Material UI
import { withStyles } from '@material-ui/core/styles'
import { yellow } from '@material-ui/core/colors'
import Typography from '@material-ui/core/Typography'

const styles = theme => ({
  jobs: {
    width: '100%'
  },
  jobsRemaining: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%'
  },
  jobsFailed: {
    color: theme.palette.error.main,
    fontStyle: 'italic'
  },
  warning: {
    color: yellow['A400'],
  },
})


class WidgetJobs extends PureComponent {
  state = {
    intervalId: null,
    intervalIdle: 0,
  }

  // componentDidMount() {
  //   console.log('jobs mounted');
  // }

  componentWillUnmount() {
    console.log('stop jobs timer:', this.state.intervalId);
    clearInterval(this.state.intervalId)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      !this.state.intervalId
      && !equals(this.props.jobs, nextProps.jobs) && length(nextProps.jobs)
    ) {
      console.log('start jobs timer:', this.state.intervalId);
      const intervalId = setInterval(this.timer, 1000)
      this.setState({ intervalId: intervalId })
    }

    if (!equals(this.props.jobs, nextProps.jobs) && !length(nextProps.jobs)) {
      console.log('stop jobs timer:', this.state.intervalId);
      clearInterval(this.state.intervalId)
      this.setState({ intervalId: null, intervalIdle: 0 })
    }
  }

  timer = () => {
    const { lastJobProcessedDate } = this.props
    const idle = Math.max(0, moment().diff(lastJobProcessedDate, 'seconds'))
    // console.log('jobs timer:', this.state.intervalId, idle);
    this.setState({ intervalIdle: idle })
  }

  render() {
    const {
      classes,
      jobs,
      failedJobs,
    } = this.props
    const { intervalIdle } = this.state
    let idle = `${intervalIdle}s`
    if (intervalIdle > 3600) {
      idle = `${Math.floor(intervalIdle/3600)}h`
    } else if (intervalIdle > 99) {
      idle = `${Math.floor(intervalIdle/60)}m`
    }

    return (
      <div className={classes.jobs}>
        <div className={classes.jobsRemaining}>
          <Typography
            className={(length(jobs) && intervalIdle > 60) ? classes.warning : null}
            variant="body2"
          >
            {`${length(jobs)} jobs remaining`}
          </Typography>
          <Typography
            className={(length(jobs) && intervalIdle > 60) ? classes.warning : null}
            variant="body2"
          >
            {`${idle} idle`}
          </Typography>
        </div>
        {
          length(failedJobs)
          ? (
            <div className={classes.jobsFailed}>
              <Typography variant="body2">
                {`${length(failedJobs)} failed`}
              </Typography>
            </div>
          )
          : null
        }
      </div>
    )
  }
}

WidgetJobs.propTypes = {
  classes: PropTypes.object.isRequired,
  jobs: PropTypes.array.isRequired,
  failedJobs: PropTypes.array.isRequired,
  lastJobProcessedDate: PropTypes.string.isRequired,
}


export default withStyles(styles)(WidgetJobs)
