import apisauce from 'apisauce'
import { stringify } from 'qs'
import { prop, contains } from 'ramda'

// Define API
const api = apisauce.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    'Accept': 'application/json',
  },
  timeout: 120000,
})

// Define API transforms
api.addRequestTransform(request => {
  const method = prop('method', request)

  if (contains(method, ['post', 'put'])) {
    request.headers['Content-Type'] = 'application/x-www-form-urlencoded'
    request.data = stringify(request.data)
  }
})

api.addResponseTransform(response => {
  const ok = prop('ok', response)
  const data = prop('data', response)
  const problem = prop('problem', response)
  // console.log('Response ::', ok, response)

  if (!ok) {
    // console.log('Problem ::', problem, response)
    // just mutate the data to what you want.
    // TODO need to map these statuses and errors properly
    switch (problem) {
      // NOTE codes: https://github.com/infinitered/apisauce#problem-codes
      case 'CLIENT_ERROR':
        response.data = {
          status: 'error',
          ...data,
        }
      break
      case 'TIMEOUT_ERROR':
        response.status = 408
        response.data = {
          status: 'error',
          message: 'Network timeout. Please try again.',
          ...data,
        }
      break
      case 'CONNECTION_ERROR':
        response.status = 503
        response.data = {
          status: 'error',
          message: 'Server not available.',
          ...data,
        }
      break
      case 'NETWORK_ERROR':
        response.status = 511
        response.data = {
          status: 'error',
          message: 'Network unavailable.',
          ...data,
        }
      break
      case 'CANCEL_ERROR':
        response.status = 500
        response.data = {
          status: 'error',
          message: 'Request has been cancelled.',
          ...data,
        }
      break
      default:
        response.status = 500
        response.data = {
          status: 'error',
          message: 'System error. Please contact webmaster.',
          ...data,
        }
    }
  }
})

// Endpoints
const ENDPOINT = {
  CONFIG: '/config',
  OAUTH: '/oauth',
  PASSWORD: '/password',
  LOGOUT: '/logout',
  USER: '/user',
  USERS: '/users',
  ORDERS: '/orders',
  COURIERS: '/couriers',
  PRODUCTS: '/products',
  // TODO need to cleanup this inventory endpoint
  STOCK: '/inventory',
  SUPPLIERS: '/suppliers',
  DELIVERIES: '/deliveries',
  RESTOCK: '/restock',
  JOBS: '/jobs',
}

// TODO how to cancel/abort http request ?
export default {
  // Headers
  setAuthHeader: token => api.setHeader('Authorization', token),
  deleteAuthHeader: () => api.deleteHeader('Authorization'),
  // Config
  getConfig: () => api.get(ENDPOINT.CONFIG),
  // Auth
  auth: user => api.post(`${ENDPOINT.OAUTH}/token`, user),
  forgotPassword: email => api.post(`${ENDPOINT.PASSWORD}/forgot`, email),
  resetPassword: data => api.post(`${ENDPOINT.PASSWORD}/reset`, data),
  logout: () => api.get(ENDPOINT.LOGOUT),
  getUser: () => api.get(ENDPOINT.USER),
  getUsers: () => api.get(ENDPOINT.USERS),
  // Order
  getOrderStatuses: () => api.get(`${ENDPOINT.ORDERS}/statuses`),
  getOrders: params => api.get(ENDPOINT.ORDERS, params),
  getOrdersReadyToShip: params => api.get(`${ENDPOINT.ORDERS}/ready-to-ship`, params),
  getOrder: orderReference => api.get(`${ENDPOINT.ORDERS}/${orderReference}`),
  getOrderShipments: orderReference => api.get(`${ENDPOINT.COURIERS}/${orderReference}/shipments`),
  getOrderHistory: orderReference => api.get(`${ENDPOINT.ORDERS}/${orderReference}/history`),
  changeOrderStatus: (orderReference, change) => api.post(`${ENDPOINT.ORDERS}/${orderReference}/status`, change),
  allocateStock: (orderId, skus) => api.post(`${ENDPOINT.ORDERS}/${orderId}/allocate`, skus),
  deallocateStock: (orderId, skus) => api.post(`${ENDPOINT.ORDERS}/${orderId}/deallocate`, skus),
  syncOrder: orderId => api.post(`${ENDPOINT.ORDERS}/sync/${orderId}`),
  deleteOrder: (orderId, data) => api.delete(`${ENDPOINT.ORDERS}/${orderId}/delete`, {}, { data: data }),
  // Couriers
  getCouriers: () => api.get(ENDPOINT.COURIERS),
  getCourierCollections: (courier, params) => api.get(`${ENDPOINT.COURIERS}/${courier}/collections`, params),
  getCourierServices: (courier, shipment) => api.post(`${ENDPOINT.COURIERS}/${courier}/services`, shipment),
  // Shipments
  getShipments: params => api.get(`${ENDPOINT.COURIERS}/shipments`, params),
  getShipmentsActive: params => api.get(`${ENDPOINT.COURIERS}/shipments/active`, params),
  getShipment: shipmentId => api.get(`${ENDPOINT.COURIERS}/shipments/${shipmentId}`),
  deliverShipment: shipmentId => api.put(`${ENDPOINT.COURIERS}/shipments/${shipmentId}/delivered`),
  // TODO this needs to be dynamic endpoint not per courier
  createShipment: (courier, shipment) => api.post(`${ENDPOINT.COURIERS}/${courier}/ship`, shipment),
  deleteShipment: (courier, shipmentId, data) => api.delete(`${ENDPOINT.COURIERS}/${courier}/cancel/${shipmentId}`, data),
  getShipmentLabels: (courier, shipmentId) => api.get(`${ENDPOINT.COURIERS}/${courier}/label/${shipmentId}`, {}, {
    responseType: 'arraybuffer'
  }),
  getShipmentDocuments: (courier, shipmentId) => api.get(`${ENDPOINT.COURIERS}/${courier}/document/${shipmentId}`, {}, {
    responseType: 'arraybuffer'
  }),
  // Product
  getProducts: params => api.get(ENDPOINT.PRODUCTS, params),
  getProduct: skuCode => api.get(`${ENDPOINT.PRODUCTS}/${skuCode}`),
  updateProduct: product => api.post(ENDPOINT.PRODUCTS, product),
  // Product Stock
  getProductStock: skuCode => api.get(`${ENDPOINT.STOCK}/${skuCode}`),
  adjustStock: (skuCode, adjustment) => api.post(`${ENDPOINT.STOCK}/${skuCode}/adjust`, adjustment),
  reserveStock: (skuCode, adjustment) => api.post(`${ENDPOINT.STOCK}/${skuCode}/reserve`, adjustment),
  unreserveStock: (skuCode, adjustment) => api.post(`${ENDPOINT.STOCK}/${skuCode}/unreserve`, adjustment),
  // Suppliers
  getSuppliers: () => api.get(ENDPOINT.SUPPLIERS),
  updateSupplier: (supplierId, supplier) => api.put(`${ENDPOINT.SUPPLIERS}/${supplierId}`, supplier),
  // Deliveries
  getDeliveries: params => api.get(ENDPOINT.DELIVERIES, params),
  // Delivery
  getDelivery: (supplierId, deliveryId) => api.get(`${ENDPOINT.DELIVERIES}/${supplierId}${deliveryId > 0 ? `/${deliveryId}` : ''}`),
  createDelivery: (supplierId, data) => api.post(`${ENDPOINT.DELIVERIES}/${supplierId}`, data),
  updateDelivery: (supplierId, deliveryId, data) => api.put(`${ENDPOINT.DELIVERIES}/${supplierId}/${deliveryId}`, data),
  completeDelivery: (supplierId, deliveryId, data) => api.put(`${ENDPOINT.DELIVERIES}/${supplierId}/${deliveryId}/complete`, data),
  updateDeliveryInvoice: (deliveryId, data) => api.post(`${ENDPOINT.DELIVERIES}/${deliveryId}/invoice`, data),
  deleteDelivery: deliveryId => api.delete(`${ENDPOINT.DELIVERIES}/${deliveryId}`),
  // Restock
  getRestock: () => api.get(ENDPOINT.RESTOCK),
  // Jobs
  getJobs: () => api.get(ENDPOINT.JOBS),
  retryFailedJob: failedJobUuid => api.post(`${ENDPOINT.JOBS}/retry-failed/${failedJobUuid}`),
  deleteFailedJob: failedJobUuid => api.delete(`${ENDPOINT.JOBS}/delete-failed/${failedJobUuid}`),
}
