import startup from './startup'
import notification from './notification'
import app from './app'
import auth from './auth'
import dashboard from './dashboard'
import orders from './orders'
import order from './order'
import shipments from './shipments'
import shipment from './shipment'
import products from './products'
import product from './product'
import suppliers from './suppliers'
import deliveries from './deliveries'
import delivery from './delivery'
import restock from './restock'
import jobs from './jobs'

export default {
  startup,
  notification,
  app,
  auth,
  dashboard,
  orders,
  order,
  shipments,
  shipment,
  products,
  product,
  suppliers,
  deliveries,
  delivery,
  restock,
  jobs
}
