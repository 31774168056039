import {
  pipe, times, identity, length, isEmpty, multiply, map, values, groupBy,
  prop, propOr, curry, when, propEq, assoc, omit, find,
} from 'ramda'
import { Types } from '../actions'
import Immutable from 'seamless-immutable'
import { createReducer } from 'reduxsauce'

const INITIAL_STATE = Immutable({
  // Grid
  uiLoadingGet: false,
  uiLoadingSave: false,
  uiLoadingComplete: false,
  uiLoadingDelete: false,
  data: {},
  // error: null,
  // Grid
  productsColumnExtensions: [
    { columnName: 'sku', width: 120 },
    { columnName: 'hot', width: 65 },
    { columnName: 'cost_ex_vat', width: 115 },
    { columnName: 'total_cost_ex_vat', width: 115 },
    { columnName: 'supplier_case_size', width: 115 },
    { columnName: 'total_ordered', width: 105 },
    { columnName: 'items_required', width: 110 },
    { columnName: 'total_allocated', width: 115 },
    // { columnName: 'total_shipped', width: 105 },
    { columnName: 'items_in_stock', width: 110 },
    // { columnName: 'total_notified', width: 105 },
    { columnName: 'restock', width: 120 },
  ],
  visibilityColumnExtensions: [
    { columnName: 'sku', togglingEnabled: false },
    { columnName: 'description', togglingEnabled: false },
    { columnName: 'restock', togglingEnabled: false },
  ],
  hiddenColumnNames: [
    'total_allocated', 'items_in_stock',
  ],
  // Restock
  restockProducts: [],
  restockProductsColumns: [
    { name: 'sku', title: 'SKU Code' },
    { name: 'description', title: 'Description' },
    { name: 'hot', title: 'Hot', getCellValue: row => row.hot || null },
    { name: 'cost_ex_vat', title: 'Cost (ex VAT)' },
    {
      name: 'total_cost_ex_vat',
      title: 'Total (ex VAT)',
      getCellValue: row => multiply(row.cost_ex_vat, row.restock)
    },
    { name: 'supplier_case_size', title: 'Supplier Case' },
    { name: 'total_ordered', title: 'Ordered Qty' },
    { name: 'items_required', title: 'Required Qty' },
    { name: 'total_allocated', title: 'Allocated Qty' },
    // { name: 'total_shipped', title: 'Shipped Qty' },
    { name: 'items_in_stock', title: 'Available Qty' },
    // { name: 'total_notified', title: 'Notified Qty' },
    { name: 'restock', title: 'Restock Qty' },
  ],
  restockProductsSorting: [
    { columnName: 'description', direction: 'asc' },
  ],
  restockProductsSelection: [],
  // Other
  otherProducts: [],
  otherProductsColumns: [
    { name: 'sku', title: 'SKU Code' },
    { name: 'description', title: 'Description' },
    { name: 'hot', title: 'Hot', getCellValue: row => row.hot || null },
    { name: 'cost_ex_vat', title: 'Cost (ex VAT)' },
    {
      name: 'total_cost_ex_vat',
      title: 'Total (ex VAT)',
      getCellValue: row => multiply(row.cost_ex_vat, row.restock)
    },
    { name: 'supplier_case_size', title: 'Supplier Case' },
    { name: 'total_ordered', title: 'Ordered Qty' },
    { name: 'items_required', title: 'Required Qty' },
    { name: 'total_allocated', title: 'Allocated Qty' },
    // { name: 'total_shipped', title: 'Shipped Qty' },
    { name: 'items_in_stock', title: 'Available Qty' },
    // { name: 'total_notified', title: 'Notified Qty' },
    { name: 'restock', title: 'Restock Qty' },
  ],
  otherProductsSorting: [
    { columnName: 'description', direction: 'asc' },
  ],
  // Orders Grid
  ordersColumns: [
    { name: 'order_status', title: 'Status' },
    { name: 'order_reference', title: 'Order No.' },
    { name: 'total_items', title: 'Total Products' },
    { name: 'order_date', title: 'Order Date' },
    { name: 'consignee', title: 'Consignee' },
    { name: 'ordered', title: 'Ordered Qty' },
    { name: 'notified', title: 'Notified Qty' },
    { name: 'allocated', title: 'Allocated Qty' },
    { name: 'shipped', title: 'Shipped Qty' },
    { name: 'action', title: 'View', getCellValue: row => row.order_reference },
  ],
  ordersColumnExtensions: [
    { columnName: 'ordered', width: 100 },
    { columnName: 'notified', width: 100 },
    { columnName: 'allocated', width: 100 },
    { columnName: 'shipped', width: 100 },
    { columnName: 'action', width: 70, align: 'right' },
  ],
  // Invoice
  invoice: {},
  invoiceProducts: [],
  invoiceProductsColumns: [
    { name: 'supplier_code', title: 'Supplier Code' },
    { name: 'description', title: 'Description' },
    {
      name: 'total_cost_ex_vat',
      title: 'Total Cost (ex VAT)',
      getCellValue: row => {
        if (row.total_cost_ex_vat) {
          return Number(row.total_cost_ex_vat).toFixed(2)
        }

        return row.total_cost_ex_vat
      }
    },
    { name: 'unmatched', title: ' ' },
    {
      name: 'invoice_cost_ex_vat',
      title: 'Invoice Cost (ex VAT)'
    },
  ],
  invoiceProductsColumnsExtensions: [
    { columnName: 'supplier_code', width: 145 },
    { columnName: 'total_cost_ex_vat', width: 135 },
    { columnName: 'unmatched', width: 60 },
    { columnName: 'invoice_cost_ex_vat', width: 155 },
  ],
})

const selectProducts = data => times(identity, length(data))

// GET Delivery
const getDeliveryAttempt = state => {
  return state.merge({
    uiLoadingGet: true,
  })
}

const getDeliverySuccess = (state, { data }) => {
  const { delivery, products, other_products, invoice } = data
  let selection = []
  if (!isEmpty(delivery)) selection = selectProducts(products)

  return state.merge({
    uiLoadingGet: false,
    data: {
      ...state.data,
      ...delivery,
    },
    restockProducts: products,
    restockProductsSelection: selection,
    otherProducts: other_products,
    invoice: omit(['invoice_products'], invoice),
    invoiceProducts: propOr([], 'invoice_products', invoice),
  })
}

const getDeliveryFailure = state => {
  return state.merge({
    uiLoadingGet: false,
  })
}

const setDeliveryGridState = (state, { name, value }) => {
  return state.set(name, value)
}

// POST/PUT Delivery
const saveDeliveryAttempt = state => {
  return state.merge({
    uiLoadingSave: true,
  })
}

const saveDeliverySuccess = (state, { data }) => {
  const { delivery, products, other_products } = data

  return state.merge({
    uiLoadingSave: false,
    data: delivery,
    restockProducts: products,
    restockProductsSelection: selectProducts(products),
    otherProducts: other_products,
  })
}

const saveDeliveryFailure = state => {
  return state.merge({
    uiLoadingSave: false,
  })
}

// PUT Complete Delivery
const completeDeliveryAttempt = state => {
  return state.merge({
    uiLoadingComplete: true,
  })
}

const completeDeliverySuccess = (state, { data }) => {
  const { delivery, products, other_products, invoice } = data

  return state.merge({
    uiLoadingComplete: false,
    data: delivery,
    restockProducts: products,
    restockProductsSelection: selectProducts(products),
    otherProducts: other_products,
    invoice: omit(['invoice_products'], invoice),
    invoiceProducts: propOr([], 'invoice_products', invoice),
  })
}

const completeDeliveryFailure = state => {
  return state.merge({
    uiLoadingComplete: false,
  })
}

// POST/PUT Delivery Invoice
const setInvoice = (state, { invoice })  => {
  const newState = Immutable(state)
  return newState.merge({
    invoice: {
      ...state.invoice,
      ...invoice
    }
  })
}

const setInvoiceProducts = (state, { products })  => {
  const newState = Immutable(state)
  const mergedProducts = pipe(
    groupBy(prop('supplier_code')),
    map(prod => {
      let product = {}
      map(prod => {
        if (isEmpty(product)) {
          product = {
            ...prod
          }
        } else {
          // state product will be first
          // so if second is invoice only update invoice cost
          if (!isEmpty(product.invoice_cost_ex_vat)) {
            product = {
              ...product,
              invoice_cost_ex_vat: prod.invoice_cost_ex_vat
            }
          }
        }
      }, prod)

      const restockProduct = find(
        propEq('supplier_code', product.supplier_code),
        state.restockProducts
      )

      if (restockProduct) {
        return {
          ...product,
          quantity: restockProduct.notified
        }
      }

      return product
    }),
    values,
  )([
    ...state.invoiceProducts,
    ...products
  ])
  console.log('merged', mergedProducts);
  return newState.merge({
    invoiceProducts: mergedProducts || []
  })
}

const setInvoiceProductCost = (state, { productId, cost })  => {
  const newState = Immutable(state)
  const alterCost = curry((cost, id, products) => map(
    when(propEq('product_id', id), assoc('invoice_cost_ex_vat', cost)),
    products
  ))
  const products = alterCost(cost, productId, state.invoiceProducts)

  return newState.set('invoiceProducts', products)
}

const saveDeliveryInvoiceAttempt = state => {
  return state.merge({
    uiLoadingSave: true,
  })
}

const saveDeliveryInvoiceSuccess = (state, { data }) => {
  return state.merge({
    uiLoadingSave: false,
    invoice: omit(['invoice_products'], data),
    invoiceProducts: propOr([], 'invoice_products', data)
  })
}

const saveDeliveryInvoiceFailure = state => {
  return state.merge({
    uiLoadingSave: false,
  })
}

// DELETE Delivery
const deleteDeliveryAttempt = state => {
  return state.merge({
    uiLoadingDelete: true,
  })
}

const deleteDeliverySuccess = () => INITIAL_STATE

const deleteDeliveryFailure = (state, { error }) => {
  return state.merge({
    uiLoadingDelete: false,
    error: error,
  })
}

const resetDeliveryState = () => INITIAL_STATE

// map our types to our handlers
const ACTION_HANDLERS = {
  // GET Delivery
  [Types.GET_DELIVERY_ATTEMPT]: getDeliveryAttempt,
  [Types.GET_DELIVERY_SUCCESS]: getDeliverySuccess,
  [Types.GET_DELIVERY_FAILURE]: getDeliveryFailure,
  [Types.SET_DELIVERY_GRID_STATE]: setDeliveryGridState,
  // POST/PUT Delivery
  [Types.SAVE_DELIVERY_ATTEMPT]: saveDeliveryAttempt,
  [Types.SAVE_DELIVERY_SUCCESS]: saveDeliverySuccess,
  [Types.SAVE_DELIVERY_FAILURE]: saveDeliveryFailure,
  // PUT Complete Delivery
  [Types.COMPLETE_DELIVERY_ATTEMPT]: completeDeliveryAttempt,
  [Types.COMPLETE_DELIVERY_SUCCESS]: completeDeliverySuccess,
  [Types.COMPLETE_DELIVERY_FAILURE]: completeDeliveryFailure,
  // POST/PUT Delivery Invoice
  [Types.SET_INVOICE]: setInvoice,
  [Types.SET_INVOICE_PRODUCTS]: setInvoiceProducts,
  [Types.SET_INVOICE_PRODUCT_COST]: setInvoiceProductCost,
  [Types.SAVE_DELIVERY_INVOICE_ATTEMPT]: saveDeliveryInvoiceAttempt,
  [Types.SAVE_DELIVERY_INVOICE_SUCCESS]: saveDeliveryInvoiceSuccess,
  [Types.SAVE_DELIVERY_INVOICE_FAILURE]: saveDeliveryInvoiceFailure,
  // DELETE Delivery
  [Types.DELETE_DELIVERY_ATTEMPT]: deleteDeliveryAttempt,
  [Types.DELETE_DELIVERY_SUCCESS]: deleteDeliverySuccess,
  [Types.DELETE_DELIVERY_FAILURE]: deleteDeliveryFailure,
  // Reset reducer
  [Types.RESET_DELIVERY_STATE]: resetDeliveryState,
}

export default createReducer(INITIAL_STATE, ACTION_HANDLERS)
