import React, { Component } from 'react'
import PropTypes from 'prop-types'

// Material UI
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import IconLock from '@material-ui/icons/Lock'
import CircularProgress from '@material-ui/core/CircularProgress'

const styles = theme => ({
  form: {
    width: 300,
    textAlign: 'center',
  },
  icon: {
    width: 64,
    height: 64,
    opacity: 0.5,
    marginBottom: theme.spacing(3),
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing(3),
  },
})


class PasswordForgot extends Component {
  render() {
    const {
      classes,
      uiLoading,
      email,
      onChange,
      onSubmit,
      navigate,
    } = this.props
    
    return (
      <form className={classes.form} onSubmit={onSubmit}>
        <IconLock className={classes.icon} color="action" />
        <Typography variant="h5" gutterBottom>
          {'Forgot your password?'}
        </Typography>
        <TextField
          id="email"
          label="Email"
          name="email"
          type="email"
          value={email}
          required
          disabled={uiLoading}
          fullWidth
          margin="normal"
          onChange={onChange('email')}
        />
        <div className={classes.footer}>
          <Button
            variant="contained"
            onClick={navigate('/login')}
          >
            {'Cancel'}
          </Button>
          <Button
            variant="contained"
            type="submit"
            color="primary"
            disabled={uiLoading}
          >
            {uiLoading
              ? <CircularProgress color="inherit" size={20} />
              : 'Send'
            }
          </Button>
        </div>
      </form>
    )
  }
}

PasswordForgot.propTypes = {
  classes: PropTypes.object.isRequired,
  uiLoading: PropTypes.bool.isRequired,
  email: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  navigate: PropTypes.func.isRequired,
}

export default withStyles(styles)(PasswordForgot)
