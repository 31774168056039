import React from 'react'
import PropTypes from 'prop-types'

// Material UI
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'

const styles = theme => ({
  field: {
    margin: theme.spacing()
  }
})

const GridFilterDateField = ({ classes, filter, onFilter }) => (
  <TextField
    className={classes.field}
    fullWidth
    type="date"
    value={filter ? filter.value : ''}
    onChange={e => onFilter(e.target.value ? { value: e.target.value } : null)}
  />
)

GridFilterDateField.propTypes = {
  filter: PropTypes.shape({
    value: PropTypes.string.isRequired,
  }),
  onFilter: PropTypes.func.isRequired,
}

GridFilterDateField.defaultProps = {
  filter: null,
}

export default withStyles(styles)(GridFilterDateField)
