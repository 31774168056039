import React from 'react'
import PropTypes from 'prop-types'

import { map, sum, length } from 'ramda'

// Material UI
import { withStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import Button from '@material-ui/core/Button'

const tasksFinished = tasks => sum(map(task => +task, tasks))

const styles = theme => ({
  booting: {
    width: '90%',
    maxWidth: 300,
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  icon: {
    color: 'white',
    marginRight: theme.spacing(1),
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  failed: {
    display: 'flex',
    flexDirection: 'column',
  },
})

const Booting = ({
  classes,
  tasks,
  tasksFailed,
  onFailed,
}) => (
  <div className={classes.booting}>
    <div className={classes.loading}>
      {!tasksFailed && (
        <CircularProgress className={classes.icon} size={32} thickness={1} />
      )}
      <Typography variant="h5">
        {`${tasksFailed ? 'Failed' : 'Running tasks...'}`}
      </Typography>
    </div>
    <Divider className={classes.divider} />
    {
      tasksFailed
      ? (
        <div className={classes.failed}>
          <Typography align="center" variant="subtitle1">
            {`${tasksFailed} of ${length(tasks)} task${tasksFailed > 1 ? 's' : ''} failed`}
          </Typography>
          <Divider className={classes.divider} />
          <Button variant="contained" color="secondary" onClick={onFailed}>
            {'Try again'}
          </Button>
        </div>
      )
      : (
        <div>
          <Typography align="center" variant="subtitle1">
            {`${tasksFinished(tasks)} of ${length(tasks)} tasks finished`}
          </Typography>
          <Divider className={classes.divider} />
        </div>
      )
    }
  </div>
)

Booting.propTypes = {
  classes: PropTypes.object.isRequired,
  tasks: PropTypes.array.isRequired,
  tasksFailed: PropTypes.number.isRequired,
  onFailed: PropTypes.func.isRequired,
}

export default withStyles(styles)(Booting)
