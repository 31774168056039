import React from 'react'
import PropTypes from 'prop-types'

import { prop, equals } from 'ramda'
import {
  TRACKING_DPD_URL,
  TRACKING_PARCELFORCE_URL,
  TRACKING_PACKFLEET_URL
} from '../util/constants'

// React Grid
import {
  DataTypeProvider,
} from '@devexpress/dx-react-grid'

const GridCourierTrackingTypeProvider = ({ columns }) => (
  <DataTypeProvider
    formatterComponent={
      ({ row, value }) => {
        const courierId = prop('courier_id', row)
        let courierReference = null

        if (value && equals(courierId, 'dpd')) {
          courierReference = `<a href="${TRACKING_DPD_URL}${value}" target="_blank">${value}</a>`
        }

        if (value && equals(courierId, 'parcelforce')) {
          courierReference = `<a href="${TRACKING_PARCELFORCE_URL}${value}" target="_blank">${value}</a>`
        }

        if (value && equals(courierId, 'packfleet')) {
          courierReference = `<a href="${TRACKING_PACKFLEET_URL}${value}" target="_blank">${value}</a>`
        }

        if (courierReference) {
          return <div dangerouslySetInnerHTML={{ __html: courierReference }} />
        } else {
          return value || null
        }
      }
    }
    for={columns}
  />
)

GridCourierTrackingTypeProvider.propTypes = {
  columns: PropTypes.array.isRequired,
}

export default GridCourierTrackingTypeProvider
