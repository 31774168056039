import {
  pipe, converge, find, nthArg, propEq, join, unless, isNil,
  map, assoc, assocPath, propOr, prop, props, path, sum, subtract,
  defaultTo, reverse, head, pluck, evolve, concat, reject,
  all, contains, toLower, split, times, identity, length, or,
  reduce, add, tail, curry, values, groupBy, sortWith, ascend, compose
} from 'ramda'
import moment from 'moment'

export const nl2brArray = text => {
  if (typeof text !== 'string') return []
  return text.split(/(?:\r\n|\r|\n)/)
}

export const isGridColumnSortingApplied = (columnName, sorting) =>
  sorting.findIndex(sortingItem => sortingItem.columnName === columnName) > -1

export const formatDate = (value, format) => {
  const date = moment(value)
  return date.isValid() ? date.format(format || 'DD/MM/YYYY HH:mm') : value
}

export const findById = converge(
  find,
  [pipe(nthArg(0), propEq('id')), nthArg(1)]
)

export const searchInString = (string, { value }) => all(
  keyword => contains(toLower(keyword), toLower(string))
)(split(' ', value))

export const searchInArray = (string, { value }) => contains(string, value)

export const sortDecimal = (a, b) => (a - b)

export const naturalSort = (a, b) => a.localeCompare(b)

export const sumBy = props => vals => reduce(
  (current, val) => {
    let transformations = {}
    map(
      prop => transformations[prop] = add(val[prop]),
      props
    )

    return evolve(transformations, current)
  },
  head(vals),
  tail(vals)
)

export const groupSumBy = curry((groupOn, sumOn, vals) =>
  values(map(sumBy(sumOn))(groupBy(prop(groupOn), vals)))
)

export const urlify = text => {
  if (text) {
    const urlRegex = /(https?:\/\/[^\s]+)/g
    return text.replace(urlRegex, function(url) {
      return `<a href="${url}" target="_blank">${url}</a>`
    })
  }

  return text
}

// Orders
export const setOrder = (data, statuses, suppliers) => {
  const transformations = {
    order_date: defaultTo(''),
    updated_at: defaultTo(''),
    customer_email: defaultTo(''),
    customer_phone_number: defaultTo(''),
    gift_message: defaultTo(''),
    warehouse_instructions: defaultTo(''),
    delivery_date: defaultTo(''),
    customer_delivery_company: defaultTo(''),
    customer_delivery_address_line2: defaultTo(''),
    customer_delivery_phone_number: defaultTo(''),
    delivery_instructions: defaultTo(''),
  }

  return pipe(
    // Set order status title
    assoc('status', propOr('Unknown', 'title', findById(data.order_status_id, statuses))),
    // Set customer
    assoc('customer_billing_contact', pipe(
      // prop('customer'),
      props(['customer_billing_first_name', 'customer_billing_last_name']),
      join(' ')
    )(data)),
    assoc('customer_email', path(['customer', 'customer_email'], data)),
    assoc('customer_phone_number', path(['customer', 'customer_phone_number'], data)),
    // Set delivery contact
    assoc('customer_delivery_contact', pipe(
      props(['customer_delivery_first_name', 'customer_delivery_last_name']),
      join(' ')
    )(data)),
    // Set total items
    assoc('items_ordered', pipe(pluck('ordered'), sum)(data.order_items)),
    assoc('items_allocated', pipe(pluck('allocated'), sum)(data.order_items)),
    assoc('items_notified', pipe(pluck('notified'), sum)(data.order_items)),
    assoc('items_shipped', pipe(pluck('shipped'), sum)(data.order_items)),
    assoc('items_fulfilled', pipe(
      map(x => sum(props(['allocated', 'shipped'], x))),
      sum
    )(data.order_items)),
    assoc('items_can_be_fulfilled', pipe(
      map(x => {
        const inStock = prop('items_in_stock', x)
        const difference = subtract(
          prop('ordered', x),
          sum(props(['allocated', 'shipped'], x))
        )
        return Math.min(difference, inStock)
      }),
      sum
    )(data.order_items)),
    assoc('order_items', pipe(
      map(product => setProduct(product, suppliers)),
      sortWith([
        ascend(prop('supplier')),
        ascend(compose(toLower, prop('description')))
      ])
    )(data.order_items)),
    assoc('reserved_products', map(product => setProduct(product, suppliers), data.reserved_products || [])),
    evolve(transformations),
  )(data)
}

// Shipments
export const setShipmentItems = (data, courier) => map(
  item => ({
    ...item,
    tracking_meta: pipe(
      defaultTo([]),
      meta => contains(courier, ['apc', 'dhl', 'parcelforce', 'packfleet']) ? reverse(meta) : meta,
      map(meta => {
        let trackingMeta;

        switch (courier) {
          case 'apc':
            trackingMeta = {
              dateAdded: prop('DateTime', meta),
              location: prop('Location', meta),
              comments: prop('Comments', meta),
              status: prop('StatusDescription', meta),
              signedBy: prop('SignedBy', meta),
            }
            break
          case 'dhl':
            trackingMeta = {
              dateAdded: formatDate(prop('StatusTimeStamp', meta)),
              location: null,
              comments: null,
              status: prop('StatusDescription', meta),
              signedBy: null,
            }
            break
          case 'dpd':
            trackingMeta = {
              dateAdded: formatDate(prop('date', meta)),
              location: prop('locality', meta),
              comments: prop('description', meta),
              status: or(prop('type', meta), prop('description', meta)),
              signedBy: null,
            }
            break
          case 'parcelforce':
            trackingMeta = {
              dateAdded: formatDate(prop('event_date_time', meta)),
              location: prop('event_location', meta),
              comments: prop('description', meta),
              status: prop('status', meta),
              signedBy: null,
            }
            break
          case 'packfleet':
            trackingMeta = {
              dateAdded: formatDate(prop('timestamp', meta)),
              location: null,
              comments: propOr(null, 'notes', meta),
              status: prop('status', meta),
              signedBy: null,
            }
            break
          case 'ups':
            trackingMeta = {
              dateAdded: formatDate(`${prop('Date', meta)} ${prop('Time', meta)}`),
              location: path(['ActivityLocation', 'Address', 'City'], meta),
              comments: path(['ActivityLocation', 'Description'], meta),
              status: path(['Status', 'Description'], meta),
              signedBy: path(['ActivityLocation', 'SignedForByName'], meta),
            }
            break
          default:
            trackingMeta = []
            break
        }

        return trackingMeta
      }),
    )(item.tracking_meta)
  }),
  data
)

export const setShipment = (data, couriers) => {
  // parse json to object
  const shippingItems = setShipmentItems(
    prop('shipment_items', data),
    prop('courier', data),
  )

  // set tracking status
  const tracking = pipe(
    pluck('tracking_meta'),
    map(head),
    pluck('status'),
    reject(isNil),
  )(shippingItems)

  // set tracking comments
  const comments = pipe(
    pluck('tracking_meta'),
    map(head),
    pluck('comments'),
    reject(isNil),
  )(shippingItems)

  return pipe(
    assoc('courier', propOr('', 'name', findById(data.courier, couriers))),
    assoc('courier_id', propOr('', 'id', findById(data.courier, couriers))),
    assoc('shipment_items', shippingItems),
    assoc('tracking', tracking),
    assoc('comments', comments),
  )(data)
}

// Products
export const setProduct = (data, suppliers) => {
  const transformations = {
    created_at: defaultTo(''),
    updated_at: defaultTo(''),
    location: defaultTo(''),
  }

  return pipe(
    assoc('supplier', propOr('', 'name', findById(data.supplier_id, suppliers))),
    evolve(transformations),
  )(data)
}

export const setProductStockAdjustment = (data, suppliers, users) => {
  const transformations = {
    created_at: defaultTo(''),
    updated_at: defaultTo(''),
  }

  const assocDelivery = delivery => unless(
    x => isNil(prop('delivery', x)),
    assocPath(
      ['delivery', 'supplier'],
      propOr('', 'name', findById(path(['delivery', 'supplier_id'], delivery), suppliers))
    )
  )(delivery)

  const assocReceivedDelivery = delivery => unless(
    x => isNil(prop('received_delivery', x)),
    assocPath(
      ['received_delivery', 'supplier'],
      propOr('', 'name', findById(path(['received_delivery', 'supplier_id'], delivery), suppliers))
    ),

  )(delivery)

  return pipe(
    assocDelivery,
    assocReceivedDelivery,
    assoc('created_by', propOr('', 'name', findById(data.created_by_id, users))),
    evolve(transformations),
  )(data)
}

// Suppliers
export const setSupplier = data => {
  const transformations = {
    created_at: defaultTo(''),
    updated_at: defaultTo(''),
    email: defaultTo(''),
    comment: defaultTo(''),
  }

  return evolve(transformations, data)
}

// Delivery
export const setDelivery = (data, suppliers, users) => pipe(
  assoc('supplier', propOr('', 'name', findById(data.supplier_id, suppliers))),
  assoc('created_by', propOr('', 'name', findById(data.created_by_id, users))),
  assoc('updated_by', propOr('', 'name', findById(data.updated_by_id, users))),
)(data)

export const setDeliveryProduct = (data, deliveryId) => {
  const orders = prop('orders', data) || []
  const otherOrders = prop('other_orders', data) || []
  // const supplierCaseSize = prop('supplier_case_size', data) || 1
  const required = prop('items_required', data) || 1
  let restock = prop('total_notified', data)

  if (!deliveryId || !restock) {
    // restock = supplierCaseSize * Math.ceil(required / supplierCaseSize)
    restock = required
  }

  return pipe(
    assoc('restock', restock),
    assoc('orders', concat(orders, otherOrders)),
    assoc('ordersSelection', times(identity, length(orders))),
  )(data)
}

// Restock
export const setRestock = (data, suppliers) => pipe(
  assoc('supplier', propOr('', 'name', findById(data.supplier_id, suppliers))),
  assoc('comment', propOr('', 'comment', findById(data.supplier_id, suppliers))),
)(data)
