import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import { push, replace } from 'connected-react-router'
import { Creators as Actions } from '../actions'

// Material UI
import { withStyles } from '@material-ui/core/styles'

// Components
import PasswordForgot from '../components/PasswordForgot'

const styles = theme => ({
  forgot: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
})


class ForgotPassword extends Component {
  state = {
    email: ''
  }
  
  UNSAFE_componentWillReceiveProps(nextProps) {
    // Password requested?
    if (
      this.props.uiLoading !== nextProps.uiLoading
      && !nextProps.uiLoading
      && !nextProps.error
    ) {
      this.props.replace('/login')
    }
  }
  
  navigate = to => () => {
    this.props.push(to)
  }
  
  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    })
  }
  
  handleSubmit = event => {
    event.preventDefault()
    const { forgotPasswordAttempt } = this.props
    const { email } = this.state
    
    // TODO redux forms ???
    if (event.target.checkValidity()) {
      forgotPasswordAttempt({ email })
    }
  }
  
  render() {
    const {
      classes,
      uiLoading,
    } = this.props
    const { email } = this.state;
    
    return (
      <div className={classes.forgot}>
        <PasswordForgot
          uiLoading={uiLoading}
          email={email}
          onChange={this.handleChange}
          onSubmit={this.handleSubmit}
          navigate={this.navigate}
        />
      </div>
    )
  }
}

ForgotPassword.propTypes = {
  classes: PropTypes.object.isRequired,
  uiLoading: PropTypes.bool.isRequired,
  push: PropTypes.func.isRequired,
  replace: PropTypes.func.isRequired,
  forgotPasswordAttempt: PropTypes.func.isRequired,
}

const {
  forgotPasswordAttempt,
} = Actions

const mapStateToProps = ({
  auth: { uiLoading, error },
}) => ({
  uiLoading,
  error,
})

const mapDispatchToProps = dispatch => bindActionCreators({
  push,
  replace,
  forgotPasswordAttempt,
}, dispatch)

export default compose(
  withStyles(styles), connect(mapStateToProps, mapDispatchToProps)
)(ForgotPassword)
