import React, { Component } from 'react'
import PropTypes from 'prop-types'

import {
  prop, path, equals, or, length, contains,
} from 'ramda'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import { Creators as Actions } from '../actions'
import {
  isGridColumnSortingApplied,
  searchInString,
} from '../util/helpers'
import { suppliersSelector } from '../util/selectors'

// Material UI
import { withStyles } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import IconButton from '@material-ui/core/IconButton'
import IconEdit from '@material-ui/icons/Edit'
import IconLocalOffer from '@material-ui/icons/LocalOffer'

// React Grid
import {
  SortingState, FilteringState, PagingState,
  IntegratedSorting, IntegratedFiltering, IntegratedPaging,
} from '@devexpress/dx-react-grid'
import {
  Grid as ReactGrid,
  Table, TableHeaderRow, TableFilterRow,
  PagingPanel,
} from '@devexpress/dx-react-grid-material-ui'

// Components
import LinkButton from '../components/LinkButton'
import Header from '../components/Header'
import RefreshButton from '../components/RefreshButton'
import Loading from '../components/Loading'
import GridFilterDateField from '../components/GridFilterDateField'
import GridDateTypeProvider from '../components/GridDateTypeProvider'
import DialogSupplierUpdate from '../components/DialogSupplierUpdate'

const styles = theme => ({

})


class Suppliers extends Component {
  state = {
    editing: false,
    supplier: {},
  }
  
  componentDidMount() {
    // NOTE Suppliers loaded on startup login
    // no need to load if page refreshed on this container
    const from = path(['location', 'state', 'from'], this.props)
    if (!equals(from, '/login')) {
      this.handleMount()
    }
  }
  
  UNSAFE_componentWillReceiveProps(nextProps) {
    // Updated?
    if (
      this.props.uiLoadingUpdate !== nextProps.uiLoadingUpdate
      && !nextProps.uiLoadingUpdate
      && !nextProps.error
    ) {
      this.toggleEditingClose()
    }
  }
  
  shouldComponentUpdate(nextProps, nextState) {
    return or(
      !equals(this.props, nextProps),
      !equals(this.state, nextState)
    )
  }
  
  handleMount = () => {
    this.props.getSuppliersAttempt()
  }
  
  toggleEditingOpen = supplier => {
    this.setState({
      editing: true,
      supplier: supplier,
    })
  }
  
  toggleEditingClose = () => {
    this.setState({
      editing: false,
      supplier: {},
    })
  }
  
  handleUpdateSubmit = supplier => {
    const { updateSupplierAttempt } = this.props
    const { supplierId, name, email, comment } = supplier
    
    // TODO redux forms ???
    updateSupplierAttempt(supplierId, { name, email, comment })
  }
  
  handleGridSorting = sort => {
    const { sorting, onSortingChange } = this.props
    const { columnName } = sort[0]
    
    // Descending as default direction
    if (
      contains(columnName, [
        'total_products', 'updated_at'
      ])
      && !isGridColumnSortingApplied(columnName, sorting)
    ) {
      sort[0].direction = 'desc';
    }
    
    onSortingChange(sort)
  }
  
  // Grid
  headerCellComponent = cellProps => {
    const columnName = path(['column', 'name'], cellProps)
    
    // No label and sorting
    if (equals(columnName, 'action')) {
      return <Table.Cell />
    }
    
    return <TableHeaderRow.Cell {...cellProps} />
  }
  
  filterCellComponent = cellProps => {
    const columnName = path(['column', 'name'], cellProps)
    
    // Date filter
    if (equals(columnName, 'updated_at')) {
      return (
        <TableFilterRow.Cell {...cellProps}>
          <GridFilterDateField
            filter={cellProps.filter}
            onFilter={cellProps.onFilter}
          />
        </TableFilterRow.Cell>
      )
    }
    
    // No filter
    if (equals(columnName, 'action')) {
      return <Table.Cell />
    }
    
    return <TableFilterRow.Cell {...cellProps} />
  }
  
  rowComponent = rowProps => {
    return (
      <Table.Row hover {...rowProps} />
    )
  }
  
  cellComponent = ({ style, ...cellProps }) => {
    const columnName = path(['column', 'name'], cellProps)
    let cellStyle = style
    
    // Wrap cell
    if (equals(columnName, 'comment')) {
      cellStyle = {
        ...cellStyle,
        whiteSpace: 'pre-line',
      }
    }
    
    // Actions
    if (equals(columnName, 'action')) {
      const { location: routerLocation } = this.props
      const pathname = prop('pathname', routerLocation)
      const supplier = prop('row', cellProps)
      const supplierId = path(['row', 'id'], cellProps)
      
      return (
        <Table.Cell style={cellStyle} {...cellProps}>
          {
            supplierId
            ? (
              <Tooltip
                id={`tooltip-${supplierId}`}
                title="Update supplier"
                placement="left"
              >
                <IconButton onClick={() => this.toggleEditingOpen(supplier)}>
                  <IconEdit color="action" />
                </IconButton>
              </Tooltip>
            )
            : null
          }
          <LinkButton
            title="Order more stock"
            to={{
              pathname: `/delivery/${supplierId}/0`,
              state: { from: pathname }
            }}
          >
            <IconLocalOffer color="action" />
          </LinkButton>
        </Table.Cell>
      )
    }
    
    return <Table.Cell style={cellStyle} {...cellProps} />
  }
  
  cellComponentNoData = () => {
    return (
      <Table.Cell colSpan={length(this.props.columns)}>
        {<Typography variant="body1" align="center">{'No suppliers.'}</Typography>}
      </Table.Cell>
    )
  }
  
  render() {
    const {
      // classes,
      // Grid
      uiLoadingGet,
      uiLoadingUpdate,
      data,
      columns,
      columnExtensions,
      sorting,
      filters,
      onFiltersChange,
      currentPage,
      onCurrentPageChange,
      pageSizes,
      pageSize,
      onPageSizeChange,
    } = this.props
    const {
      editing,
      supplier,
    } = this.state
    
    return (
      <div>
        <Header
          title={'Suppliers'}
          subtitle={`Browsing page ${currentPage + 1}`}
        >
          <RefreshButton
            onClick={this.handleMount}
          />
        </Header>
        <Paper>
          <ReactGrid
            rows={data}
            columns={columns.asMutable()}
          >
            <GridDateTypeProvider
              columns={['updated_at']}
            />
            <FilteringState
              filters={filters.asMutable()}
              onFiltersChange={onFiltersChange}
            />
            <SortingState
              sorting={sorting.asMutable()}
              onSortingChange={this.handleGridSorting}
            />
            <PagingState
              currentPage={currentPage}
              onCurrentPageChange={onCurrentPageChange}
              pageSize={pageSize}
              onPageSizeChange={onPageSizeChange}
            />
            <IntegratedFiltering
              columnExtensions={[
                {
                  columnName: 'name',
                  predicate: searchInString
                },
              ]}
            />
            <IntegratedSorting />
            <IntegratedPaging />
            <Table
              columnExtensions={columnExtensions.asMutable()}
              rowComponent={this.rowComponent}
              cellComponent={this.cellComponent}
              noDataCellComponent={this.cellComponentNoData}
            />
            <TableHeaderRow
              showSortingControls
              cellComponent={this.headerCellComponent}
            />
            <TableFilterRow
              cellComponent={this.filterCellComponent}
            />
            <PagingPanel
              pageSizes={pageSizes}
            />
          </ReactGrid>
        </Paper>
        <DialogSupplierUpdate
          open={editing}
          onClose={this.toggleEditingClose}
          disableBackdropClick={uiLoadingUpdate}
          disableEscapeKeyDown={uiLoadingUpdate}
          supplier={supplier}
          onSubmit={this.handleUpdateSubmit}
          submitting={uiLoadingUpdate}
        />
        {uiLoadingGet && <Loading message="Loading suppliers..." />}
      </div>
    )
  }
}

Suppliers.propTypes = {
  classes: PropTypes.object.isRequired,
  // Grid
  getSuppliersAttempt: PropTypes.func.isRequired,
  updateSupplierAttempt: PropTypes.func.isRequired,
  uiLoadingGet: PropTypes.bool.isRequired,
  uiLoadingUpdate: PropTypes.bool.isRequired,
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  columnExtensions: PropTypes.array.isRequired,
  sorting: PropTypes.array.isRequired,
  onSortingChange: PropTypes.func.isRequired,
  filters: PropTypes.array.isRequired,
  onFiltersChange: PropTypes.func.isRequired,
  currentPage: PropTypes.number.isRequired,
  onCurrentPageChange: PropTypes.func.isRequired,
  pageSize: PropTypes.number.isRequired,
  onPageSizeChange: PropTypes.func.isRequired,
  pageSizes: PropTypes.arrayOf(PropTypes.number).isRequired,
}

const {
  getSuppliersAttempt,
  updateSupplierAttempt,
  setSuppliersGridState,
} = Actions

const mapStateToProps = state => ({
  ...state.suppliers,
  data: suppliersSelector(state),
})

const mapDispatchToProps = dispatch => bindActionCreators({
  getSuppliersAttempt,
  updateSupplierAttempt,
  onSortingChange: sorting => setSuppliersGridState('sorting', sorting),
  onFiltersChange: filters => setSuppliersGridState('filters', filters),
  onCurrentPageChange: currentPage => setSuppliersGridState('currentPage', currentPage),
  onPageSizeChange: pageSize => setSuppliersGridState('pageSize', pageSize),
}, dispatch)

export default compose(
  withStyles(styles), connect(mapStateToProps, mapDispatchToProps)
)(Suppliers)
