import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

// Material UI
import { createMuiTheme, MuiThemeProvider, withStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'

const defaultTheme = createMuiTheme({
  typography: {
    fontFamily: '"Arial", sans-serif',
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 700,
    useNextVariants: true,
  },
})
const theme = outerTheme => defaultTheme

const styles = theme => ({
  paper: {
    pageBreakAfter: 'always',
    minHeight: '100%',
    // NOTE hide component until opened in new tab for print
    // TODO can this be done better ???
    display: 'none',
    'body > &': {
      display: 'block',
    },
  },
})


class PrintWrapper extends PureComponent {
  render() {
    const { classes, children } = this.props
    return (
      <MuiThemeProvider theme={theme}>
        <Paper className={classes.paper} elevation={0} square>
          {children}
        </Paper>
      </MuiThemeProvider>
    )
  }
}

PrintWrapper.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
}

export default withStyles(styles)(PrintWrapper)
