import React from 'react'
import PropTypes from 'prop-types'

// Material UI
import Grid from '@material-ui/core/Grid'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'

// Components
import CourierFormParcels from './CourierFormParcels'

const CourierFormOffline = ({
  classes,
  uiLoading,
  parcels,
  onParcelChange,
  onAddParcel,
  onRemoveParcel,
  onCancel,
  onSubmit,
}) => [
  <DialogContent key="offlineForm">
    <Grid container spacing={3}>
      <Grid item xs={6}>
        <CourierFormParcels
          uiLoading={uiLoading}
          parcels={parcels}
          onParcelChange={onParcelChange}
          onAddParcel={onAddParcel}
          onRemoveParcel={onRemoveParcel}
        />
      </Grid>
    </Grid>
  </DialogContent>,
  <DialogActions key="offlineActions">
    <Button
      onClick={onCancel}
      disabled={uiLoading}
    >
      {'Cancel'}
    </Button>
    <Button
      variant="contained"
      color="primary"
      onClick={onSubmit}
      disabled={uiLoading}
    >
      {uiLoading
        ? <CircularProgress color="inherit" size={20} />
        : 'Submit'
      }
    </Button>
  </DialogActions>
]

CourierFormOffline.propTypes = {
  uiLoading: PropTypes.bool.isRequired,
  parcels: PropTypes.array.isRequired,
  onParcelChange: PropTypes.func.isRequired,
  onAddParcel: PropTypes.func.isRequired,
  onRemoveParcel: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

export default CourierFormOffline
