import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import {
  equals, pathOr,
} from 'ramda'

// Material UI
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import CircularProgress from '@material-ui/core/CircularProgress'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'


class DialogSupplierUpdate extends PureComponent {
  state = {
    supplierId: 0,
    name: '',
    email: '',
    comment: '',
  }
  
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!equals(this.props.supplier, nextProps.supplier)) {
      this.setState({
        supplierId: pathOr(0, ['supplier', 'id'], nextProps),
        name: pathOr('', ['supplier', 'name'], nextProps),
        email: pathOr('', ['supplier', 'email'], nextProps),
        comment: pathOr('', ['supplier', 'comment'], nextProps),
      })
    }
  }
  
  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    })
  }
  
  handleSubmit = () => {
    const { onSubmit } = this.props
    const { supplierId, name, email, comment } = this.state
    
    onSubmit({ supplierId, name, email, comment })
  }
  
  render() {
    const {
      open,
      onClose,
      disableBackdropClick,
      disableEscapeKeyDown,
      submitting,
    } = this.props
    const {
      name,
      email,
      comment,
    } = this.state
    
    return (
      <Dialog
        open={open}
        onClose={onClose}
        disableBackdropClick={disableBackdropClick}
        disableEscapeKeyDown={disableEscapeKeyDown}
      >
        <DialogTitle>Update Supplier</DialogTitle>
        <DialogContent>
          <TextField
            id="name"
            label="Name"
            fullWidth
            value={name}
            readOnly
            margin="normal"
            disabled={submitting}
          />
          <TextField
            id="email"
            label="Email"
            fullWidth
            value={email}
            onChange={this.handleChange('email')}
            margin="normal"
            disabled={submitting}
          />
          <TextField
            id="comment"
            label="Comment"
            multiline
            rows="5"
            fullWidth
            value={comment}
            margin="normal"
            onChange={this.handleChange('comment')}
            disabled={submitting}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={onClose}
            disabled={submitting}
          >
            {'Cancel'}
          </Button>
          <Button
            variant="contained"
            style={{ marginLeft: '10px'}}
            color="primary"
            onClick={this.handleSubmit}
            disabled={submitting}
          >
            {submitting
              ? <CircularProgress color="inherit" size={20} />
              : 'Submit'
            }
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

DialogSupplierUpdate.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  disableBackdropClick: PropTypes.bool.isRequired,
  disableEscapeKeyDown: PropTypes.bool.isRequired,
  supplier: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
}

export default DialogSupplierUpdate
