import { path, prop } from 'ramda'
import {
  take, put, call,
  fork, cancel, cancelled,
  // delay
} from 'redux-saga/effects'
import { Types, Creators as Actions } from '../actions'


export default api => {
  // GET Shipment
  function* getShipment(shipmentId) {
    try {
      // yield call(delay, 3000)
      const shipmentResp = yield call(api.getShipment, shipmentId)
      
      // Did shipment load?
      if (prop('ok', shipmentResp)) {
        const data = path(['data', 'data'], shipmentResp) || []
        const success = path(['data', 'message'], shipmentResp) || '[200] Shipment loaded.'
        yield put(Actions.getShipmentSuccess(data, success))
      } else {
        const error = path(['data', 'message'], shipmentResp) || '[500] Failed to load shipment.'
        const problem = prop('problem', shipmentResp)
        const status = prop('status', shipmentResp)
        yield put(Actions.getShipmentFailure(error, problem, status))
      }
    } catch (error) {
      yield put(Actions.getShipmentFailure(error))
    } finally {
      if (yield cancelled()) {
        // TODO task cancelled
        // NOTE Nothing to do here, shipment state cleared on LOCATION_CHANGE
        console.log('GET Shipment task cancelled.')
      }
    }
  }
  
  function* getFlow() {
    let lastTask
    while (true) {
      const { shipmentId } = yield take(Types.GET_SHIPMENT_ATTEMPT)
      
      if (lastTask) {
        yield cancel(lastTask)
      }
      
      lastTask = yield fork(getShipment, shipmentId)
    }
  }
  
  // DELIVER Shipment
  function* deliverShipment(shipmentId) {
    try {
      // yield call(delay, 3000)
      const shipmentResp = yield call(api.deliverShipment, shipmentId)
      
      // Did shipment deliver?
      if (prop('ok', shipmentResp)) {
        const data = path(['data', 'data'], shipmentResp) || []
        const success = path(['data', 'message'], shipmentResp) || '[200] Shipment marked as delivered.'
        yield put(Actions.getShipmentSuccess(data, success))
        yield put(Actions.deliverShipmentSuccess())
      } else {
        const error = path(['data', 'message'], shipmentResp) || '[500] Failed to deliver shipment.'
        const problem = prop('problem', shipmentResp)
        const status = prop('status', shipmentResp)
        yield put(Actions.deliverShipmentFailure(error, problem, status))
      }
    } catch (error) {
      yield put(Actions.deliverShipmentFailure(error))
    } finally {
      if (yield cancelled()) {
        // TODO task cancelled
        // NOTE Nothing to do here, shipment state cleared on LOCATION_CHANGE
        console.log('DELIVER Shipment task cancelled.')
      }
    }
  }
  
  function* deliverFlow() {
    let lastTask
    while (true) {
      const { shipmentId } = yield take(Types.DELIVER_SHIPMENT_ATTEMPT)
      
      if (lastTask) {
        yield cancel(lastTask)
      }
      
      lastTask = yield fork(deliverShipment, shipmentId)
    }
  }
  
  // DELETE Shipment
  function* deleteShipment(courier, shipmentId, data) {
    try {
      // yield call(delay, 3000)
      const shipmentResp = yield call(api.deleteShipment, courier, shipmentId, data)
      
      // Did shipment delete?
      if (prop('ok', shipmentResp)) {
        const success = path(['data', 'message'], shipmentResp) || '[200] Shipment deleted.'
        yield put(Actions.deleteShipmentSuccess(success))
      } else {
        const error = path(['data', 'message'], shipmentResp) || '[500] Failed to delete shipment.'
        const problem = prop('problem', shipmentResp)
        const status = prop('status', shipmentResp)
        yield put(Actions.deleteShipmentFailure(error, problem, status))
      }
    } catch (error) {
      yield put(Actions.deleteShipmentFailure(error))
    } finally {
      if (yield cancelled()) {
        // TODO task cancelled
        // NOTE Nothing to do here, shipment state cleared on LOCATION_CHANGE
        console.log('DELETE Shipment task cancelled.')
      }
    }
  }
  
  function* deleteFlow() {
    let lastTask
    while (true) {
      const { courier, shipmentId, data } = yield take(Types.DELETE_SHIPMENT_ATTEMPT)
      
      if (lastTask) {
        yield cancel(lastTask)
      }
      
      lastTask = yield fork(deleteShipment, courier, shipmentId, data)
    }
  }
  
  return {
    getFlow,
    deliverFlow,
    deleteFlow,
  }
}
