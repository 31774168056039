import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { and, path, equals, startsWith, length, or } from 'ramda'
import { bindActionCreators, compose } from 'redux'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { replace, push } from 'connected-react-router'
import { Route, Switch } from 'react-router-dom'
import { Link } from 'react-router-dom'

// Material UI
import { withStyles } from '@material-ui/core/styles'
import { yellow } from '@material-ui/core/colors'
import AppBar from '@material-ui/core/AppBar'
import Drawer from '@material-ui/core/Drawer'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Hidden from '@material-ui/core/Hidden'
import ButtonBase from '@material-ui/core/ButtonBase'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import MenuList from '@material-ui/core/MenuList'
import MenuItem from '@material-ui/core/MenuItem'
import List from '@material-ui/core/List'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Collapse from '@material-ui/core/Collapse'
import IconMenu from '@material-ui/icons/Menu'
import IconExpandLess from '@material-ui/icons/ExpandLess'
import IconExpandMore from '@material-ui/icons/ExpandMore'
import IconDashboard from '@material-ui/icons/Assessment'
import IconAssignmentTurnedIn from '@material-ui/icons/AssignmentTurnedIn'
import IconShoppingCart from '@material-ui/icons/ShoppingCart'
import IconLocalShipping from '@material-ui/icons/LocalShipping'
import IconAssignment from '@material-ui/icons/Assignment'
import IconLocalOffer from '@material-ui/icons/LocalOffer'
import IconStore from '@material-ui/icons/Store'
import IconFlightLand from '@material-ui/icons/FlightLand'
import IconTimer from '@material-ui/icons/Timer'
import IconSettingsOutlined from '@material-ui/icons/SettingsOutlined'
import IconEject from '@material-ui/icons/Eject'
import Snackbar from '@material-ui/core/Snackbar'
import IconClose from '@material-ui/icons/Close'
import IconError from '@material-ui/icons/Error'

// Dispatch
import { Creators as Actions } from '../actions'

// Components
import MaterialThemeWrapper from '../components/MaterialThemeWrapper'
import PrivateRoute from '../components/PrivateRoute'
import WidgetJobs from '../components/WidgetJobs'

// Containers
import Auth from './Auth'
import ForgotPassword from './ForgotPassword'
import ResetPassword from './ResetPassword'
import Dashboard from './Dashboard'
import Orders from './Orders'
import Order from './Order'
import Shipments from './Shipments'
import Shipment from './Shipment'
import Products from './Products'
import Product from './Product'
import Suppliers from './Suppliers'
import Deliveries from './Deliveries'
import Delivery from './Delivery'
import Restock from './Restock'
import Jobs from './Jobs'
import UnderDevelopment from './UnderDevelopment'
import NotFound from './NotFound'

const ConnectedSwitch = connect(state => ({
  location: state.location
}))(Switch)

const devMode = process.env.NODE_ENV !== 'production'

const styles = theme => ({
  devMode: {
    height: 0,
    borderBottom: `7px solid ${yellow['A400']}`,
  },
  root: {
    width: '100%',
    height: '100vh',
    // NOTE temporary hide whole app in print mode
    '@media print': {
      display: 'none !important',
      visibility: 'hidden',
      height: 0,
    },
  },
  flex: {
    flex: 1,
  },
  frame: {
    display: 'flex',
    width: '100%',
    height: '100%',
  },
  iconMenu: {
    marginLeft: -12,
    marginRight: 10,
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  iconEject: {
    marginLeft: theme.spacing(1),
  },
  drawerHeader: theme.mixins.toolbar,
  drawerPaperPerm: {
    position: 'fixed',
    width: theme.drawerWidth,
    border: 'none',
  },
  drawerPaperTemp: {
    width: theme.drawerWidth,
    height: '100%',
    border: 'none',
    [theme.breakpoints.up('md')]: {
      position: 'relative',
    },
  },
  nestedMenu: {
    paddingLeft: theme.spacing(4),
  },
  container: {
    width: '100%',
    height: '100%',
    marginLeft: 0,
  },
  containerBooted: {
    height: 'calc(100% - 56px)',
    marginTop: 56,
    marginLeft: 0,
    [theme.breakpoints.up('sm')]: {
      height: 'calc(100% - 64px)',
      marginTop: 64,
    },
    [theme.breakpoints.up('md')]: {
      marginLeft: theme.drawerWidth,
    },
  },
  content: {
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100%',
    padding: theme.spacing(3),
  },
  snackbar: {
    flexWrap: 'nowrap',
  },
  notification: {
    display: 'flex',
    alignItems: 'center',
  },
  iconError: {
    marginRight: theme.spacing(1),
  },
  alert: {
    backgroundColor: theme.palette.warning.main,
    borderRadius: theme.spacing(1),
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  }
})


class Index extends Component {
  state = {
    drawerOpen: false,
    menuSalesOpen: true,
    menuInventoryOpen: true,
    notify: false,
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // App is ready
    if (this.props.isBooted !== nextProps.isBooted && nextProps.isBooted) {
      const pathname = path(['location', 'pathname'], nextProps)
      const redirectTo = path(['location', 'state', 'from'], nextProps) || '/'

      this.props.replace({
        pathname: redirectTo,
        state: { from: pathname },
      })
    }

    // Location change
    if (equals(this.props.location, nextProps.location)) {
      this.state.drawerOpen && this.toggleDrawer(false)
    }

    // Notify with Snackbar
    if (this.props.notificationMessage !== nextProps.notificationMessage && nextProps.notificationMessage) {
      this.setState({
        notify: true,
      })
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return or(
      !equals(this.props, nextProps),
      !equals(this.state, nextState)
    )
  }

  toggleDrawer = open => {
    this.setState({
      drawerOpen: open,
    })
  }

  toggleMenuSales = () => {
    this.setState({
      menuSalesOpen: !this.state.menuSalesOpen,
    })
  }

  toggleMenuInventory = () => {
    this.setState({
      menuInventoryOpen: !this.state.menuInventoryOpen,
    })
  }

  navigate = (event, route) => {
    event.preventDefault()
    const { location } = this.props
    const pathname = path(['pathname'], location)
    if (!equals(pathname, route)) {
      this.props.push(route)
    }
  }

  closeNotification = () => {
    this.setState({
      notify: false,
    })
  }

  render() {
    const {
      classes,
      appName,
      isAuthenticated,
      isBooted,
      user,
      location,
      logout,
      notificationMessage,
      notificationLevel,
      clearNotification,
      datedDeliveryNextDay,
      jobs,
      failedJobs,
      lastJobProcessedDate,
    } = this.props
    const pathname = path(['pathname'], location)
    const userName = path(['name'], user)

    const drawer = (
      <div>
        <div className={classes.drawerHeader} />
        <MenuList component="div">
          <MenuItem
            component="a"
            href="/"
            onClick={e => this.navigate(e, '/')}
            // disabled={equals(pathname, '/')}
            selected={equals(pathname, '/')}
          >
            <ListItemIcon><IconDashboard /></ListItemIcon>
            <ListItemText primary="Dashboard" />
          </MenuItem>
          <MenuItem component="div" onClick={this.toggleMenuSales}>
            <ListItemIcon><IconAssignmentTurnedIn /></ListItemIcon>
            <ListItemText primary="Sales" />
            {this.state.menuSalesOpen ? <IconExpandLess /> : <IconExpandMore />}
          </MenuItem>
          <Collapse in={this.state.menuSalesOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <MenuItem
                className={classes.nestedMenu}
                component="a"
                href="/orders"
                onClick={e => this.navigate(e, '/orders')}
                // disabled={equals(pathname, '/orders')}
                selected={startsWith('/orders', pathname)}
              >
                <ListItemIcon><IconShoppingCart /></ListItemIcon>
                <ListItemText primary="Orders" />
              </MenuItem>
              <MenuItem
                className={classes.nestedMenu}
                component="a"
                href="/shipments"
                onClick={e => this.navigate(e, '/shipments')}
                // disabled={equals(pathname, '/shipments')}
                selected={startsWith('/shipments', pathname)}
              >
                <ListItemIcon><IconLocalShipping /></ListItemIcon>
                <ListItemText primary="Shipments" />
              </MenuItem>
            </List>
          </Collapse>
          <MenuItem component="div" onClick={this.toggleMenuInventory}>
            <ListItemIcon><IconAssignment /></ListItemIcon>
            <ListItemText primary="Inventory" />
            {this.state.menuInventoryOpen ? <IconExpandLess /> : <IconExpandMore />}
          </MenuItem>
          <Collapse in={this.state.menuInventoryOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <MenuItem
                className={classes.nestedMenu}
                component="a"
                href="/products"
                onClick={e => this.navigate(e, '/products')}
                // disabled={equals(pathname, '/products')}
                selected={startsWith('/products', pathname)}
              >
                <ListItemIcon><IconLocalOffer /></ListItemIcon>
                <ListItemText primary="Products" />
              </MenuItem>
              <MenuItem
                className={classes.nestedMenu}
                component="a"
                href="/suppliers"
                onClick={e => this.navigate(e, '/suppliers')}
                // disabled={equals(pathname, '/suppliers')}
                selected={equals(pathname, '/suppliers')}
              >
                <ListItemIcon><IconStore /></ListItemIcon>
                <ListItemText primary="Suppliers" />
              </MenuItem>
              <MenuItem
                className={classes.nestedMenu}
                component="a"
                href="/deliveries"
                onClick={e => this.navigate(e, '/deliveries')}
                // disabled={equals(pathname, '/deliveries')}
                selected={startsWith('/deliver', pathname)}
              >
                <ListItemIcon><IconFlightLand /></ListItemIcon>
                <ListItemText primary="Deliveries" />
              </MenuItem>
              <MenuItem
                className={classes.nestedMenu}
                component="a"
                href="/restock"
                onClick={e => this.navigate(e, '/restock')}
                // disabled={equals(pathname, '/restock')}
                selected={equals(pathname, '/restock')}
              >
                <ListItemIcon><IconTimer /></ListItemIcon>
                <ListItemText primary="Restock" />
              </MenuItem>
            </List>
          </Collapse>
          <MenuItem
            component="a"
            href="/settings"
            onClick={e => this.navigate(e, '/settings')}
            // disabled={equals(pathname, '/settings')}
            selected={equals(pathname, '/settings')}
          >
            {'Settings'}
          </MenuItem>
          <br />
          <MenuItem
            component="a"
            href="/jobs"
            onClick={e => this.navigate(e, '/jobs')}
            // disabled={equals(pathname, '/jobs')}
            selected={equals(pathname, '/jobs')}
          >
            <WidgetJobs
              jobs={jobs}
              failedJobs={failedJobs}
              lastJobProcessedDate={lastJobProcessedDate}
            />
          </MenuItem>
        </MenuList>
      </div>
    )

    return (
      <div className={classes.root}>
        <div className={classes.frame}>
          {isBooted && (
            <AppBar>
              <div className={devMode ? classes.devMode : null} />
              <Toolbar>
                {isBooted && (
                  <IconButton
                    className={classes.iconMenu}
                    onClick={() => this.toggleDrawer(!this.state.drawerOpen)}
                    color="inherit"
                  >
                    <IconMenu />
                  </IconButton>
                )}
                <div className={classes.flex}>
                  {appName && (
                    <div>
                      <Typography variant="h6" color="inherit" noWrap>
                        {appName}
                      </Typography>
                      <Typography variant="caption" color="inherit" noWrap>
                        v1.0.0-beta
                      </Typography>
                    </div>
                  )}
                </div>
                {isBooted && userName && (
                  <Tooltip id="tooltip-logout" title="Click to logout" placement="bottom">
                    <ButtonBase
                      onClick={logout}
                    >
                      <Typography variant="body1" color="inherit">
                        {`Hello ${userName}`}
                      </Typography>
                      <IconEject className={classes.iconEject} />
                    </ButtonBase>
                  </Tooltip>
                )}
              </Toolbar>
            </AppBar>
          )}
          {isBooted && (
            <Hidden mdUp>
              <Drawer
                variant="temporary"
                classes={{
                  paper: classes.drawerPaperTemp,
                }}
                open={this.state.drawerOpen}
                onClose={() => this.toggleDrawer(false)}
                ModalProps={{
                  keepMounted: true, // Better open performance on mobile.
                }}
              >
                {drawer}
              </Drawer>
            </Hidden>
          )}
          {isBooted && (
            <Hidden smDown implementation="css">
              <Drawer
                variant="permanent"
                open
                classes={{
                  paper: classes.drawerPaperPerm,
                }}
              >
                {drawer}
              </Drawer>
            </Hidden>
          )}
          <main className={`${classes.container} ${isBooted ? classes.containerBooted : null}`}>
            <div className={classes.content}>
              <ConnectedSwitch>
                {!isBooted && <Route exact path="/login" component={Auth} />}
                {!isAuthenticated && <Route exact path="/forgot-password" component={ForgotPassword} />}
                {!isAuthenticated && <Route exact path="/reset-password/:token" component={ResetPassword} />}
                <PrivateRoute exact path="/" canAccess={isBooted} component={Dashboard} />
                <PrivateRoute exact path="/orders" canAccess={isBooted} component={Orders} />
                <PrivateRoute path="/orders/:orderReference" canAccess={isBooted} component={Order} />
                <PrivateRoute exact path="/shipments" canAccess={isBooted} component={Shipments} />
                <PrivateRoute path="/shipments/:shipmentId" canAccess={isBooted} component={Shipment} />
                <PrivateRoute exact path="/products" canAccess={isBooted} component={Products} />
                <PrivateRoute path="/products/:skuCode" canAccess={isBooted} component={Product} />
                <PrivateRoute exact path="/suppliers" canAccess={isBooted} component={Suppliers} />
                <PrivateRoute exact path="/deliveries" canAccess={isBooted} component={Deliveries} />
                <PrivateRoute path="/delivery/:supplierId/:deliveryId" canAccess={isBooted} component={Delivery} />
                <PrivateRoute exact path="/jobs" canAccess={isBooted} component={Jobs} />
                <PrivateRoute exact path="/restock" canAccess={isBooted} component={Restock} />
                <PrivateRoute exact path="/settings" canAccess={isBooted} component={UnderDevelopment} />
                <Route component={NotFound} />
              </ConnectedSwitch>
            </div>
          </main>
          {
            length(datedDeliveryNextDay)
            ? (
              <Snackbar
                open
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
              >
                <div className={classes.alert}>
                  <Typography variant="h6" component="p" color="inherit">
                    {`${length(datedDeliveryNextDay)} dated delivery order${length(datedDeliveryNextDay) > 1 ? 's' : ''}!`}
                  </Typography>
                </div>
              </Snackbar>
            )
            : null
          }
          <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={this.state.notify}
            autoHideDuration={2000}
            onClose={(event, reason) => {
              if (reason === 'clickaway') return
              this.closeNotification()
            }}
            onExited={clearNotification}
            // TransitionProps={{
            //   'onExited': clearNotification
            // }}
            ContentProps={{
              'className': classes.snackbar,
            }}
            message={(
              <div className={classes.notification}>
                {notificationLevel && (notificationLevel === 'error') && (
                  <IconError
                    className={classes.iconError}
                    color="error"
                  />
                )}
                {notificationLevel && (notificationLevel === 'success') && (
                  <IconError
                    className={classes.iconError}
                    color="primary"
                  />
                )}
                {notificationMessage}
              </div>
            )}
            action={[
              <IconButton
                key="close"
                color="inherit"
                onClick={this.closeNotification}
              >
                <IconClose />
              </IconButton>,
            ]}
          />
        </div>
      </div>
    )
  }
}

Index.propTypes = {
  classes: PropTypes.object.isRequired,
}


const mapStateToProps = ({
  startup: { booted },
  app: { config },
  notification: { message, level },
  auth: { token, user },
  router: { location },
  dashboard: { datedDeliveryNextDay },
  jobs: { jobs, failedJobs, lastJobProcessedDate }
}) => ({
  appName: config.app_name,
  isAuthenticated: and(!!token, !!user),
  isBooted: booted && and(!!token, !!user),
  user,
  location,
  notificationMessage: message,
  notificationLevel: level,
  datedDeliveryNextDay,
  jobs,
  failedJobs,
  lastJobProcessedDate
})

const {
  clearNotification,
  logout,
} = Actions

const mapDispatchToProps = dispatch => bindActionCreators({
  push,
  replace,
  clearNotification,
  logout,
}, dispatch)

export default MaterialThemeWrapper(
  withRouter(compose(withStyles(styles), connect(mapStateToProps, mapDispatchToProps))(Index))
)
