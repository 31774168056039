import { path, prop } from 'ramda'
import {
  take, put, call,
  fork, cancel, cancelled,
  // delay
} from 'redux-saga/effects'
import { Types, Creators as Actions } from '../actions'


export default api => {

  // GET Config
  function* getConfig() {
    try {
      // yield call(delay, 2000)
      const configResp = yield call(api.getConfig)

      // Did config load?
      if (prop('ok', configResp)) {
        const data = path(['data', 'data'], configResp) || {}
        yield put(Actions.getConfigSuccess(data))
      } else {
        const error = path(['data', 'message'], configResp) || 'Failed to load app...'
        yield put(Actions.getConfigFailure(error))
      }
    } catch (error) {
      yield put(Actions.getConfigFailure(error))
    } finally {
      if (yield cancelled()) {
        // TODO task cancelled
        console.log('GET Config task cancelled.')
      }
    }
  }

  function* getConfigFlow() {
    let lastTask
    while (true) {
      yield take(Types.GET_CONFIG_ATTEMPT)

      if (lastTask) {
        yield cancel(lastTask)
      }

      lastTask = yield fork(getConfig)
    }
  }

  return {
    getConfigFlow,
  }

}
