import { createStore, applyMiddleware, compose } from 'redux'
import { connectRouter, routerMiddleware } from 'connected-react-router'
import createSagaMiddleware from 'redux-saga'
import { createBrowserHistory } from 'history'
import { persistStore, persistCombineReducers } from 'redux-persist'
import storage from 'redux-persist/es/storage'
import immutableTransform from './immutableTransform'
import reducers from '../reducers/'
import sagas from '../sagas/'

// import { hardSet } from 'redux-persist/lib/stateReconciler/hardSet'


export const history = createBrowserHistory()

const persistConfig = {
  // debug: true,
  key: 'root',
  storage,
  whitelist: ['startup', 'auth', 'app'],
  transforms: [immutableTransform],
  // stateReconciler: hardSet
}

const rootReducer = persistCombineReducers(persistConfig, {
  ...reducers,
  router: connectRouter(history),
})

const routingMiddleware = routerMiddleware(history)
const sagaMiddleware = createSagaMiddleware()

// Redux Dev tools
const composeEnhancers = (process.env.NODE_ENV !== 'production')
  ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
  : compose

export default () => {
  const enhancers = [
    applyMiddleware(sagaMiddleware, routingMiddleware),
  ]

  // Create store
  const store = createStore(
    rootReducer,
    composeEnhancers(...enhancers)
  )

  // Persist store
  const persistor = persistStore(store)

  // Run sagas
  sagaMiddleware.run(sagas)

  return { persistor, store }
}
