import { Types } from '../actions'
import Immutable from 'seamless-immutable'
import { createReducer } from 'reduxsauce'

const INITIAL_STATE = Immutable({
  // Grid
  uiLoadingGet: false,
  data: [],
  columns: [
    { name: 'sku', title: 'SKU Code' },
    { name: 'description', title: 'Description' },
    { name: 'supplier', title: 'Supplier' },
    { name: 'location', title: 'Location' },
    { name: 'hot', title: 'Hot', getCellValue: row => row.hot || null },
    { name: 'cost_ex_vat', title: 'Cost (ex VAT)' },
    { name: 'total_items', title: 'Total Qty' },
    { name: 'items_in_stock', title: 'Available Qty' },
    { name: 'items_reserved', title: 'Reserved Qty' },
    { name: 'items_required', title: 'Required Qty' },
    { name: 'items_notified', title: 'Notified Qty' },
    { name: 'items_allocated', title: 'Allocated Qty' },
    { name: 'items_shipped', title: 'Shipped Qty' },
    { name: 'items_lost_stolen', title: 'Lost/Stolen Qty' },
    { name: 'action', title: null, getCellValue: row => row.sku },
  ],
  columnExtensions: [
    { columnName: 'sku', width: 110 },
    { columnName: 'hot', width: 65 },
    { columnName: 'cost_ex_vat', width: 115 },
    { columnName: 'total_items', width: 100 },
    { columnName: 'items_in_stock', width: 110 },
    { columnName: 'items_reserved', width: 110 },
    { columnName: 'items_required', width: 110 },
    { columnName: 'items_notified', width: 110 },
    { columnName: 'items_allocated', width: 112 },
    { columnName: 'items_shipped', width: 110 },
    { columnName: 'items_lost_stolen', width: 125 },
    { columnName: 'action', width: 70, align: 'right' },
  ],
  visibilityColumnExtensions: [
    { columnName: 'sku', togglingEnabled: false },
    { columnName: 'description', togglingEnabled: false },
    { columnName: 'action', togglingEnabled: false },
  ],
  hiddenColumnNames: [
    'location', 'items_required', 'items_notified',
    'items_allocated', 'items_shipped', 'items_lost_stolen'
  ],
  sorting: [
    { columnName: 'total_items', direction: 'desc' },
  ],
  filters: [],
  currentPage: 0,
  totalCount: 0,
  pageSizes: [10, 25, 50],
  pageSize: 10,
})

// GET Products
const watchProductsFilter = (state, { params }) => {
  return state.merge({
    ...params
  })
}

const getProductsAttempt = (state, { params }) => {
  return state.merge({
    uiLoadingGet: true,
    ...params
  })
}

const getProductsSuccess = (state, { data }) => {
  return state.merge({
    uiLoadingGet: false,
    ...data
  })
}

const getProductsFailure = state => {
  return state.merge({
    uiLoadingGet: false,
  })
}

const setProductsGridState = (state, { name, value }) => {
  return state.set(name, value)
}

const logout = state => INITIAL_STATE

// map our types to our handlers
const ACTION_HANDLERS = {
  // GET Products
  [Types.WATCH_PRODUCTS_FILTER]: watchProductsFilter,
  [Types.GET_PRODUCTS_ATTEMPT]: getProductsAttempt,
  [Types.GET_PRODUCTS_SUCCESS]: getProductsSuccess,
  [Types.GET_PRODUCTS_FAILURE]: getProductsFailure,
  [Types.SET_PRODUCTS_GRID_STATE]: setProductsGridState,
  // Reset
  [Types.LOGOUT]: logout,
}

export default createReducer(INITIAL_STATE, ACTION_HANDLERS)
