import { path, prop } from 'ramda'
import {
  take, put, call,
  fork, cancel, cancelled,
  // delay
} from 'redux-saga/effects'
import { Types, Creators as Actions } from '../actions'

export default api => {
  // GET Orders Ready To Ship
  function* getOrdersReadyToShip(params) {
    try {
      // yield call(delay, 3000)
      const ordersResp = yield call(api.getOrdersReadyToShip, params)

      // Did orders load?
      if (prop('ok', ordersResp)) {
        const data = path(['data', 'data'], ordersResp) || []
        yield put(Actions.getOrdersReadyToShipSuccess(data))
      } else {
        yield put(Actions.getOrdersReadyToShipFailure())
      }
    } catch (error) {
      yield put(Actions.getOrdersReadyToShipFailure())
    } finally {
      if (yield cancelled()) {
        // TODO task cancelled
        console.log('GET Orders Ready To Ship task cancelled.')
        yield put(Actions.getOrdersReadyToShipFailure())
      }
    }
  }

  function* getOrdersReadyToShipFlow() {
    let lastTask
    while (true) {
      const { params } = yield take(Types.GET_ORDERS_READY_TO_SHIP_ATTEMPT)

      if (lastTask) {
        yield cancel(lastTask)
      }

      lastTask = yield fork(getOrdersReadyToShip, params)
    }
  }

  // GET Orders Dated Delivery
  function* getOrdersDatedDelivery() {
    try {
      // yield call(delay, 3000)
      const ordersResp = yield call(api.getOrders, {
        order_status_id: 6
      })

      // Did orders load?
      if (prop('ok', ordersResp)) {
        const data = path(['data', 'data'], ordersResp) || []
        yield put(Actions.getOrdersDatedDeliverySuccess(data))
      } else {
        yield put(Actions.getOrdersDatedDeliveryFailure())
      }
    } catch (error) {
      yield put(Actions.getOrdersDatedDeliveryFailure())
    } finally {
      if (yield cancelled()) {
        // TODO task cancelled
        console.log('GET Orders Dated Delivery task cancelled.')
        yield put(Actions.getOrdersDatedDeliveryFailure())
      }
    }
  }

  function* getOrdersDatedDeliveryFlow() {
    let lastTask
    while (true) {
      yield take(Types.GET_ORDERS_DATED_DELIVERY_ATTEMPT)

      if (lastTask) {
        yield cancel(lastTask)
      }

      lastTask = yield fork(getOrdersDatedDelivery)
    }
  }

  // GET Shipments Active
  function* getShipmentsActive() {
    try {
      // yield call(delay, 3000)
      let params = {}
      params.sort = 'created_at'
      params.order = 'desc'

      const shipmentsResp = yield call(api.getShipmentsActive, params)

      // Did shipments load?
      if (prop('ok', shipmentsResp)) {
        const shipments = path(['data', 'data', 'shipments'], shipmentsResp) || []
        yield put(Actions.getShipmentsActiveSuccess(shipments))
      } else {
        const error = path(['data', 'message'], shipmentsResp) || '[500] Failed to load shipments.'
        yield put(Actions.getShipmentsActiveFailure(error))
      }
    } catch (error) {
      yield put(Actions.getShipmentsActiveFailure(error))
    } finally {
      if (yield cancelled()) {
        // TODO task cancelled
        console.log('GET Shipments Active task cancelled.')
        yield put(Actions.getShipmentsActiveFailure())
      }
    }
  }

  function* getShipmentsActiveFlow() {
    let lastTask
    while (true) {
      yield take(Types.GET_SHIPMENTS_ACTIVE_ATTEMPT)

      if (lastTask) {
        yield cancel(lastTask)
      }

      lastTask = yield fork(getShipmentsActive)
    }
  }

  return {
    getOrdersReadyToShipFlow,
    getOrdersDatedDeliveryFlow,
    getShipmentsActiveFlow,
  }
}
