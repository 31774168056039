import {
  propOr, omit,
} from 'ramda'
import { Types } from '../actions'
import Immutable from 'seamless-immutable'
import { createReducer } from 'reduxsauce'

const INITIAL_STATE = Immutable({
  uiLoadingGet: false,
  uiLoadingUpdate: false,
  uiLoadingDelete: false,
  data: {},
  error: null,
  // Shipment Products Grid
  shipmentProducts: [],
  shipmentProductsColumns: [
    { name: 'sku', title: 'SKU Code' },
    { name: 'description', title: 'Description' },
    // { name: 'location', title: 'Location' },
    { name: 'supplier', title: 'Supplier' },
    { name: 'cost_ex_vat', title: 'Cost (ex VAT)' },
    { name: 'shipped', title: 'Shipped Qty' },
    { name: 'action', title: 'View', getCellValue: row => row.sku },
  ],
  shipmentProductsSorting: [],
  shipmentProductsColumnExtensions: [
    { columnName: 'sku', width: 110 },
    { columnName: 'cost_ex_vat', width: 115 },
    { columnName: 'shipped', width: 110 },
    { columnName: 'action', width: 110, align: 'right' },
  ],
  // Shipment Items Grid
  shipmentItems: [],
  shipmentItemsColumns: [
    { name: 'dateAdded', title: 'Date Added' },
    { name: 'location', title: 'Location' },
    { name: 'comments', title: 'Comments' },
    { name: 'status', title: 'Status' },
    { name: 'signedBy', title: 'Signed By' },
  ]
})

// GET Shipment
const getShipmentAttempt = state => {
  return state.merge({
    uiLoadingGet: true,
  })
}

const getShipmentSuccess = (state, { data }) => {
  return state.merge({
    uiLoadingGet: false,
    data: omit(['products', 'shipment_items'], data),
    shipmentProducts: propOr([], 'products', data),
    shipmentItems: propOr([], 'shipment_items', data),
  })
}

const getShipmentFailure = (state, { error }) => {
  return state.merge({
    uiLoadingGet: false,
    error: error,
  })
}

const setShipmentProductsGridState = (state, { name, value }) => {
  return state.set(name, value)
}

// POST Shipment
const deliverShipmentAttempt = state => {
  return state.merge({
    uiLoadingUpdate: true,
  })
}

const deliverShipmentSuccess = (state, { data }) => {
  return state.merge({
    uiLoadingUpdate: false,
  })
}

const deliverShipmentFailure = (state, { error }) => {
  return state.merge({
    uiLoadingUpdate: false,
    error: error,
  })
}

// DELETE Shipment
const deleteShipmentAttempt = state => {
  return state.merge({
    uiLoadingDelete: true,
  })
}

const deleteShipmentSuccess = state => INITIAL_STATE

const deleteShipmentFailure = (state, { error }) => {
  return state.merge({
    uiLoadingDelete: false,
    error: error,
  })
}

const resetShipmentState = state => INITIAL_STATE

// map our types to our handlers
const ACTION_HANDLERS = {
  // GET Shipment
  [Types.GET_SHIPMENT_ATTEMPT]: getShipmentAttempt,
  [Types.GET_SHIPMENT_SUCCESS]: getShipmentSuccess,
  [Types.GET_SHIPMENT_FAILURE]: getShipmentFailure,
  [Types.SET_SHIPMENT_PRODUCTS_GRID_STATE]: setShipmentProductsGridState,
  // DELIVER Shipment
  [Types.DELIVER_SHIPMENT_ATTEMPT]: deliverShipmentAttempt,
  [Types.DELIVER_SHIPMENT_SUCCESS]: deliverShipmentSuccess,
  [Types.DELIVER_SHIPMENT_FAILURE]: deliverShipmentFailure,
  // DELETE Shipment
  [Types.DELETE_SHIPMENT_ATTEMPT]: deleteShipmentAttempt,
  [Types.DELETE_SHIPMENT_SUCCESS]: deleteShipmentSuccess,
  [Types.DELETE_SHIPMENT_FAILURE]: deleteShipmentFailure,
  // Reset reducer
  [Types.RESET_SHIPMENT_STATE]: resetShipmentState,
}

export default createReducer(INITIAL_STATE, ACTION_HANDLERS)
