import React, { Component } from 'react'
import PropTypes from 'prop-types'

import {
  path, prop, length, equals, or, contains, pluck, pipe, filter, isEmpty,
} from 'ramda'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import { Creators as Actions } from '../actions'
import {
  isGridColumnSortingApplied,
} from '../util/helpers'
import { shipmentsSelector } from '../util/selectors'

// Material UI
import { withStyles } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import IconDone from '@material-ui/icons/Done'
import IconFlightTakeoff from '@material-ui/icons/FlightTakeoff'
import IconVisibility from '@material-ui/icons/Visibility'

// React Grid
import {
  SortingState, FilteringState, PagingState,
  DataTypeProvider, CustomPaging
} from '@devexpress/dx-react-grid'
import {
  Grid as ReactGrid,
  Table, TableHeaderRow, TableColumnVisibility, TableFilterRow,
  PagingPanel,
} from '@devexpress/dx-react-grid-material-ui'

// Components
import LinkButton from '../components/LinkButton'
import Header from '../components/Header'
import RefreshButton from '../components/RefreshButton'
import Loading from '../components/Loading'
import GridColumnChooserCell from '../components/GridColumnChooserCell'
import GridFilterDateField from '../components/GridFilterDateField'
import GridFilterSelectField from '../components/GridFilterSelectField'
import GridDateTypeProvider from '../components/GridDateTypeProvider'
import GridCourierTrackingTypeProvider from '../components/GridCourierTrackingTypeProvider'

const styles = theme => ({
  statusIconStyle: {
    verticalAlign: 'inherit',
    pointerEvents: 'none',
    fontSize: 22
  }
})


class Shipments extends Component {
  // state = {
  //
  // }

  componentDidMount() {
    this.handleMount()
  }

  shouldComponentUpdate(nextProps, nextState) {
    return or(
      !equals(this.props, nextProps),
      !equals(this.state, nextState)
    )
  }

  handleMount = () => {
    this.props.getShipmentsAttempt({
      // currentPage: 0
    })
  }

  handleGridSorting = sort => {
    const { sorting, onSortingChange } = this.props
    const { columnName } = sort[0]

    // Descending as default direction
    if (
      contains(columnName, [
        'shipment_reference', 'created_at', 'dispatched_at', 'delivered_at', 'order_id', 'parcels'
      ])
      && !isGridColumnSortingApplied(columnName, sorting)
    ) {
      sort[0].direction = 'desc';
    }

    onSortingChange(sort)
  }

  // Grid
  headerCellComponent = cellProps => {
    const {
      columns,
      visibilityColumnExtensions,
      hiddenColumnNames,
      onColumnVisibilityChange,
    } = this.props
    const columnName = path(['column', 'name'], cellProps)
    const disabledColumns = pipe(
      filter(column => !column.togglingEnabled),
      pluck('columnName'),
    )(visibilityColumnExtensions)

    if (equals(columnName, 'tracking')) {
      const columnTitle = path(['column', 'title'], cellProps)
      return <Table.Cell value={columnTitle} />
    }

    // No label and sorting
    if (equals(columnName, 'action')) {
      return (
        <GridColumnChooserCell
          columns={columns}
          hiddenColumns={hiddenColumnNames}
          disabledColumns={disabledColumns}
          onChange={onColumnVisibilityChange}
        />
      )
    }

    return <TableHeaderRow.Cell {...cellProps} />
  }

  filterCellComponent = cellProps => {
    const columnName = path(['column', 'name'], cellProps)

    // Date filter
    if (
      contains(columnName, [
        'created_at', 'dispatched_at', 'delivered_at'
      ])
    ) {
      return (
        <TableFilterRow.Cell {...cellProps}>
          <GridFilterDateField
            filter={cellProps.filter}
            onFilter={cellProps.onFilter}
          />
        </TableFilterRow.Cell>
      )
    }

    // Status filter
    if (equals(columnName, 'delivered')) {
      const options = ['Yes', 'No']
      return (
        <TableFilterRow.Cell {...cellProps}>
          <GridFilterSelectField
            options={options}
            filter={cellProps.filter}
            onFilter={cellProps.onFilter}
          />
        </TableFilterRow.Cell>
      )
    }

    // Couriers filter
    if (equals(columnName, 'courier')) {
      const options = pluck('name', this.props.couriers)
      return (
        <TableFilterRow.Cell {...cellProps}>
          <GridFilterSelectField
            options={options}
            filter={cellProps.filter}
            onFilter={cellProps.onFilter}
          />
        </TableFilterRow.Cell>
      )
    }

    // No filter
    if ( contains(columnName, ['tracking', 'action']) ) {
      return <Table.Cell />
    }

    return <TableFilterRow.Cell {...cellProps} />
  }

  rowComponent = rowProps => {
    return (
      <Table.Row hover {...rowProps} />
    )
  }

  cellComponent = ({ style, ...cellProps }) => {
    const columnName = path(['column', 'name'], cellProps)
    let cellStyle = style

    // Wrap cell
    if (equals(columnName, 'tracking')) {
      cellStyle = {
        ...cellStyle,
        whiteSpace: 'pre-line',
      }
    }

    // Indicate delivery status
    if (equals(columnName, 'delivered')) {
      const { classes } = this.props
      const delivered = path(['row', 'delivered_at'], cellProps)

      return (
        <Table.Cell style={cellStyle} {...cellProps}>
          {
            !!delivered
            ? (
              <IconDone
                className={classes.statusIconStyle}
                color="primary"
              />
            )
            : (
              <IconFlightTakeoff
                className={classes.statusIconStyle}
                color="disabled"
              />
            )
          }
        </Table.Cell>
      )
    }

    // Tracking
    if (equals(columnName, 'tracking')) {
      const comments = path(['row', 'comments'], cellProps)
      if (!isEmpty(comments)) {
        return (
          <Table.Cell style={cellStyle} {...cellProps}>
            <Tooltip
              title={
                <div>
                  {comments.map((comment, index) => (
                    <div key={index}>{comment}</div>
                  ))}
                </div>
              }
              placement="top-end"
            >
              <span>{cellProps.value}</span>
            </Tooltip>
          </Table.Cell>
        )
      }
    }

    // Actions
    if (equals(columnName, 'action')) {
      const { location: routerLocation } = this.props
      const pathname = prop('pathname', routerLocation)
      const shipmentId = path(['row', 'id'], cellProps)

      return (
        <Table.Cell style={cellStyle} {...cellProps}>
          <LinkButton
            title={`Open shipment ${shipmentId}`}
            to={{
              pathname: `/shipments/${shipmentId}`,
              state: { from: pathname }
            }}
          >
            <IconVisibility color="action" />
          </LinkButton>
        </Table.Cell>
      )
    }

    return <Table.Cell style={cellStyle} {...cellProps} />
  }

  cellComponentNoData = () => {
    const { columns, hiddenColumnNames } = this.props
    const colSpan = length(columns) - length(hiddenColumnNames)

    return (
      <Table.Cell colSpan={colSpan}>
        {<Typography variant="body1" align="center">{'No shipments.'}</Typography>}
      </Table.Cell>
    )
  }

  render() {
    const {
      // classes,
      // Grid
      uiLoadingGet,
      data,
      columns,
      columnExtensions,
      hiddenColumnNames,
      sorting,
      filters,
      onFiltersChange,
      currentPage,
      onCurrentPageChange,
      totalCount,
      pageSizes,
      pageSize,
      onPageSizeChange,
    } = this.props

    return (
      <div>
        <Header
          title={'Shipments'}
          subtitle={`Browsing page ${currentPage + 1}`}
        >
          <RefreshButton
            onClick={this.handleMount}
          />
        </Header>
        <Paper>
          <ReactGrid
            rows={data}
            columns={columns.asMutable()}
          >
            <GridDateTypeProvider
              columns={['created_at', 'dispatched_at', 'delivered_at']}
            />
            <DataTypeProvider
              for={[
                'delivered',
                'shipment_reference',
                'courier_reference',
                'consignee',
                'created_at',
                'dispatched_at',
                'delivered_at',
                'courier',
                'tracking',
              ]}
              availableFilterOperations={[]}
            />
            <DataTypeProvider
              for={[
                'parcels'
              ]}
              availableFilterOperations={[
                'greaterThanOrEqual',
                'greaterThan',
                'lessThanOrEqual',
                'lessThan',
              ]}
            />
            <GridCourierTrackingTypeProvider
              columns={['courier_reference']}
            />
            <FilteringState
              filters={filters.asMutable()}
              onFiltersChange={onFiltersChange}
            />
            <SortingState
              sorting={sorting.asMutable()}
              onSortingChange={this.handleGridSorting}
            />
            <PagingState
              currentPage={currentPage}
              onCurrentPageChange={onCurrentPageChange}
              pageSize={pageSize}
              onPageSizeChange={onPageSizeChange}
            />
            <CustomPaging
              totalCount={totalCount}
            />
            <Table
              columnExtensions={columnExtensions.asMutable()}
              rowComponent={this.rowComponent}
              cellComponent={this.cellComponent}
              noDataCellComponent={this.cellComponentNoData}
            />
            <TableHeaderRow
              showSortingControls
              cellComponent={this.headerCellComponent}
            />
            <TableColumnVisibility
              hiddenColumnNames={hiddenColumnNames}
            />
            <TableFilterRow
              showFilterSelector
              cellComponent={this.filterCellComponent}
            />
            <PagingPanel pageSizes={pageSizes} />
          </ReactGrid>
        </Paper>
        {uiLoadingGet && <Loading message="Loading shipments..." />}
      </div>
    )
  }
}

Shipments.propTypes = {
  classes: PropTypes.object.isRequired,
  couriers: PropTypes.array.isRequired,
  // Grid
  getShipmentsAttempt: PropTypes.func.isRequired,
  uiLoadingGet: PropTypes.bool.isRequired,
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  columnExtensions: PropTypes.array.isRequired,
  visibilityColumnExtensions: PropTypes.array.isRequired,
  hiddenColumnNames: PropTypes.array.isRequired,
  onColumnVisibilityChange: PropTypes.func.isRequired,
  sorting: PropTypes.array.isRequired,
  onSortingChange: PropTypes.func.isRequired,
  filters: PropTypes.array.isRequired,
  onFiltersChange: PropTypes.func.isRequired,
  currentPage: PropTypes.number.isRequired,
  onCurrentPageChange: PropTypes.func.isRequired,
  totalCount: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  onPageSizeChange: PropTypes.func.isRequired,
  pageSizes: PropTypes.arrayOf(PropTypes.number).isRequired,
}

const {
  watchShipmentsFilter,
  getShipmentsAttempt,
  setShipmentsGridState,
} = Actions

const mapStateToProps = state => ({
  ...state.shipments,
  data: shipmentsSelector(state),
  couriers: state.app.couriers,
})

const mapDispatchToProps = dispatch => bindActionCreators({
  getShipmentsAttempt,
  onColumnVisibilityChange: columnNames => setShipmentsGridState('hiddenColumnNames', columnNames),
  onSortingChange: sorting => getShipmentsAttempt({
    sorting: sorting
  }),
  onFiltersChange: filters => watchShipmentsFilter({
    currentPage: 0,
    filters: filters
  }),
  onCurrentPageChange: currentPage => getShipmentsAttempt({
    currentPage: currentPage
  }),
  onPageSizeChange: pageSize => getShipmentsAttempt({
    currentPage: 0,
    pageSize: pageSize
  }),
}, dispatch)

export default compose(
  withStyles(styles), connect(mapStateToProps, mapDispatchToProps)
)(Shipments)
