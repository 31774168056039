import React from 'react'
import PropTypes from 'prop-types'

// React Grid
import {
  DataTypeProvider,
} from '@devexpress/dx-react-grid'

const GridCurrencyTypeProvider = ({ columns }) => (
  <DataTypeProvider
    formatterComponent={
      ({ value }) => value ? `£${Number(value).toFixed(2)}` : '£0.00'
    }
    for={columns}
  />
)

GridCurrencyTypeProvider.propTypes = {
  columns: PropTypes.array.isRequired,
}

export default GridCurrencyTypeProvider
