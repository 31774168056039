import { path, prop, omit } from 'ramda'
import {
  take, put, call,
  fork, cancel, cancelled,
  // delay
} from 'redux-saga/effects'
import { Types, Creators as Actions } from '../actions'


export default api => {
  // GET Product
  function* getProduct(skuCode) {
    try {
      // yield call(delay, 3000)
      const productResp = yield call(api.getProduct, skuCode)
      const stockResp = yield call(api.getProductStock, skuCode)

      // Did product load?
      if (prop('ok', productResp)) {
        const data = path(['data', 'data'], productResp) || []
        const stockAdjustments = path(['data', 'data'], stockResp) || []
        const success = path(['data', 'message'], productResp) || '[200] Product loaded.'
        yield put(Actions.getProductSuccess(data, stockAdjustments, success))
      } else {
        const error = path(['data', 'message'], productResp) || '[500] Failed to load product.'
        const problem = prop('problem', productResp)
        const status = prop('status', productResp)
        yield put(Actions.getProductFailure(error, problem, status))
      }
    } catch (error) {
      yield put(Actions.getProductFailure(error))
    } finally {
      if (yield cancelled()) {
        // TODO task cancelled
        // NOTE Nothing to do here, product state cleared on LOCATION_CHANGE
        console.log('GET Product task cancelled.')
      }
    }
  }

  function* getFlow() {
    let lastTask
    while (true) {
      const { skuCode } = yield take(Types.GET_PRODUCT_ATTEMPT)

      if (lastTask) {
        yield cancel(lastTask)
      }

      lastTask = yield fork(getProduct, skuCode)
    }
  }

  // POST Product
  // function* createFlow() {
  //
  // }

  // PUT Product
  function* updateProduct(skuCode, product) {
    try {
      // yield call(delay, 3000)
      const productResp = yield call(api.updateProduct, {
        sku: skuCode,
        ...product
      })

      // Did product update?
      if (prop('ok', productResp)) {
        const data = path(['data', 'data'], productResp) || []
        const success = path(['data', 'message'], productResp) || '[200] Product updated.'
        yield put(Actions.updateProductSuccess(data, success))
      } else {
        const error = path(['data', 'message'], productResp) || '[500] Failed to update product.'
        const problem = prop('problem', productResp)
        const status = prop('status', productResp)
        const errors = path(['data', 'errors'], productResp)
        yield put(Actions.updateProductFailure(error, problem, status, errors))
      }
    } catch (error) {
      yield put(Actions.updateProductFailure(error))
    } finally {
      if (yield cancelled()) {
        // TODO task cancelled
        // NOTE Nothing to do here, product state cleared on LOCATION_CHANGE
        console.log('PUT Product task cancelled.')
      }
    }
  }

  function* updateFlow() {
    let lastTask
    while (true) {
      const { skuCode, product } = yield take(Types.UPDATE_PRODUCT_ATTEMPT)

      if (lastTask) {
        yield cancel(lastTask)
      }

      lastTask = yield fork(updateProduct, skuCode, product)
    }
  }

  // DELETE Product
  // function* deleteFlow() {
  //
  // }

  // POST Stock Adjustment
  function* adjustStock(skuCode, adjustment, reserve) {
    try {
      // yield call(delay, 3000)
      let adjustmentResp = {}
      if (reserve) {
        adjustmentResp = yield call(api.reserveStock, skuCode, adjustment)
      } else {
        adjustmentResp = yield call(api.adjustStock, skuCode, omit(['order_reference'], adjustment))
      }

      // Did stock adjust?
      if (prop('ok', adjustmentResp)) {
        const stockResp = yield call(api.getProductStock, skuCode)
        const data = path(['data', 'data'], adjustmentResp) || []
        const stockAdjustments = path(['data', 'data'], stockResp) || []
        const success = path(['data', 'message'], adjustmentResp) || '[200] Product updated.'
        yield put(Actions.adjustStockSuccess(data, stockAdjustments, success))
      } else {
        const error = path(['data', 'message'], adjustmentResp) || '[500] Failed to update product.'
        const problem = prop('problem', adjustmentResp)
        const status = prop('status', adjustmentResp)
        const errors = path(['data', 'errors'], adjustmentResp)
        yield put(Actions.adjustStockFailure(error, problem, status, errors))
      }
    } catch (error) {
      yield put(Actions.adjustStockFailure(error))
    } finally {
      if (yield cancelled()) {
        // TODO task cancelled
        // NOTE Nothing to do here, product state cleared on LOCATION_CHANGE
        console.log('POST Stock Adjustment task cancelled.')
      }
    }
  }

  function* adjustmentFlow() {
    let lastTask
    while (true) {
      const { skuCode, adjustment, reserve } = yield take(Types.ADJUST_STOCK_ATTEMPT)

      if (lastTask) {
        yield cancel(lastTask)
      }

      lastTask = yield fork(adjustStock, skuCode, adjustment, reserve)
    }
  }

  // POST Unreserve Stock
  function* unreserveStock(skuCode, adjustment) {
    try {
      // yield call(delay, 3000)
      const unreserveResp = yield call(api.unreserveStock, skuCode, adjustment)

      // Did stock adjust?
      if (prop('ok', unreserveResp)) {
        const stockResp = yield call(api.getProductStock, skuCode)
        const data = path(['data', 'data'], unreserveResp) || []
        const stockAdjustments = path(['data', 'data'], stockResp) || []
        const success = path(['data', 'message'], unreserveResp) || '[200] Product updated.'
        yield put(Actions.unreserveStockSuccess(data, stockAdjustments, success))
      } else {
        const error = path(['data', 'message'], unreserveResp) || '[500] Failed to update product.'
        const problem = prop('problem', unreserveResp)
        const status = prop('status', unreserveResp)
        const errors = path(['data', 'errors'], unreserveResp)
        yield put(Actions.unreserveStockFailure(error, problem, status, errors))
      }
    } catch (error) {
      yield put(Actions.unreserveStockFailure(error))
    } finally {
      if (yield cancelled()) {
        // TODO task cancelled
        // NOTE Nothing to do here, product state cleared on LOCATION_CHANGE
        console.log('POST Unreserve Stock task cancelled.')
      }
    }
  }

  function* unreserveStockFlow() {
    let lastTask
    while (true) {
      const { skuCode, adjustment } = yield take(Types.UNRESERVE_STOCK_ATTEMPT)

      if (lastTask) {
        yield cancel(lastTask)
      }

      lastTask = yield fork(unreserveStock, skuCode, adjustment)
    }
  }

  // POST Deallocate
  function* deallocateProduct(orderId, product) {
    try {
      // yield call(delay, 3000)
      const deallocateResp = yield call(api.deallocateStock, orderId, { products: [product] })
      const skuCode = product.sku

      // Did stock deallocate?
      if (prop('ok', deallocateResp)) {
        const productResp = yield call(api.getProduct, skuCode)
        const stockResp = yield call(api.getProductStock, skuCode)
        const data = path(['data', 'data'], productResp) || []
        const stockAdjustments = path(['data', 'data'], stockResp) || []
        const success = path(['data', 'message'], deallocateResp) || '[200] Product deallocated.'
        yield put(Actions.deallocateProductSuccess(data, stockAdjustments, success))
      } else {
        const error = path(['data', 'message'], deallocateResp) || '[500] Failed to deallocate stock.'
        const problem = prop('problem', deallocateResp)
        const status = prop('status', deallocateResp)
        yield put(Actions.deallocateProductFailure(error, problem, status))
      }
    } catch (error) {
      yield put(Actions.deallocateProductFailure(error))
    } finally {
      if (yield cancelled()) {
        // TODO task cancelled
        // NOTE Nothing to do here, product state cleared on LOCATION_CHANGE
        console.log('POST Deallocate Product task cancelled.')
      }
    }
  }

  function* deallocateFlow() {
    let lastTask
    while (true) {
      const { orderId, product } = yield take(Types.DEALLOCATE_PRODUCT_ATTEMPT)

      if (lastTask) {
        yield cancel(lastTask)
      }

      lastTask = yield fork(deallocateProduct, orderId, product)
    }
  }

  return {
    getFlow,
    updateFlow,
    adjustmentFlow,
    unreserveStockFlow,
    deallocateFlow,
  }
}
