import { map, head, path, join } from 'ramda'
import { Types } from '../actions'
import Immutable from 'seamless-immutable'
import { createReducer } from 'reduxsauce'

const getItemsValue = value => {
  let val = null
  if (value > 0) val = `+${value}`
  if (value < 0) val = value
  return val
}

const INITIAL_STATE = Immutable({
  uiLoadingGet: false,
  uiLoadingUpdate: false,
  uiLoadingAdjust: false,
  uiLoadingDeallocate: false,
  data: {},
  error: null,
  errors: [],
  // Stock Adjustments Grid
  stockAdjustments: [],
  stockAdjustmentsColumns: [
    { name: 'created_at', title: 'Date Added' },
    { name: 'created_by', title: 'Added By' },
    {
      name: 'comment',
      title: 'Comment',
      getCellValue: row => {
        // NOTE have to replicate this for search
        const orderReference = path(['order', 'order_reference'], row)
        const shipmentReference = path(['order_shipment', 'shipment_reference'], row)
        const deliveryId = path(['delivery', 'id'], row)
        const deliverySupplier = path(['delivery', 'supplier'], row)
        const receivedDeliveryId = path(['received_delivery', 'id'], row)
        const receivedDeliverySupplier = path(['received_delivery', 'supplier'], row)
        let comment = []

        if (row.comment) {
          comment.push(row.comment)
        }

        if (orderReference) {
          comment.push(`Order: ${orderReference}`)
        }

        if (shipmentReference) {
          comment.push(`Shipment: ${shipmentReference}`)
        }

        if (deliveryId && deliverySupplier) {
          comment.push(`Delivery: ${deliverySupplier} #${deliveryId}`)
        } else if (receivedDeliveryId && receivedDeliverySupplier) {
          comment.push(`Delivery: ${receivedDeliverySupplier} #${receivedDeliveryId}`)
        }

        return join('\n', comment)
      },
    },
    {
      name: 'items_in_stock',
      title: 'Available Qty',
      getCellValue: row => getItemsValue(row.items_in_stock),
    },
    {
      name: 'items_reserved',
      title: 'Reserved Qty',
      getCellValue: row => getItemsValue(row.items_reserved),
    },
    {
      name: 'items_required',
      title: 'Required Qty',
      getCellValue: row => getItemsValue(row.items_required),
    },
    {
      name: 'items_notified',
      title: 'Notified Qty',
      getCellValue: row => getItemsValue(row.items_notified),
    },
    {
      name: 'items_allocated',
      title: 'Allocated Qty',
      getCellValue: row => getItemsValue(row.items_allocated),
    },
    {
      name: 'items_shipped',
      title: 'Shipped Qty',
      getCellValue: row => getItemsValue(row.items_shipped),
    },
    // {
    //   name: 'items_returned',
    //   title: 'Returned Qty',
    //   getCellValue: row => getItemsValue(row.items_returned),
    // },
    {
      name: 'items_lost_stolen',
      title: 'Lost/Stolen Qty',
      getCellValue: row => getItemsValue(row.items_lost_stolen),
    },
    { name: 'action', title: 'Reverse', getCellValue: row => null },
  ],
  stockAdjustmentsSorting: [
    // TODO sort by value from another column
    // { columnName: 'id', direction: 'desc' },
  ],
  stockAdjustmentsFilters: [],
  stockAdjustmentsCurrentPage: 0,
  stockAdjustmentsPageSize: 10,
  stockAdjustmentsColumnExtensions: [
    { columnName: 'items_in_stock', width: 110 },
    { columnName: 'items_reserved', width: 110 },
    { columnName: 'items_required', width: 110 },
    { columnName: 'items_notified', width: 110 },
    { columnName: 'items_allocated', width: 112 },
    { columnName: 'items_shipped', width: 110 },
    // { columnName: 'items_returned', width: 100 },
    { columnName: 'items_lost_stolen', width: 125 },
    { columnName: 'action', width: 60, align: 'right' },
  ],
})

// GET Product
const getProductAttempt = state => {
  return state.merge({
    uiLoadingGet: true,
  })
}

const getProductSuccess = (state, { data, stockAdjustments }) => {
  return state.merge({
    uiLoadingGet: false,
    data: data,
    stockAdjustments: stockAdjustments,
  })
}

const getProductFailure = (state, { error }) => {
  return state.merge({
    uiLoadingGet: false,
    error: error,
  })
}

const setStockAdjustmentsGridState = (state, { name, value }) => {
  return state.set(name, value)
}

// PUT Product
const updateProductAttempt = state => {
  return state.merge({
    uiLoadingUpdate: true,
    error: INITIAL_STATE.error,
    errors: INITIAL_STATE.errors,
  })
}

const updateProductSuccess = (state, { data }) => {
  return state.merge({
    uiLoadingUpdate: false,
    data: data,
  })
}

const updateProductFailure = (state, { error, errors }) => {
  const cleanedErrors = errors ? map(head, errors) : INITIAL_STATE.errors
  return state.merge({
    uiLoadingUpdate: false,
    error: error,
    errors: cleanedErrors,
  })
}

// POST Stock Adjustment
const adjustStockAttempt = state => {
  return state.merge({
    uiLoadingAdjust: true,
    error: INITIAL_STATE.error,
    errors: INITIAL_STATE.errors,
  })
}

const adjustStockSuccess = (state, { data, stockAdjustments }) => {
  return state.merge({
    uiLoadingAdjust: false,
    data: Immutable.merge(state.data, data),
    stockAdjustments: stockAdjustments,
  })
}

const adjustStockFailure = (state, { error, errors }) => {
  const cleanedErrors = errors ? map(head, errors) : INITIAL_STATE.errors
  return state.merge({
    uiLoadingAdjust: false,
    error: error,
    errors: cleanedErrors,
  })
}

// POST Unreserve Stock
const unreserveStockAttempt = state => {
  return state.merge({
    uiLoadingAdjust: true,
    error: INITIAL_STATE.error,
    errors: INITIAL_STATE.errors,
  })
}

const unreserveStockSuccess = (state, { data, stockAdjustments }) => {
  return state.merge({
    uiLoadingAdjust: false,
    data: Immutable.merge(state.data, data),
    stockAdjustments: stockAdjustments,
  })
}

const unreserveStockFailure = (state, { error, errors }) => {
  const cleanedErrors = errors ? map(head, errors) : INITIAL_STATE.errors
  return state.merge({
    uiLoadingAdjust: false,
    error: error,
    errors: cleanedErrors,
  })
}

// POST Deallocate
const deallocateProductAttempt = state => {
  return state.merge({
    uiLoadingDeallocate: true,
    error: INITIAL_STATE.error,
    errors: INITIAL_STATE.errors,
  })
}

const deallocateProductSuccess = (state, { data, stockAdjustments }) => {
  return state.merge({
    uiLoadingDeallocate: false,
    data: Immutable.merge(state.data, data),
    stockAdjustments: stockAdjustments,
  })
}

const deallocateProductFailure = (state, { error }) => {
  return state.merge({
    uiLoadingDeallocate: false,
    error: error,
  })
}

// Reset reducer
const resetProductState = () => INITIAL_STATE

// map our types to our handlers
const ACTION_HANDLERS = {
  // GET Product
  [Types.GET_PRODUCT_ATTEMPT]: getProductAttempt,
  [Types.GET_PRODUCT_SUCCESS]: getProductSuccess,
  [Types.GET_PRODUCT_FAILURE]: getProductFailure,
  [Types.SET_STOCK_ADJUSTMENTS_GRID_STATE]: setStockAdjustmentsGridState,
  // PUT Product
  [Types.UPDATE_PRODUCT_ATTEMPT]: updateProductAttempt,
  [Types.UPDATE_PRODUCT_SUCCESS]: updateProductSuccess,
  [Types.UPDATE_PRODUCT_FAILURE]: updateProductFailure,
  // POST Stock Adjustment
  [Types.ADJUST_STOCK_ATTEMPT]: adjustStockAttempt,
  [Types.ADJUST_STOCK_SUCCESS]: adjustStockSuccess,
  [Types.ADJUST_STOCK_FAILURE]: adjustStockFailure,
  // POST Unreserve Stock
  [Types.UNRESERVE_STOCK_ATTEMPT]: unreserveStockAttempt,
  [Types.UNRESERVE_STOCK_SUCCESS]: unreserveStockSuccess,
  [Types.UNRESERVE_STOCK_FAILURE]: unreserveStockFailure,
  // POST Deallocate
  [Types.DEALLOCATE_PRODUCT_ATTEMPT]: deallocateProductAttempt,
  [Types.DEALLOCATE_PRODUCT_SUCCESS]: deallocateProductSuccess,
  [Types.DEALLOCATE_PRODUCT_FAILURE]: deallocateProductFailure,
  // Reset reducer
  [Types.RESET_PRODUCT_STATE]: resetProductState,
}

export default createReducer(INITIAL_STATE, ACTION_HANDLERS)
