import { path, prop } from 'ramda'
import {
  take, put, call,
  fork, cancel, cancelled,
  // delay
} from 'redux-saga/effects'
import { Types, Creators as Actions } from '../actions'


export default api => {
  // GET Delivery
  function* getDelivery(supplierId, deliveryId) {
    try {
      // yield call(delay, 500)
      const deliveryResp = yield call(api.getDelivery, supplierId, deliveryId)

      // Did delivery load?
      if (prop('ok', deliveryResp)) {
        const data = path(['data', 'data'], deliveryResp) || {}
        const success = path(['data', 'message'], deliveryResp) || '[200] Delivery loaded.'
        yield put(Actions.getDeliverySuccess(data, success))
      } else {
        const error = path(['data', 'message'], deliveryResp) || '[500] Failed to load delivery.'
        const problem = prop('problem', deliveryResp)
        const status = prop('status', deliveryResp)
        yield put(Actions.getDeliveryFailure(error, problem, status))
      }
    } catch (error) {
      yield put(Actions.getDeliveryFailure(error))
    } finally {
      if (yield cancelled()) {
        // TODO task cancelled
        // NOTE Nothing to do here, delivery state cleared on LOCATION_CHANGE
        console.log('GET Delivery task cancelled.')
      }
    }
  }

  function* getFlow() {
    let lastTask
    while (true) {
      const { supplierId, deliveryId } = yield take(Types.GET_DELIVERY_ATTEMPT)

      if (lastTask) {
        yield cancel(lastTask)
      }

      lastTask = yield fork(getDelivery, supplierId, deliveryId)
    }
  }

  // POST/PUT Delivery
  function* saveDelivery(supplierId, deliveryId, expectedDate, comments, products) {
    try {
      // yield call(delay, 500)
      let deliveryResp
      if (deliveryId) {
        deliveryResp = yield call(api.updateDelivery, supplierId, deliveryId, {
          expected_at: expectedDate,
          comments,
          products,
        })
      } else {
        deliveryResp = yield call(api.createDelivery, supplierId, {
          expected_at: expectedDate,
          comments,
          products,
        })
      }

      // Did delivery save?
      if (prop('ok', deliveryResp)) {
        const data = path(['data', 'data'], deliveryResp) || {}
        const success = path(['data', 'message'], deliveryResp) || '[200] Delivery saved.'
        yield put(Actions.saveDeliverySuccess(data, success))
      } else {
        const error = path(['data', 'message'], deliveryResp) || '[500] Failed to save delivery.'
        const problem = prop('problem', deliveryResp)
        const status = prop('status', deliveryResp)
        yield put(Actions.saveDeliveryFailure(error, problem, status))
      }
    } catch (error) {
      yield put(Actions.saveDeliveryFailure(error))
    } finally {
      if (yield cancelled()) {
        // TODO task cancelled
        // NOTE Nothing to do here, delivery state cleared on LOCATION_CHANGE
        console.log('POST/PUT Delivery task cancelled.')
      }
    }
  }

  function* saveFlow() {
    let lastTask
    while (true) {
      const { supplierId, deliveryId, expectedDate, comments, products } = yield take(Types.SAVE_DELIVERY_ATTEMPT)

      if (lastTask) {
        yield cancel(lastTask)
      }

      lastTask = yield fork(saveDelivery, supplierId, deliveryId, expectedDate, comments, products)
    }
  }

  // PUT Complete Delivery
  function* completeDelivery(supplierId, deliveryId, comments) {
    try {
      // yield call(delay, 500)
      const deliveryResp = yield call(api.completeDelivery, supplierId, deliveryId, { comments })

      // Did delivery complete?
      if (prop('ok', deliveryResp)) {
        const data = path(['data', 'data'], deliveryResp) || {}
        const success = path(['data', 'message'], deliveryResp) || '[200] Delivery completed.'
        yield put(Actions.completeDeliverySuccess(data, success))
      } else {
        const error = path(['data', 'message'], deliveryResp) || '[500] Failed to complete delivery.'
        const problem = prop('problem', deliveryResp)
        const status = prop('status', deliveryResp)
        yield put(Actions.completeDeliveryFailure(error, problem, status))
      }
    } catch (error) {
      yield put(Actions.completeDeliveryFailure(error))
    } finally {
      if (yield cancelled()) {
        // TODO task cancelled
        // NOTE Nothing to do here, delivery state cleared on LOCATION_CHANGE
        console.log('PUT Complete Delivery task cancelled.')
      }
    }
  }

  function* completeFlow() {
    let lastTask
    while (true) {
      const { supplierId, deliveryId, comments } = yield take(Types.COMPLETE_DELIVERY_ATTEMPT)

      if (lastTask) {
        yield cancel(lastTask)
      }

      lastTask = yield fork(completeDelivery, supplierId, deliveryId, comments)
    }
  }


  // POST/PUT Delivery invoice
  function* saveDeliveryInvoice(deliveryId, invoice, products) {
    try {
      // yield call(delay, 500)
      const deliveryInvoiceResp = yield call(api.updateDeliveryInvoice, deliveryId, {
        ...invoice,
        products,
      })

      // Did delivery save?
      if (prop('ok', deliveryInvoiceResp)) {
        const data = path(['data', 'data'], deliveryInvoiceResp) || {}
        const success = path(['data', 'message'], deliveryInvoiceResp) || '[200] Delivery invoice saved.'
        yield put(Actions.saveDeliveryInvoiceSuccess(data, success))
      } else {
        const error = path(['data', 'message'], deliveryInvoiceResp) || '[500] Failed to save invoice.'
        const problem = prop('problem', deliveryInvoiceResp)
        const status = prop('status', deliveryInvoiceResp)
        yield put(Actions.saveDeliveryInvoiceFailure(error, problem, status))
      }
    } catch (error) {
      yield put(Actions.saveDeliveryInvoiceFailure(error))
    } finally {
      if (yield cancelled()) {
        // TODO task cancelled
        // NOTE Nothing to do here, delivery state cleared on LOCATION_CHANGE
        console.log('POST/PUT Delivery invoice task cancelled.')
      }
    }
  }

  function* saveInvoiceFlow() {
    let lastTask
    while (true) {
      const { deliveryId, invoice, products } = yield take(Types.SAVE_DELIVERY_INVOICE_ATTEMPT)

      if (lastTask) {
        yield cancel(lastTask)
      }

      lastTask = yield fork(saveDeliveryInvoice, deliveryId, invoice, products)
    }
  }


  // DELETE Delivery
  function* deleteDelivery(deliveryId) {
    try {
      // yield call(delay, 2000)
      const deliveryResp = yield call(api.deleteDelivery, deliveryId)

      // Did delivery delete?
      if (prop('ok', deliveryResp)) {
        const success = path(['data', 'message'], deliveryResp) || '[200] Delivery deleted.'
        yield put(Actions.deleteDeliverySuccess(success))
      } else {
        const error = path(['data', 'message'], deliveryResp) || '[500] Failed to delete delivery.'
        const problem = prop('problem', deliveryResp)
        const status = prop('status', deliveryResp)
        yield put(Actions.deleteDeliveryFailure(error, problem, status))
      }
    } catch (error) {
      yield put(Actions.deleteDeliveryFailure(error))
    } finally {
      if (yield cancelled()) {
        // TODO task cancelled
        // NOTE Nothing to do here, delivery state cleared on LOCATION_CHANGE
        console.log('DELETE Delivery task cancelled.')
      }
    }
  }

  function* deleteFlow() {
    let lastTask
    while (true) {
      const { deliveryId } = yield take(Types.DELETE_DELIVERY_ATTEMPT)

      if (lastTask) {
        yield cancel(lastTask)
      }

      lastTask = yield fork(deleteDelivery, deliveryId)
    }
  }

  return {
    getFlow,
    saveFlow,
    completeFlow,
    saveInvoiceFlow,
    deleteFlow,
  }
}
