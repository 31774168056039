import React, { Component } from 'react'
import CssBaseline from '@material-ui/core/CssBaseline'
import { StylesProvider } from '@material-ui/core/styles'
import { withStyles, ThemeProvider } from '@material-ui/core/styles'
import wrapDisplayName from 'recompose/wrapDisplayName'
import createContext from '../styles/createContext'

// Apply some reset
const styles = theme => ({
  '@global': {
    html: {
      height: '100%',
      WebkitFontSmoothing: 'antialiased', // Antialiasing.
      MozOsxFontSmoothing: 'grayscale', // Antialiasing.
    },
    body: {
      minHeight: '100%',
      margin: 0,
    },
    input: {
      '&:-webkit-autofill, &:-webkit-autofill:hover, &:-webkit-autofill:focus': {
        transition: 'background-color 5000s ease-in-out 0s',
        WebkitTextFillColor: '#fff !important',
      }
    },
    a: {
      color: theme.palette.primary.main,
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      }
    },
  },
})

let AppWrapper = props => props.children

AppWrapper = withStyles(styles)(AppWrapper)

const context = createContext()

function MaterialThemeWrapper(BaseComponent) {
  class Root extends Component {
    componentDidMount() {
      // Remove the server-side injected CSS.
      const jssStyles = document.querySelector('#jss-server-side')
      if (jssStyles && jssStyles.parentNode) {
        jssStyles.parentNode.removeChild(jssStyles)
      }
    }
    render() {
      return (
        <StylesProvider jss={context.jss}>
          <ThemeProvider theme={context.theme}>
            <AppWrapper>
              <CssBaseline />
              <BaseComponent {...this.props} />
            </AppWrapper>
          </ThemeProvider>
        </StylesProvider>
      )
    }
  }

  if (process.env.NODE_ENV !== 'production') {
    Root.displayName = wrapDisplayName(BaseComponent, 'MaterialThemeWrapper')
  }

  return Root
}

export default MaterialThemeWrapper
