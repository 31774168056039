// import {
//
// } from 'ramda'
import { Types } from '../actions'
import Immutable from 'seamless-immutable'
import { createReducer } from 'reduxsauce'

const INITIAL_STATE = Immutable({
  // Grid
  uiLoadingGet: false,
  data: [],
  columns: [
    { name: 'supplier', title: 'Supplier' },
    { name: 'total_products', title: 'Total Products' },
    { name: 'total_items', title: 'Total Quantity' },
    { name: 'total_cost_ex_vat', title: 'Total Cost (ex VAT)'},
    { name: 'total_orders', title: 'Total Orders' },
    { name: 'oldest_order', title: 'Oldest Order' },
    { name: 'last_delivery', title: 'Last Delivery' },
    { name: 'comment', title: 'Comment' },
    { name: 'action', title: 'Order', getCellValue: row => row.id },
  ],
  columnExtensions: [
    { columnName: 'total_cost_ex_vat', width: 145 },
    { columnName: 'action', width: 130, align: 'right' },
  ],
  sorting: [
    { columnName: 'supplier', direction: 'asc' },
  ],
})

// GET Restock
const getRestockAttempt = state => {
  return state.merge({
    uiLoadingGet: true,
  })
}

const getRestockSuccess = (state, { data }) => {
  return state.merge({
    uiLoadingGet: false,
    data: data,
  })
}

const getRestockFailure = state => {
  return state.merge({
    uiLoadingGet: false,
  })
}

const setRestockGridState = (state, { name, value }) => {
  return state.set(name, value)
}

const logout = state => INITIAL_STATE

// map our types to our handlers
const ACTION_HANDLERS = {
  // GET Restock
  [Types.GET_RESTOCK_ATTEMPT]: getRestockAttempt,
  [Types.GET_RESTOCK_SUCCESS]: getRestockSuccess,
  [Types.GET_RESTOCK_FAILURE]: getRestockFailure,
  [Types.SET_RESTOCK_GRID_STATE]: setRestockGridState,
  // Reset
  [Types.LOGOUT]: logout,
}

export default createReducer(INITIAL_STATE, ACTION_HANDLERS)
