import { path, prop } from 'ramda'
import {
  take, put, call,
  fork, cancel, cancelled,
  // delay
} from 'redux-saga/effects'
import { Types, Creators as Actions } from '../actions'


export default api => {
  // GET Suppliers
  function* getSuppliers(startup) {
    try {
      // yield call(delay, 2000)
      const suppliersResp = yield call(api.getSuppliers)
      
      // Did suppliers load?
      if (prop('ok', suppliersResp)) {
        const data = path(['data', 'data'], suppliersResp) || []
        let success
        if (!startup) {
          success = path(['data', 'message'], suppliersResp) || '[200] Suppliers loaded.'
        }
        yield put(Actions.getSuppliersSuccess(data, success))
      } else {
        const error = path(['data', 'message'], suppliersResp) || '[500] Failed to load suppliers.'
        const problem = prop('problem', suppliersResp)
        const status = prop('status', suppliersResp)
        yield put(Actions.getSuppliersFailure(error, problem, status))
      }
    } catch (error) {
      yield put(Actions.getSuppliersFailure(error))
    } finally {
      if (yield cancelled()) {
        // TODO task cancelled
        console.log('GET Suppliers task cancelled.')
      }
    }
  }
  
  function* getFlow() {
    let lastTask
    while (true) {
      const { startup } = yield take(Types.GET_SUPPLIERS_ATTEMPT)
      
      if (lastTask) {
        yield cancel(lastTask)
      }
      
      lastTask = yield fork(getSuppliers, startup)
    }
  }
  
  // PUT Supplier
  function* updateSupplier(supplierId, supplier) {
    try {
      // yield call(delay, 3000)
      const supplierResp = yield call(api.updateSupplier, supplierId, supplier)
      
      // Did supplier update?
      if (prop('ok', supplierResp)) {
        const data = path(['data', 'data'], supplierResp) || {}
        const success = path(['data', 'message'], supplierResp) || '[200] Supplier updated.'
        yield put(Actions.updateSupplierSuccess(data, success))
      } else {
        const error = path(['data', 'message'], supplierResp) || '[500] Failed to update supplier.'
        const problem = prop('problem', supplierResp)
        const status = prop('status', supplierResp)
        yield put(Actions.updateSupplierFailure(error, problem, status))
      }
    } catch (error) {
      yield put(Actions.updateSupplierFailure(error))
    } finally {
      if (yield cancelled()) {
        // TODO task cancelled
        // NOTE Nothing to do here, supplier state cleared on LOCATION_CHANGE
        console.log('PUT Supplier task cancelled.')
      }
    }
  }
  
  function* updateFlow() {
    let lastTask
    while (true) {
      const { supplierId, supplier } = yield take(Types.UPDATE_SUPPLIER_ATTEMPT)
      
      if (lastTask) {
        yield cancel(lastTask)
      }
      
      lastTask = yield fork(updateSupplier, supplierId, supplier)
    }
  }
  
  return {
    getFlow,
    updateFlow,
  }
}
