import React from 'react'
import PropTypes from 'prop-types'

// Material UI
import { withStyles } from '@material-ui/core/styles'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Chip from '@material-ui/core/Chip'

const styles = theme => ({
  flex: {
    flex: 1,
  },
  toolbar: {
    marginBottom: theme.spacing(2),
  },
  chip: {
    position: 'relative',
    top: '-3px',
    height: '22px',
    marginLeft: theme.spacing(2),
  },
  subtitle: {
    display: 'block'
  }
})


const Header = ({
  classes,
  title,
  chip,
  onChipClick,
  subtitle,
  children,
}) => (
  <Toolbar disableGutters className={classes.toolbar}>
    <div className={classes.flex}>
      <Typography variant="h5">
        {title}
        {chip && onChipClick && (
          <Chip
            className={classes.chip}
            label={chip}
            onClick={onChipClick}
          />
        )}
        {subtitle && (
          <Typography className={classes.subtitle} variant="caption" gutterBottom>
            {subtitle}
          </Typography>
        )}
      </Typography>
    </div>
    {children}
  </Toolbar>
)

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  chip: PropTypes.string,
  onChipClick: PropTypes.func,
  subtitle: PropTypes.string,
}

export default withStyles(styles)(Header)
