import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import { contains } from 'ramda'

// Material UI
import { withStyles } from '@material-ui/core/styles'

// React Grid
import { Table, ColumnChooser } from '@devexpress/dx-react-grid-material-ui'

const columnChooserItems = (columns, hiddenColumns) =>
  columns.map(column => ({ column, hidden: hiddenColumns.indexOf(column.name) !== -1 }))

const toggleColumn = (hiddenColumns, columnName) => (
  hiddenColumns.indexOf(columnName) === -1
    ? [...hiddenColumns, columnName]
    : hiddenColumns.filter(hiddenColumn => hiddenColumn !== columnName)
)

const styles = theme => ({
  cell: {
    textAlign: 'right',
    overflow: 'visible',
    paddingTop: 0,
    paddingBottom: 0,
  }
})


class GridColumnChooserCell extends PureComponent {
  state = {
    visible: false,
  }

  buttonEl = null

  columnChooserButton = () => {
    return (
      <ColumnChooser.ToggleButton
        getMessage={() => 'Show/Hide'}
        onToggle={() => this.setState({ visible: !this.state.visible })}
        buttonRef={button => { this.buttonEl = button }}
      />
    )
  }

  columnChooserOverlay = () => {
    const {
      columns,
      hiddenColumns,
      disabledColumns,
      onChange,
    } = this.props

    return (
      <ColumnChooser.Overlay
        visible={this.state.visible}
        target={this.buttonEl}
        onHide={() => { this.setState({ visible: false }) }}
      >
        <ColumnChooser.Container>
          {columnChooserItems(columns, hiddenColumns).map((item) => item.column.name !== 'action' && (
            <ColumnChooser.Item
              key={item.column.name}
              onToggle={() => {
                const columnName = item.column.name
                const nextHiddenColumnNames = toggleColumn(hiddenColumns, columnName)
                onChange(nextHiddenColumnNames)
              }}
              disabled={contains(item.column.name, disabledColumns)}
              item={item}
            />
          ))}
        </ColumnChooser.Container>
      </ColumnChooser.Overlay>
    )
  }

  render() {
    const { classes } = this.props

    return (
      <Table.Cell className={classes.cell}>
        {this.columnChooserButton()}
        {this.columnChooserOverlay()}
      </Table.Cell>
    )
  }
}

GridColumnChooserCell.propTypes = {
  columns: PropTypes.array.isRequired,
  hiddenColumns: PropTypes.array.isRequired,
  disabledColumns: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default withStyles(styles)(GridColumnChooserCell)
