import React, { Component } from 'react'
import PropTypes from 'prop-types'

// Material UI
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import ButtonBase from '@material-ui/core/ButtonBase'
import Button from '@material-ui/core/Button'
import IconLock from '@material-ui/icons/Lock'

import CircularProgress from '@material-ui/core/CircularProgress'

const styles = theme => ({
  form: {
    textAlign: 'center',
  },
  icon: {
    width: 64,
    height: 64,
    opacity: 0.5,
    marginBottom: theme.spacing(3),
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing(3),
  },
})


class Login extends Component {
  render() {
    const {
      classes,
      appName,
      uiLoading,
      username,
      password,
      onChange,
      onSubmit,
      navigate,
    } = this.props

    return (
      <form className={classes.form} onSubmit={onSubmit}>
        <IconLock className={classes.icon} color="action" />
        <Typography variant="h5" gutterBottom>
          {`Login to ${appName}`}
        </Typography>
        <TextField
          id="email"
          label="Email"
          name="username"
          type="email"
          value={username}
          required
          disabled={uiLoading}
          fullWidth
          margin="normal"
          onChange={onChange('username')}
        />
        <TextField
          id="password"
          label="Password"
          type="password"
          value={password}
          required
          disabled={uiLoading}
          fullWidth
          // TODO https://developers.google.com/web/updates/2015/06/checkout-faster-with-autofill
          // autoComplete="current-password"
          margin="normal"
          onChange={onChange('password')}
        />
        <div className={classes.footer}>
          <ButtonBase
            disableRipple
            onClick={navigate('/forgot-password')}
          >
            <Typography variant="caption">
              {'Forgot Password?'}
            </Typography>
          </ButtonBase>
          <Button
            variant="contained"
            type="submit"
            color="primary"
            disabled={uiLoading}
          >
            {uiLoading
              ? <CircularProgress color="inherit" size={20} />
              : 'Login'
            }
          </Button>
        </div>
      </form>
    )
  }
}

Login.propTypes = {
  classes: PropTypes.object.isRequired,
  appName: PropTypes.string.isRequired,
  uiLoading: PropTypes.bool.isRequired,
  username: PropTypes.string.isRequired,
  password: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  navigate: PropTypes.func.isRequired,
}

export default withStyles(styles)(Login)
