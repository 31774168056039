import React from 'react'
import PropTypes from 'prop-types'

import {
  prop, sum, pipe, pluck, split, head, propOr, contains
} from 'ramda'
import { encode } from 'html-entities'
import { nl2brArray, formatDate } from '../util/helpers'

// Material UI
import { withStyles } from '@material-ui/core/styles'
import { red } from '@material-ui/core/colors'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'

const highlight = string => {
  const canHighlight = !!string.match(/\(([^)]+)\)/)
  const stringHtmlEntities = encode(string)

  if (canHighlight) {
    return stringHtmlEntities.replaceAll('(', `<span style="color: ${red['A400']}">(`).replaceAll(')', ')</span>')
  }

  return stringHtmlEntities
}

const styles = theme => ({
  container: {
    pageBreakAfter: 'always',
    padding: theme.spacing(2),
    margin: 0,
    width: '100%',
  },
  cell: {
    fontSize: 10,
  },
  cellLast: {
    paddingTop: theme.spacing(),
    paddingRight: 0,
    paddingBottom: theme.spacing(),
    paddingLeft: 0,
  },
  cellInfo: {
    fontSize: 8,
  },
  noWrap: {
    whiteSpace: 'nowrap',
  },
  postcodeAlert: {
    marginLeft: 5,
    color: theme.palette.error.main,
    fontStyle: 'italic',
    fontSize: 12
  },
  bold: {
    fontWeight: 700,
  },
  error: {
    color: theme.palette.error.main,
  },
})


const PrintOrder = ({
  classes,
  order,
  couriers
}) => {
  const {
    // Order details
    // id: orderId,
    order_reference: orderReference,
    status: orderStatus,
    order_date: orderDate,
    customer_billing_contact: customer,
    customer_email: customerEmail,
    customer_phone_number: customerTelephone,
    gift_message: giftMessage,
    warehouse_instructions: warehouseInstructions,
    delivery_date: deliveryDate,
    // Delivery address
    customer_delivery_company: deliveryCompany,
    customer_delivery_contact: deliveryContact,
    customer_delivery_address_line1: deliveryAddress1,
    customer_delivery_address_line2: deliveryAddress2,
    customer_delivery_city: deliveryCity,
    customer_delivery_county: deliveryCounty,
    customer_delivery_postcode: deliveryPostcode,
    customer_delivery_country: deliveryCountry,
    customer_delivery_phone_number: deliveryTelephone,
    delivery_instructions: deliveryInstructions,
    order_items: orderItems,
  } = order
  const totalAllocated = pipe(
    pluck('allocated'),
    sum
  )(orderItems)
  // NOTE UK specific..
  const postcodeArea = pipe(split(' '), head)(deliveryPostcode || '')
  const collectionPostcode = process.env.REACT_APP_COLLECTION_POSTCODE

  return (
    <Grid className={classes.container} container spacing={2}>
      <Grid item xs={6}>
        <Typography variant="body1" gutterBottom>
          <strong className={classes.bold}>{'Order Reference:'}</strong> {orderReference}
        </Typography>
        <Typography variant="body1" gutterBottom>
          <strong className={classes.bold}>{'Order Status:'}</strong> <span className={orderStatus === 'Picking' ? classes.error : null}>{orderStatus}</span>
        </Typography>
        <Typography variant="body1" gutterBottom>
          <strong className={classes.bold}>{'Order Date:'}</strong> {formatDate(orderDate)}
        </Typography>
        <Typography variant="body1" gutterBottom>
          <strong className={classes.bold}>{'Customer:'}</strong> {customer}
        </Typography>
        <Typography variant="body1" gutterBottom>
          <strong className={classes.bold}>{'Email:'}</strong> {customerEmail}
        </Typography>
        <Typography variant="body1" gutterBottom>
          <strong className={classes.bold}>{'Telephone:'}</strong> {customerTelephone}
        </Typography>
        <br />
        {giftMessage && (
          <Typography component="div" variant="body1" color="error" gutterBottom>
            <strong className={classes.bold}>{'Gift Message:'}</strong>
            {nl2brArray(giftMessage).map((text, key) => (
              <Typography key={key} color="error" gutterBottom>{text}</Typography>
            ))}
          </Typography>
        )}
        {warehouseInstructions && (
          <Typography component="div" variant="body1" color="error" gutterBottom>
            <strong className={classes.bold}>{'Warehouse Instructions:'}</strong>
            {nl2brArray(warehouseInstructions).map((text, key) => (
              <Typography key={key} color="error" gutterBottom>{text}</Typography>
            ))}
          </Typography>
        )}
        {deliveryDate && (
          <Typography variant="body1" color="error" gutterBottom>
            <strong className={classes.bold}>{'Delivery Date:'}</strong> {formatDate(deliveryDate, 'dddd, MMM Do YYYY')}
          </Typography>
        )}
      </Grid>
      <Grid item xs={6}>
        <Typography variant="body1" gutterBottom className={classes.bold}>
          {'Shipping Address:'}
        </Typography>
        <Typography variant="body1" gutterBottom>
          {deliveryCompany}
        </Typography>
        <Typography variant="body1" gutterBottom>
          {deliveryContact}
        </Typography>
        <Typography variant="body1" gutterBottom>
          {deliveryAddress1}
        </Typography>
        <Typography variant="body1" gutterBottom>
          {deliveryAddress2}
        </Typography>
        <Typography variant="body1" gutterBottom>
          {deliveryCity}
        </Typography>
        <Typography variant="body1" gutterBottom>
          {deliveryCounty}
        </Typography>
        <Typography variant="body1" gutterBottom>
          {deliveryPostcode}
          {
            deliveryPostcode === collectionPostcode
            ? (<strong key="postcodeAlert" className={classes.postcodeAlert}>(Collection)</strong>)
            : couriers.map(courier => {
              const coverage = propOr([], 'coverage_postcodes', courier)
              if (contains(postcodeArea, coverage)) {
                return (<strong key="postcodeAlert" className={classes.postcodeAlert}>({courier.name})</strong>)
              }
            })
          }
        </Typography>
        <Typography variant="body1" gutterBottom>
          {deliveryCountry}
        </Typography>
        <Typography variant="body1" gutterBottom>
          {deliveryTelephone}
        </Typography>
        {deliveryInstructions && (
          <Typography variant="body1" color="error" gutterBottom>
            <strong className={classes.bold}>{'Instructions:'}</strong> {deliveryInstructions}
          </Typography>
        )}
      </Grid>
      {orderItems && (
        <Grid item xs={12}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell classes={{ root: classes.cell }} className={classes.noWrap} padding="none">
                  {'SKU'}
                </TableCell>
                <TableCell classes={{ root: classes.cell }} className={classes.noWrap} padding="none">
                  {'Description'}
                </TableCell>
                {
                // <TableCell classes={{ root: classes.cell }} className={classes.noWrap} padding="none">
                //   {'Location'}
                // </TableCell>
                }
                <TableCell classes={{ root: classes.cell }} className={classes.noWrap} padding="none">
                  {'Supplier'}
                </TableCell>
                <TableCell classes={{ root: classes.cell }} className={classes.noWrap} padding="none" align="right">
                  {'Warehouse'}
                </TableCell>
                <TableCell classes={{ root: classes.cell }} className={classes.noWrap} padding="none" align="right">
                  {'Ordered'}
                </TableCell>
                <TableCell classes={{ root: classes.cell }} className={`${classes.noWrap} ${classes.error}`} padding="none" align="right">
                  {`Allocated (${totalAllocated})`}
                </TableCell>
                <TableCell classes={{ root: classes.cell }} className={classes.noWrap} padding="none" align="right">
                  {'Shipped'}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {orderItems.map(item => (
                <TableRow key={item.id}>
                  <TableCell classes={{ root: classes.cell }} className={classes.noWrap} padding="none">
                    {item.sku}
                  </TableCell>
                  <TableCell
                    classes={{ root: classes.cell }}
                    padding="none"
                  >
                    <span dangerouslySetInnerHTML={{ __html: highlight(item.description) }} />
                    {item.kit && (
                      <div className={classes.cellInfo}><i className={classes.error}>({item.kit.description})</i></div>
                    )}
                  </TableCell>
                  {
                  // <TableCell classes={{ root: classes.cell }} className={classes.noWrap} padding="none">
                  //   {item.location}
                  // </TableCell>
                  }
                  <TableCell classes={{ root: classes.cell }} className={classes.noWrap} padding="none">
                    {item.supplier}
                    {item.location && (
                      <div className={classes.cellInfo}><i className={classes.error}>({item.location})</i></div>
                    )}
                  </TableCell>
                  <TableCell classes={{ root: classes.cell }} className={classes.noWrap} padding="none" align="right">
                    {sum([item.items_in_stock, item.items_allocated, item.items_reserved])}
                  </TableCell>
                  <TableCell classes={{ root: classes.cell }} padding="none" align="right">
                    {item.ordered}
                  </TableCell>
                  <TableCell classes={{ root: classes.cell }} className={`${item.allocated > 0 ? `${classes.error} ${classes.bold}` : null}`} padding="none" align="right">
                    {item.allocated}
                  </TableCell>
                  <TableCell classes={{ root: classes.cell }} className={classes.cellLast} align="right">
                    {item.shipped}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Grid>
      )}
    </Grid>
  )
}

PrintOrder.propTypes = {
  classes: PropTypes.object.isRequired,
  order: PropTypes.object.isRequired,
  couriers: PropTypes.array.isRequired,
}

export default withStyles(styles)(PrintOrder)
