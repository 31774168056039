import { all, equals, prop } from 'ramda'
import { Types } from '../actions'
import Immutable from 'seamless-immutable'
import { createReducer } from 'reduxsauce'

const INITIAL_STATE = Immutable({
  booted: false,
  configLoaded: false,
  configError: null,
  usersLoaded: false,
  orderStatusesLoaded: false,
  suppliersLoaded: false,
  couriersLoaded: false,
  tasksFailed: 0,
})

const allTasksDone = state => {
  return all(equals(true))([
    prop('usersLoaded', state),
    prop('orderStatusesLoaded', state),
    prop('suppliersLoaded', state),
    prop('couriersLoaded', state),
  ])
}

const configLoaded = state => {
  let newState = Immutable(state)
  newState = newState.set('configLoaded', true)
  return newState.set('configError', INITIAL_STATE.configError)
}

const configFailure = (state, { error }) => {
  let newState = Immutable(state)
  return newState.set('configError', error)
}

const usersLoaded = state => {
  let newState = Immutable(state)
  newState = newState.set('usersLoaded', true)
  return newState.set('booted', allTasksDone(newState))
}

const orderStatusesLoaded = state => {
  let newState = Immutable(state)
  newState = newState.set('orderStatusesLoaded', true)
  return newState.set('booted', allTasksDone(newState))
}

const suppliersLoaded = state => {
  let newState = Immutable(state)
  newState = newState.set('suppliersLoaded', true)
  return newState.set('booted', allTasksDone(newState))
}

const couriersLoaded = state => {
  let newState = Immutable(state)
  newState = newState.set('couriersLoaded', true)
  return newState.set('booted', allTasksDone(newState))
}

const tasksFailed = state => {
  const newState = Immutable(state)
  const failed = prop('tasksFailed', newState)
  return newState.set('tasksFailed', failed + 1)
}

const logout = state => {
  const newState = Immutable(state)
  return newState.merge({
    ...INITIAL_STATE,
    configLoaded: state.configLoaded
  })
}

// map our types to our handlers
const ACTION_HANDLERS = {
  [Types.GET_CONFIG_SUCCESS]: configLoaded,
  [Types.GET_CONFIG_FAILURE]: configFailure,
  [Types.GET_USERS_SUCCESS]: usersLoaded,
  [Types.GET_USERS_FAILURE]: tasksFailed,
  [Types.GET_ORDER_STATUSES_SUCCESS]: orderStatusesLoaded,
  [Types.GET_ORDER_STATUSES_FAILURE]: tasksFailed,
  [Types.GET_SUPPLIERS_SUCCESS]: suppliersLoaded,
  [Types.GET_SUPPLIERS_FAILURE]: tasksFailed,
  [Types.GET_COURIERS_SUCCESS]: couriersLoaded,
  [Types.GET_COURIERS_FAILURE]: tasksFailed,
  // Reset
  [Types.LOGOUT]: logout,
}

export default createReducer(INITIAL_STATE, ACTION_HANDLERS)
