import React from 'react'
import PropTypes from 'prop-types'

import { length, prop } from 'ramda'
import moment from 'moment'

// Material UI
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import MenuItem from '@material-ui/core/MenuItem'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormLabel from '@material-ui/core/FormLabel'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import Checkbox from '@material-ui/core/Checkbox'
import TextField from '@material-ui/core/TextField'

// Components
import CourierFormParcels from './CourierFormParcels'

const instructionLength = 100

const styles = theme => ({
  legend: {
    marginBottom: theme.spacing(2),
  },
})

const CourierFormParcelforce = ({
  classes,
  uiLoading,
  collectionDate,
  onCollectionDateChange,
  services,
  onServicesChange,
  serviceCode,
  saturdayDelivery,
  onSaturdayDeliveryChange,
  smsNotifications,
  onSmsNotificationsChange,
  enhancedCompensation,
  onEnhancedCompensationChange,
  parcels,
  onParcelChange,
  onAddParcel,
  onRemoveParcel,
  instructions,
  onInstructionsChange,
  onCancel,
  onSubmit,
}) => [
  <DialogContent key="parcelforceForm">
    <Grid container spacing={3}>
      <Grid item xs={6}>
        <FormControl component="fieldset" margin="dense" fullWidth>
          <FormLabel component="legend" className={classes.legend}>{'Collection Date'}</FormLabel>
          <TextField
            id="collectionDate"
            helperText="Why not today?"
            type="date"
            inputProps={{ min: moment().format('YYYY-MM-DD') }}
            value={collectionDate}
            onChange={onCollectionDateChange}
            disabled={uiLoading}
          />
          {length(services) ? (
            <FormControlLabel
              control={
                <Checkbox
                  checked={saturdayDelivery}
                  onChange={onSaturdayDeliveryChange}
                  value="saturdayDelivery"
                />
              }
              label="Saturday delivery required?"
            />
          ) : null}
          {length(services) ? (
            <FormControlLabel
              control={
                <Checkbox
                  checked={smsNotifications}
                  onChange={onSmsNotificationsChange}
                  value="smsNotifications"
                />
              }
              label="SMS notifications required?"
            />
          ) : null}
          {length(services) ? (
            <TextField
              id="enhancedCompensation"
              label="Enhanced Compensation"
              select
              value={enhancedCompensation}
              margin="normal"
              onChange={onEnhancedCompensationChange}
              disabled={uiLoading}
            >
              {[
                'None',
                'Up to £500.00 per consign. Cost £4.00',
                'Up to £1000.00 per consign. Cost £8.00',
                'Up to £1500.00 per consign. Cost £12.00',
                'Up to £2000.00 per consign. Cost £16.00',
                'Up to £2500.00 per consign. Cost £20.00'
              ].map((option, key) => (
                <MenuItem key={key} value={key}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          ) : null}
          <br />
          {
            length(services)
            ? (
              <RadioGroup
                name="serviceCode"
                value={serviceCode}
                margin="normal"
                onChange={onServicesChange}
              >
                {services.map((option, key) => {
                  const label = prop('name', option) || ''
                  const value = prop('code', option) || ''
                  
                  return (
                    <FormControlLabel
                      key={key}
                      label={label}
                      value={value}
                      control={<Radio />}
                      disabled={uiLoading}
                    />
                  )
                })}
              </RadioGroup>
            )
            : (
              <Typography variant="body1">{'No services available'}</Typography>
            )
          }
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <CourierFormParcels
          uiLoading={uiLoading}
          parcels={parcels}
          onParcelChange={onParcelChange}
          onAddParcel={onAddParcel}
          onRemoveParcel={onRemoveParcel}
        />
      </Grid>
    </Grid>
    <TextField
      id="instructions"
      label="Delivery Instructions"
      helperText={`${length(instructions)}/${instructionLength}`}
      error={length(instructions) > instructionLength}
      multiline
      rows="3"
      fullWidth
      value={instructions}
      margin="normal"
      onChange={onInstructionsChange}
      disabled={uiLoading}
      variant="outlined"
    />
  </DialogContent>,
  <DialogActions key="parcelforceActions">
    <Button
      onClick={onCancel}
      disabled={uiLoading}
    >
      {'Cancel'}
    </Button>
    <Button
      variant="contained"
      color="primary"
      onClick={onSubmit}
      disabled={!collectionDate || !serviceCode || uiLoading}
    >
      {uiLoading
        ? <CircularProgress color="inherit" size={20} />
        : 'Submit'
      }
    </Button>
  </DialogActions>
]

CourierFormParcelforce.propTypes = {
  uiLoading: PropTypes.bool.isRequired,
  parcels: PropTypes.array.isRequired,
  collectionDate: PropTypes.string.isRequired,
  onCollectionDateChange: PropTypes.func.isRequired,
  services: PropTypes.array.isRequired,
  onServicesChange: PropTypes.func.isRequired,
  serviceCode: PropTypes.string.isRequired,
  saturdayDelivery: PropTypes.bool.isRequired,
  onSaturdayDeliveryChange: PropTypes.func.isRequired,
  smsNotifications: PropTypes.bool.isRequired,
  onSmsNotificationsChange: PropTypes.func.isRequired,
  enhancedCompensation: PropTypes.number.isRequired,
  onEnhancedCompensationChange: PropTypes.func.isRequired,
  onParcelChange: PropTypes.func.isRequired,
  onAddParcel: PropTypes.func.isRequired,
  onRemoveParcel: PropTypes.func.isRequired,
  instructions: PropTypes.string.isRequired,
  onInstructionsChange: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

export default withStyles(styles)(CourierFormParcelforce)
