import {
  path, prop, filter, propEq, length, map,
} from 'ramda'
import {
  take, put, call, fork,
  cancel, cancelled, select,
  delay
} from 'redux-saga/effects'
import { Types, Creators as Actions } from '../actions'

const getStatusChanges = state => state.orders.statusChanges

export default api => {
  // GET Order Statuses
  function* getOrderStatuses() {
    try {
      // yield call(delay, 2000)
      const statusesResp = yield call(api.getOrderStatuses)

      // Did order statuses load?
      if (prop('ok', statusesResp)) {
        const data = path(['data', 'data'], statusesResp) || []
        yield put(Actions.getOrderStatusesSuccess(data))
      } else {
        yield put(Actions.getOrderStatusesFailure())
      }
    } catch (error) {
      yield put(Actions.getOrderStatusesFailure(error))
    } finally {
      if (yield cancelled()) {
        // TODO task cancelled
        console.log('GET Order statuses task cancelled.')
      }
    }
  }

  function* getStatusesFlow() {
    let lastTask
    while (true) {
      yield take(Types.GET_ORDER_STATUSES_ATTEMPT)

      if (lastTask) {
        yield cancel(lastTask)
      }

      lastTask = yield fork(getOrderStatuses)
    }
  }

  // GET Orders
  // TODO temporary throttle/debounce
  function* watchOrdersFilter() {
    yield delay(500)
    yield put(Actions.getOrdersAttempt())
  }

  function* watchFlow() {
    let lastTask
    while (true) {
      yield take(Types.WATCH_ORDERS_FILTER)

      if (lastTask) {
        yield cancel(lastTask)
      }

      lastTask = yield fork(watchOrdersFilter)
    }
  }

  function* getOrders() {
    try {
      const filters = yield select(state => state.orders.filters)
      const sorting = yield select(state => state.orders.sorting)
      const pageSize = yield select(state => state.orders.pageSize)
      const currentPage = yield select(state => state.orders.currentPage)

      let params = {}

      map((s) => {
        params[s.columnName] = s.value
        params[s.columnName + '_operator'] = s.operation
      }, filters)

      map((s) => {
        params.sort = s.columnName
        params.order = s.direction
      }, sorting)

      params.limit = pageSize
      params.page = (currentPage + 1)

      // yield call(delay, 3000)
      const ordersResp = yield call(api.getOrders, params)

      // Did orders load?
      if (prop('ok', ordersResp)) {
        const orders = path(['data', 'data', 'orders'], ordersResp) || []
        const totalCount = path(['data', 'data', 'total'], ordersResp) || 0
        const success = path(['data', 'message'], ordersResp) || '[200] Orders loaded.'

        yield put(Actions.getOrdersSuccess({
          data: orders,
          totalCount: totalCount
        }, success))
      } else {
        const error = path(['data', 'message'], ordersResp) || '[500] Failed to load orders.'
        const problem = prop('problem', ordersResp)
        const status = prop('status', ordersResp)
        yield put(Actions.getOrdersFailure(error, problem, status))
      }
    } catch (error) {
      yield put(Actions.getOrdersFailure(error))
    } finally {
      if (yield cancelled()) {
        // TODO task cancelled
        console.log('GET Orders task cancelled.')
        yield put(Actions.getOrdersFailure())
      }
    }
  }

  function* getFlow() {
    let lastTask
    while (true) {
      yield take(Types.GET_ORDERS_ATTEMPT)

      if (lastTask) {
        yield cancel(lastTask)
      }

      lastTask = yield fork(getOrders)
    }
  }

  // POST Change Orders Status
  function* changeOrderStatus(orderReference, orderStatusId, comment) {
    try {
      // yield call(delay, 500)
      const orderResp = yield call(api.changeOrderStatus, orderReference, {
        order_status_id: orderStatusId,
        comment: comment,
      })

      // Did order status change?
      if (prop('ok', orderResp)) {
        yield put(Actions.changeOrderStatusResult(orderReference, orderStatusId, true))
      } else {
        yield put(Actions.changeOrderStatusResult(orderReference, orderStatusId, false))
      }
    } catch (error) {
      yield put(Actions.changeOrderStatusResult(orderReference, orderStatusId, false))
    }
  }

  function* changeOrdersStatus(orders, orderStatusId, comment) {
    try {
      // NOTE yield* for sequence
      yield* orders.map(orderReference => call(
        changeOrderStatus,
        orderReference,
        orderStatusId,
        comment,
      ))

      // Did all orders status change?
      // NOTE delay hack, react too fast
      yield delay(100)
      const statusChanges = yield select(getStatusChanges)
      const statusChanged = filter(propEq('statusChanged', true), statusChanges)
      const success = `Status changed for ${length(statusChanged)} of ${length(statusChanges)} orders.`
      yield put(Actions.changeOrdersStatusSuccess(success))
    } catch (error) {
      yield put(Actions.changeOrdersStatusFailure(error))
    } finally {
      if (yield cancelled()) {
        // TODO task cancelled
        console.log('POST Change Orders Status task cancelled.')
        // TODO is this the best way to terminate???
        yield put(Actions.changeOrdersStatusTerminate())
      }
    }
  }

  function* changeOrdersStatusFlow() {
    let lastTask
    while (true) {
      const { orders, orderStatusId, comment } = yield take(Types.CHANGE_ORDERS_STATUS_ATTEMPT)

      if (lastTask) {
        yield cancel(lastTask)
      }

      lastTask = yield fork(changeOrdersStatus, orders, orderStatusId, comment)
    }
  }

  return {
    watchFlow,
    getFlow,
    getStatusesFlow,
    changeOrdersStatusFlow,
  }
}
