import React, { Component } from 'react'
import PropTypes from 'prop-types'

import {
  prop, path, pathOr, equals, or, length, pluck, contains,
  pipe, filter, map, isEmpty
} from 'ramda'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import { Creators as Actions } from '../actions'
import {
  isGridColumnSortingApplied,
} from '../util/helpers'
import {
  deliveriesSelector,
  supplierSelector,
} from '../util/selectors'

// Material UI
import { withStyles } from '@material-ui/core/styles'
import { yellow } from '@material-ui/core/colors'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import IconButton from '@material-ui/core/IconButton'
import IconMoreVert from '@material-ui/icons/MoreVert'
import IconDone from '@material-ui/icons/Done'
import IconFlightLand from '@material-ui/icons/FlightLand'
import IconEdit from '@material-ui/icons/Edit'
import IconLocalOffer from '@material-ui/icons/LocalOffer'

// React Grid
import {
  SortingState, SelectionState, FilteringState, PagingState,
  IntegratedSelection, DataTypeProvider, CustomPaging
} from '@devexpress/dx-react-grid'
import {
  Grid as ReactGrid,
  Table, TableHeaderRow, TableSelection, TableColumnVisibility, TableFilterRow,
  PagingPanel,
} from '@devexpress/dx-react-grid-material-ui'

// Components
import LinkButton from '../components/LinkButton'
import Header from '../components/Header'
import RefreshButton from '../components/RefreshButton'
import Loading from '../components/Loading'
import GridColumnChooserCell from '../components/GridColumnChooserCell'
import GridFilterSelectField from '../components/GridFilterSelectField'
import GridFilterDateField from '../components/GridFilterDateField'
import GridDateTypeProvider from '../components/GridDateTypeProvider'
import GridCurrencyTypeProvider from '../components/GridCurrencyTypeProvider'
import DialogSupplierUpdate from '../components/DialogSupplierUpdate'
import PrintButton from '../components/PrintButton'
import PrintWrapper from '../components/PrintWrapper'
import PrintDelivery from '../components/PrintDelivery'

const filterSelected = (data, selection) => map(
  index => data[index],
  selection
)

const styles = theme => ({
  statusIcon: {
    verticalAlign: 'inherit',
    pointerEvents: 'none',
    fontSize: 22
  },
  error: {
    color: theme.palette.error.main,
  },
  warning: {
    color: yellow['A400'],
  },
  success: {
    color: theme.palette.success.main,
  }
})


class Deliveries extends Component {
  state = {
    optionsEl: null,
    editingSupplier: false,
  }

  componentDidMount() {
    this.handleMount()
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // Supplier updated?
    if (
      this.props.uiLoadingUpdateSupplier !== nextProps.uiLoadingUpdateSupplier
      && !nextProps.uiLoadingUpdateSupplier
      && !nextProps.errorSupplier
    ) {
      this.toggleEditingSupplierClose()
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return or(
      !equals(this.props, nextProps),
      !equals(this.state, nextState)
    )
  }

  handleMount = () => {
    this.props.getDeliveriesAttempt({
      // currentPage: 0
    })
  }

  toggleOptions = event => {
    this.setState({
      optionsEl: this.state.optionsEl ? null : event.currentTarget,
    })
  }

  toggleEditingSupplierOpen = supplierId => {
    this.props.setSupplierId(supplierId)
    this.setState({
      editingSupplier: true,
    })
  }

  toggleEditingSupplierClose = () => {
    this.props.setSupplierId(0)
    this.setState({
      editingSupplier: false,
    })
  }

  handleUpdateSupplierSubmit = supplier => {
    const { updateSupplierAttempt } = this.props
    const { supplierId, name, email, comment } = supplier

    // TODO redux forms ???
    updateSupplierAttempt(supplierId, { name, email, comment })
  }

  handleGridSorting = sort => {
    const { sorting, onSortingChange } = this.props
    const { columnName } = sort[0]

    // Descending as default direction
    if (
      contains(columnName, [
        'total_products', 'total_items', 'total_cost_ex_vat', 'total_orders', 'expected_at', 'created_at', 'updated_at'
      ])
      && !isGridColumnSortingApplied(columnName, sorting)
    ) {
      sort[0].direction = 'desc';
    }

    onSortingChange(sort)
  }

  // Grid
  headerCellComponent = cellProps => {
    const {
      columns,
      visibilityColumnExtensions,
      hiddenColumnNames,
      onColumnVisibilityChange,
    } = this.props
    const columnName = path(['column', 'name'], cellProps)
    const disabledColumns = pipe(
      filter(column => !column.togglingEnabled),
      pluck('columnName'),
    )(visibilityColumnExtensions)

    // No label and sorting
    if (equals(columnName, 'action')) {
      return (
        <GridColumnChooserCell
          columns={columns}
          hiddenColumns={hiddenColumnNames}
          disabledColumns={disabledColumns}
          onChange={onColumnVisibilityChange}
        />
      )
    }

    return <TableHeaderRow.Cell {...cellProps} />
  }

  filterCellComponent = cellProps => {
    const { suppliers, users } = this.props
    const suppliersOptions = pluck('name', suppliers)
    const usersOptions = pluck('name', users)
    const columnName = path(['column', 'name'], cellProps)

    // Status filter
    if (equals(columnName, 'received')) {
      const options = ['Yes', 'No']
      return (
        <TableFilterRow.Cell {...cellProps}>
          <GridFilterSelectField
            options={options}
            filter={cellProps.filter}
            onFilter={cellProps.onFilter}
          />
        </TableFilterRow.Cell>
      )
    }

    // Suppliers filter
    if (equals(columnName, 'supplier') && length(suppliersOptions)) {
      return (
        <TableFilterRow.Cell {...cellProps}>
          <GridFilterSelectField
            options={suppliersOptions}
            filter={cellProps.filter}
            onFilter={cellProps.onFilter}
          />
        </TableFilterRow.Cell>
      )
    }

    // Users filter
    if (contains(columnName, ['created_by', 'updated_by']) && length(usersOptions)) {
      return (
        <TableFilterRow.Cell {...cellProps}>
          <GridFilterSelectField
            options={usersOptions}
            filter={cellProps.filter}
            onFilter={cellProps.onFilter}
          />
        </TableFilterRow.Cell>
      )
    }

    // Invoice filter
    if (equals(columnName, 'audited')) {
      const options = ['Yes', 'No']
      return (
        <TableFilterRow.Cell {...cellProps}>
          <GridFilterSelectField
            options={options}
            filter={cellProps.filter}
            onFilter={cellProps.onFilter}
          />
        </TableFilterRow.Cell>
      )
    }

    // Date filter
    if (contains(columnName, ['expected_at', 'created_at', 'updated_at'])) {
      return (
        <TableFilterRow.Cell {...cellProps}>
          <GridFilterDateField
            filter={cellProps.filter}
            onFilter={cellProps.onFilter}
          />
        </TableFilterRow.Cell>
      )
    }

    // No filter
    if (contains(columnName, ['action'])) {
      return <Table.Cell />
    }

    return <TableFilterRow.Cell {...cellProps} />
  }

  rowComponent = rowProps => {
    return (
      <Table.Row hover {...rowProps} />
    )
  }

  cellComponent = ({ style, ...cellProps }) => {
    const { classes } = this.props
    const columnName = path(['column', 'name'], cellProps)
    let cellStyle = style

    // Wrap cell
    if (equals(columnName, 'comment')) {
      cellStyle = {
        ...cellStyle,
        whiteSpace: 'pre-line',
      }
    }

    // Indicate delivery status
    if (equals(columnName, 'received')) {
      const { classes } = this.props
      const received = path(['row', 'received'], cellProps)

      return (
        <Table.Cell style={cellStyle} {...cellProps}>
          {
            !!received
            ? (
              <IconDone
                className={classes.statusIcon}
                color="primary"
              />
            )
            : (
              <IconFlightLand
                className={classes.statusIcon}
                color="disabled"
              />
            )
          }
        </Table.Cell>
      )
    }

    // Invoice cost
    if (equals(columnName, 'audited')) {
      let totalCost = pathOr(0, ['row', 'total_cost_ex_vat'], cellProps)
      const invoiceTotalCost = pathOr(0, ['row', 'invoice_total_cost_ex_vat'], cellProps)
      if (Number(invoiceTotalCost)) totalCost = invoiceTotalCost
      if (totalCost) totalCost = Number(totalCost).toFixed(2)
      const difference = totalCost - cellProps.value

      return (
        <Table.Cell style={cellStyle} {...cellProps}>
          <span className={cellProps.value > 0 ? null : classes.warning}>
            {cellProps.value}
          </span>
          {(difference < 0 || difference > 0) && (
            <Typography
              classes={{
                root: (difference < 0) ? classes.error : classes.success
              }}
              variant="caption"
              color="textSecondary"
            >
              &nbsp;{difference > 0 ? '+' : ''}{difference.toFixed(2)}
            </Typography>
          )}
        </Table.Cell>
      )
    }

    // Actions
    if (equals(columnName, 'action')) {
      const { location: routerLocation } = this.props
      const pathname = prop('pathname', routerLocation)
      const supplierId = path(['row', 'supplier_id'], cellProps)
      const deliveryId = path(['row', 'id'], cellProps)

      return (
        <Table.Cell style={cellStyle} {...cellProps}>
          {
            supplierId
            ? (
              <Tooltip
                id={`tooltip-supplier-${supplierId}-${deliveryId}`}
                title="Update supplier"
                placement="left"
              >
                <IconButton onClick={() => this.toggleEditingSupplierOpen(supplierId)}>
                  <IconEdit color="action" />
                </IconButton>
              </Tooltip>
            )
            : null
          }
          <LinkButton
            title="Open delivery"
            to={{
              pathname: `/delivery/${supplierId}/${deliveryId}`,
              state: { from: pathname }
            }}
          >
            <IconLocalOffer color="action" />
          </LinkButton>
        </Table.Cell>
      )
    }

    return <Table.Cell style={cellStyle} {...cellProps} />
  }

  cellComponentNoData = () => {
    const { columns, hiddenColumnNames } = this.props
    const colSpan = length(columns) - length(hiddenColumnNames)

    return (
      <Table.Cell colSpan={colSpan}>
        {<Typography variant="body1" align="center">{'No deliveries.'}</Typography>}
      </Table.Cell>
    )
  }

  render() {
    const {
      // classes,
      // Grid
      uiLoadingGet,
      data,
      columns,
      columnExtensions,
      hiddenColumnNames,
      sorting,
      selection,
      onSelectionChange,
      filters,
      onFiltersChange,
      currentPage,
      onCurrentPageChange,
      totalCount,
      pageSize,
      onPageSizeChange,
      pageSizes,
      // Update supplier
      uiLoadingUpdateSupplier,
      supplier,
    } = this.props
    const {
      optionsEl,
      editingSupplier,
    } = this.state
    const selectedDeliveries = filterSelected(data, selection)

    return (
      <div>
        <Header
          title={'Deliveries'}
          subtitle={`Browsing page ${currentPage + 1}`}
        >
          <RefreshButton
            onClick={this.handleMount}
          />
          <IconButton
            onClick={this.toggleOptions}
          >
            <IconMoreVert />
          </IconButton>
          <Menu
            anchorEl={optionsEl}
            open={Boolean(optionsEl)}
            onClose={this.toggleOptions}
          >
            <PrintButton
              trigger={() => (
                <MenuItem
                  // onClick={this.handlePrintSelected}
                  disabled={isEmpty(selectedDeliveries)}
                >
                  {`Print (${length(selectedDeliveries)})`}
                </MenuItem>
              )}
              content={() => this.printComponent}
            />
          </Menu>
        </Header>
        <Paper>
          <ReactGrid
            rows={data}
            columns={columns.asMutable()}
          >
            <GridDateTypeProvider
              columns={['created_at', 'updated_at']}
            />
            <GridDateTypeProvider
              columns={['expected_at']}
              format={'DD/MM/YYYY'}
            />
            <GridCurrencyTypeProvider
              columns={['total_cost_ex_vat']}
            />
            <DataTypeProvider
              for={[
                'received',
                'supplier',
                'expected_at',
                'created_at',
                'created_by',
                'updated_at',
                'updated_by'
              ]}
              availableFilterOperations={[]}
            />
            <DataTypeProvider
              for={[
                'total_products',
                'total_items',
                'total_cost_ex_vat',
                'total_orders'
              ]}
              availableFilterOperations={[
                'greaterThanOrEqual',
                'greaterThan',
                'lessThanOrEqual',
                'lessThan',
              ]}
            />
            <FilteringState
              filters={filters.asMutable()}
              onFiltersChange={onFiltersChange}
            />
            <SortingState
              columnExtensions={[
                { columnName: 'audited', sortingEnabled: false },
              ]}
              sorting={sorting.asMutable()}
              onSortingChange={this.handleGridSorting}
            />
            <SelectionState
              selection={selection}
              onSelectionChange={onSelectionChange}
            />
            <PagingState
              currentPage={currentPage}
              onCurrentPageChange={onCurrentPageChange}
              pageSize={pageSize}
              onPageSizeChange={onPageSizeChange}
            />
            <CustomPaging
              totalCount={totalCount}
            />
            <IntegratedSelection />
            <Table
              columnExtensions={columnExtensions.asMutable()}
              rowComponent={this.rowComponent}
              cellComponent={this.cellComponent}
              noDataCellComponent={this.cellComponentNoData}
            />
            <TableHeaderRow
              showSortingControls
              cellComponent={this.headerCellComponent}
            />
            <TableColumnVisibility
              hiddenColumnNames={hiddenColumnNames}
            />
            <TableSelection
              showSelectAll
            />
            <TableFilterRow
              showFilterSelector
              cellComponent={this.filterCellComponent}
            />
            <PagingPanel
              pageSizes={pageSizes}
            />
          </ReactGrid>
        </Paper>
        {!isEmpty(selectedDeliveries) && (
          <PrintWrapper ref={c => (this.printComponent = c)}>
            {selectedDeliveries.map(delivery => (
              <PrintDelivery
                key={delivery.id}
                supplier={delivery.supplier}
                products={delivery.products}
              />
            ))}
          </PrintWrapper>
        )}
        <DialogSupplierUpdate
          open={editingSupplier}
          onClose={this.toggleEditingSupplierClose}
          disableBackdropClick={uiLoadingUpdateSupplier}
          disableEscapeKeyDown={uiLoadingUpdateSupplier}
          supplier={supplier}
          onSubmit={this.handleUpdateSupplierSubmit}
          submitting={uiLoadingUpdateSupplier}
        />
        {uiLoadingGet && <Loading message="Loading deliveries..." />}
      </div>
    )
  }
}

Deliveries.propTypes = {
  classes: PropTypes.object.isRequired,
  // Grid
  getDeliveriesAttempt: PropTypes.func.isRequired,
  uiLoadingGet: PropTypes.bool.isRequired,
  suppliers: PropTypes.array.isRequired,
  users: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  columnExtensions: PropTypes.array.isRequired,
  visibilityColumnExtensions: PropTypes.array.isRequired,
  hiddenColumnNames: PropTypes.array.isRequired,
  onColumnVisibilityChange: PropTypes.func.isRequired,
  sorting: PropTypes.array.isRequired,
  onSortingChange: PropTypes.func.isRequired,
  selection: PropTypes.array.isRequired,
  onSelectionChange: PropTypes.func.isRequired,
  filters: PropTypes.array.isRequired,
  onFiltersChange: PropTypes.func.isRequired,
  currentPage: PropTypes.number.isRequired,
  onCurrentPageChange: PropTypes.func.isRequired,
  totalCount: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  onPageSizeChange: PropTypes.func.isRequired,
  pageSizes: PropTypes.arrayOf(PropTypes.number).isRequired,
  // Update supplier
  supplier: PropTypes.object.isRequired,
  setSupplierId: PropTypes.func.isRequired,
  updateSupplierAttempt: PropTypes.func.isRequired,
  uiLoadingUpdateSupplier: PropTypes.bool.isRequired,
  errorSupplier: PropTypes.string.isRequired,
}

const {
  watchDeliveriesFilter,
  getDeliveriesAttempt,
  setDeliveriesGridState,
  setSupplierId,
  updateSupplierAttempt,
} = Actions

const mapStateToProps = state => ({
  ...state.deliveries,
  data: deliveriesSelector(state),
  suppliers: state.app.suppliers,
  users: state.app.users,
  supplier: supplierSelector(state),
  uiLoadingUpdateSupplier: pathOr(false, ['suppliers', 'uiLoadingUpdate'], state),
  errorSupplier: pathOr('', ['suppliers', 'error'], state),
})

const mapDispatchToProps = dispatch => bindActionCreators({
  getDeliveriesAttempt,
  onColumnVisibilityChange: columnNames => setDeliveriesGridState('hiddenColumnNames', columnNames),
  onSortingChange: sorting => getDeliveriesAttempt({
    sorting: sorting
  }),
  onSelectionChange: selection => setDeliveriesGridState('selection', selection),
  onFiltersChange: filters => watchDeliveriesFilter({
    currentPage: 0,
    filters: filters,
    selection: []
  }),
  onCurrentPageChange: currentPage => getDeliveriesAttempt({
    currentPage: currentPage
  }),
  onPageSizeChange: pageSize => getDeliveriesAttempt({
    currentPage: 0,
    pageSize: pageSize
  }),
  setSupplierId,
  updateSupplierAttempt,
}, dispatch)

export default compose(
  withStyles(styles), connect(mapStateToProps, mapDispatchToProps)
)(Deliveries)
