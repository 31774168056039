import { path, prop, map } from 'ramda'
import {
  take, put, call, fork,
  cancel, cancelled, select,
  delay
} from 'redux-saga/effects'
import { Types, Creators as Actions } from '../actions'


export default api => {
  // GET Shipments
  // TODO temporary throttle/debounce
  function* watchShipmentsFilter() {
    yield delay(500)
    yield put(Actions.getShipmentsAttempt())
  }
  
  function* watchFlow() {
    let lastTask
    while (true) {
      yield take(Types.WATCH_SHIPMENTS_FILTER)
      
      if (lastTask) {
        yield cancel(lastTask)
      }
      
      lastTask = yield fork(watchShipmentsFilter)
    }
  }
  
  function* getShipments() {
    try {
      const filters = yield select(state => state.shipments.filters)
      const sorting = yield select(state => state.shipments.sorting)
      const pageSize = yield select(state => state.shipments.pageSize)
      const currentPage = yield select(state => state.shipments.currentPage)

      let params = {}

      map((s) => {
        params[s.columnName] = s.value
        params[s.columnName + '_operator'] = s.operation
      }, filters)

      map((s) => {
        params.sort = s.columnName
        params.order = s.direction
      }, sorting)

      params.limit = pageSize
      params.page = (currentPage + 1)

      // yield call(delay, 3000)
      const shipmentsResp = yield call(api.getShipments, params)
      
      // Did products load?
      if (prop('ok', shipmentsResp)) {
        const shipments = path(['data', 'data', 'shipments'], shipmentsResp) || []
        const totalCount = path(['data', 'data', 'total'], shipmentsResp) || 0
        const success = path(['data', 'message'], shipmentsResp) || '[200] Shipments loaded.'

        yield put(Actions.getShipmentsSuccess({
          data: shipments,
          totalCount: totalCount
        }, success))
      } else {
        const error = path(['data', 'message'], shipmentsResp) || '[500] Failed to load shipments.'
        const problem = prop('problem', shipmentsResp)
        const status = prop('status', shipmentsResp)
        yield put(Actions.getShipmentsFailure(error, problem, status))
      }
    } catch (error) {
      yield put(Actions.getShipmentsFailure(error))
    } finally {
      if (yield cancelled()) {
        // TODO task cancelled
        console.log('GET Shipments task cancelled.')
        yield put(Actions.getShipmentsFailure())
      }
    }
  }
  
  function* getFlow() {
    let lastTask
    while (true) {
      yield take(Types.GET_SHIPMENTS_ATTEMPT)
      
      if (lastTask) {
        yield cancel(lastTask)
      }
      
      lastTask = yield fork(getShipments)
    }
  }
  
  return {
    watchFlow,
    getFlow,
  }
}
