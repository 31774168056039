import React, { Component } from 'react'
import PropTypes from 'prop-types'

import {
  equals, path, pathOr, pipe, head, prop, join, has, without, or, contains, length, isEmpty
} from 'ramda'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import { goBack } from 'connected-react-router'
import { Link } from 'react-router-dom'
import { Creators as Actions } from '../actions'
import {
  isGridColumnSortingApplied,
  searchInString,
  formatDate,
} from '../util/helpers'
import { productSelector, productStockAdjustmentsSelector } from '../util/selectors'

// Material UI
import { withStyles } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'
import Toolbar from '@material-ui/core/Toolbar'
import MenuItem from '@material-ui/core/MenuItem'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'
import Button from '@material-ui/core/Button'
import ButtonBase from '@material-ui/core/ButtonBase'
import IconButton from '@material-ui/core/IconButton'
import IconLaunch from '@material-ui/icons/Launch'
import IconWhatshot from '@material-ui/icons/Whatshot'
import IconAdd from '@material-ui/icons/Add'
import IconDelete from '@material-ui/icons/Delete'
import TextField from '@material-ui/core/TextField'
import CircularProgress from '@material-ui/core/CircularProgress'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import withMobileDialog from '@material-ui/core/withMobileDialog'

// React Grid
import {
  SortingState, FilteringState, PagingState,
  IntegratedSorting, IntegratedFiltering, IntegratedPaging,
  DataTypeProvider,
} from '@devexpress/dx-react-grid'
import {
  Grid as ReactGrid,
  Table, TableHeaderRow, TableFilterRow,
  PagingPanel,
} from '@devexpress/dx-react-grid-material-ui'

// Components
import Header from '../components/Header'
import GoBackButton from '../components/GoBackButton'
import RefreshButton from '../components/RefreshButton'
import Loading from '../components/Loading'
import Empty from '../components/Empty'
import GridFilterDateField from '../components/GridFilterDateField'
import GridDateTypeProvider from '../components/GridDateTypeProvider'

const adjustmentReasons = [
  { value: 'Lost/Stolen', label: 'Lost/Stolen' },
  { value: 'Damaged', label: 'Damaged' },
  { value: 'Pre-assembled', label: 'Pre-assembled' },
  { value: 'Found', label: 'Found' },
  { value: 'Reserved', label: 'Reserved' },
]

const styles = theme => ({
  container: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  flex: {
    flex: 1,
  },
  paper: {
    padding: theme.spacing(2),
    paddingTop: 0,
  },
  column: {
    height: '100%',
  },
  divider: {
    marginBottom: theme.spacing(1),
  },
  buttons: {
    textAlign: 'right',
  },
})


class Product extends Component {
  state = {
    skuCode: path(['params', 'skuCode'], this.props.match),
    // Update
    description: '',
    location: '',
    // Adjust
    adjusting: false,
    items: 0,
    reason: pipe(head, prop('value'))(adjustmentReasons),
    orderReference: '',
    comment: '',
  }

  componentDidMount() {
    this.handleMount()
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // Retrieved/Updated?
    if (!equals(this.props.data, nextProps.data)) {
      const { data: { description, location } } = nextProps
      this.setState({ description, location })
    }
    // Adjusted?
    if (
      this.props.uiLoadingAdjust !== nextProps.uiLoadingAdjust
      && !nextProps.uiLoadingAdjust
      && !nextProps.error
    ) {
      this.toggleAdjusting()
      this.setState({
        items: 0,
        reason: pipe(head, prop('value'))(adjustmentReasons),
        orderReference: '',
        comment: '',
      })
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return or(
      !equals(this.props, nextProps),
      !equals(this.state, nextState)
    )
  }

  componentWillUnmount() {
    this.props.resetProductState()
  }

  handleMount = () => {
    this.props.getProductAttempt(this.state.skuCode)
  }

  toggleAdjusting = () => {
    this.setState({
      adjusting: !this.state.adjusting,
    })
  }

  handleInputChange = name => event => {
    this.setState({
      [name]: event.target.value,
    })
  }

  handleUpdateSubmit = () => {
    const { updateProductAttempt } = this.props
    const { skuCode, description, location } = this.state

    // TODO redux forms ???
    updateProductAttempt(skuCode, { description, location })
  }

  handleUnreserve = (items, orderReference) => {
    console.log('handleUnreserve', items, orderReference);
    this.setState({
      adjusting: true,
      items: -items,
      reason: 'Reserved',
      orderReference: orderReference || ''
    })
  }

  handleAdjustSubmit = () => {
    const { adjustStockAttempt } = this.props
    const { skuCode, items, reason, orderReference, comment } = this.state

    adjustStockAttempt(
      skuCode,
      {
        items,
        order_reference: orderReference,
        comment: join(' - ', without([null, ''], [reason, comment]))
      },
      // TODO temporary fix
      equals(reason, 'Reserved')
    )
  }

  handleGridStockAdjustmentSorting = sort => {
    const { stockAdjustmentsSorting, stockAdjustmentsOnSortingChange } = this.props
    const { columnName } = sort[0]

    // Descending as default direction
    if (
      contains(columnName, [
        'created_at', 'items_in_stock', 'items_reserved', 'items_required', 'items_notified', 'items_allocated', 'items_shipped', 'items_returned', 'items_lost_stolen'
      ])
      && !isGridColumnSortingApplied(columnName, stockAdjustmentsSorting)
    ) {
      sort[0].direction = 'desc';
    }

    stockAdjustmentsOnSortingChange(sort)
  }

  // Grid
  headerCellComponent = cellProps => {
    const columnName = path(['column', 'name'], cellProps)

    // No label and sorting
    if (equals(columnName, 'action')) {
      return <Table.Cell />
    }

    return <TableHeaderRow.Cell {...cellProps} />
  }

  filterCellComponent = cellProps => {
    const columnName = path(['column', 'name'], cellProps)

    // Date filter
    if (equals(columnName, 'created_at')) {
      return (
        <TableFilterRow.Cell {...cellProps}>
          <GridFilterDateField
            filter={cellProps.filter}
            onFilter={cellProps.onFilter}
          />
        </TableFilterRow.Cell>
      )
    }

    // No filter
    if (equals(columnName, 'action')) {
      return <Table.Cell />
    }

    return <TableFilterRow.Cell {...cellProps} />
  }

  rowComponent = rowProps => {
    return (
      <Table.Row hover {...rowProps} />
    )
  }

  cellComponent = ({ style, ...cellProps }) => {
    const columnName = path(['column', 'name'], cellProps)
    let cellStyle = style
    const rowId = path(['row', 'id'], cellProps)
    const product = {
      sku: path(['row', 'order_item', 'sku'], cellProps),
      kit: pathOr('', ['row', 'order_item', 'kit', 'sku'], cellProps)
    }
    const orderId = path(['row', 'order_id'], cellProps)

    // Wrap cell
    if (equals(columnName, 'comment')) {
      cellStyle = {
        ...cellStyle,
        whiteSpace: 'pre-line',
      }
    }

    // Deallocate stock from order
    if (orderId && equals(columnName, 'action')) {
      const { deallocateProductAttempt } = this.props
      const itemsAllocated = path(['row', 'items_allocated'], cellProps)
      const orderItemsAllocated = path(['row', 'order_item', 'allocated'], cellProps)

      if (itemsAllocated > 0 && orderItemsAllocated > 0) {
        return (
          <Table.Cell style={cellStyle} {...cellProps}>
            <Tooltip
              id={`tooltip-deallocate-${rowId}`}
              title="Deallocate"
              placement="left"
            >
              <ButtonBase onClick={() => deallocateProductAttempt(orderId, product)}>
                <IconDelete color="action" />
              </ButtonBase>
            </Tooltip>
          </Table.Cell>
        )
      }
    }

    // Unreserve stock
    const itemsReserved = path(['row', 'items_reserved'], cellProps)
    if (itemsReserved > 0 && equals(columnName, 'action')) {
      const { unreserveStockAttempt } = this.props
      const reservedOrderReference = path(['row', 'reserved_order_reference'], cellProps)

      return (
        <Table.Cell style={cellStyle} {...cellProps}>
          <Tooltip
            id={`tooltip-unreserve-${rowId}`}
            title="Unreserve"
            placement="left"
          >
            <ButtonBase onClick={() => this.handleUnreserve(itemsReserved, reservedOrderReference)}>
              <IconDelete color="action" />
            </ButtonBase>
          </Tooltip>
        </Table.Cell>
      )
    }

    return <Table.Cell style={cellStyle} {...cellProps} />
  }

  cellComponentNoData = () => {
    return (
      <Table.Cell colSpan={length(this.props.stockAdjustmentsColumns)}>
        {<Typography variant="body1" align="center">{'No adjustments.'}</Typography>}
      </Table.Cell>
    )
  }

  render() {
    const {
      classes,
      location: routerLocation,
      history: routerHistory,
      goBack,
      fullScreen,
      // Grid
      uiLoadingGet,
      uiLoadingUpdate,
      uiLoadingAdjust,
      uiLoadingDeallocate,
      data,
      error,
      errors,
      stockAdjustments,
      stockAdjustmentsColumns,
      stockAdjustmentsSorting,
      stockAdjustmentsFilters,
      stockAdjustmentsOnFiltersChange,
      stockAdjustmentsCurrentPage,
      stockAdjustmentsOnCurrentPageChange,
      stockAdjustmentsPageSize,
      stockAdjustmentsColumnExtensions,
    } = this.props
    const {
      id: productId,
      sku: skuCode,
      backlink: productBacklink,
      supplier,
      supplier_code: supplierCode,
      cost_ex_vat: costExVat,
      created_at: dateAdded,
      updated_at: dateUpdated,
      items_in_stock: quantityAvailable,
      items_reserved: quantityReserved,
      items_required: quantityRequired,
      items_notified: quantityNotified,
      items_allocated: quantityAllocated,
      items_shipped: quantityShipped,
      items_returned: quantityReturned,
      items_lost_stolen: quantityLost,
    } = data
    const {
      skuCode: sku,
      description,
      location,
      adjusting,
      items,
      reason,
      orderReference,
      comment,
    } = this.state
    const pathname = prop('pathname', routerLocation)
    let loading = null

    if (uiLoadingDeallocate) {
      loading = 'Deallocating stock...'
    } else if (uiLoadingGet) {
      loading = 'Loading product...'
    }

    return (
      <div className={classes.container}>
        <Header
          title={`Product #${sku}`}
          subtitle={`Manage product`}
        >
          <GoBackButton
            onBack={goBack}
            history={routerHistory}
          />
          <RefreshButton
            onClick={this.handleMount}
          />
          {description && productBacklink && (
            <Tooltip
              id="tooltip-backlink"
              title="Product backlink"
              placement="left"
            >
              <IconButton
                onClick={() => window.open(`${productBacklink}&filter_name=${description}`, '_blank')}
              >
                <IconLaunch color="action" />
              </IconButton>
            </Tooltip>
          )}
        </Header>
        {
          productId
          ? (
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <Paper className={join(' ', [classes.paper, classes.column])}>
                  <Toolbar disableGutters>
                    <Typography className={classes.flex} variant="subtitle1">
                      {'Product Details'}
                    </Typography>
                    <Tooltip
                      title="Hot"
                      placement="top"
                    >
                      <IconWhatshot color="error" />
                    </Tooltip>
                  </Toolbar>
                  <Divider className={classes.divider} />
                  <TextField
                    id="skuCode"
                    label="SKU Code"
                    fullWidth
                    value={skuCode}
                    margin="dense"
                    disabled
                  />
                  <TextField
                    error={has('description', errors)}
                    id="description"
                    label="Description"
                    fullWidth
                    value={description}
                    margin="dense"
                    readOnly
                    onChange={this.handleInputChange('description')}
                    disabled={uiLoadingUpdate}
                  />
                  <TextField
                    error={has('location', errors) || !location}
                    id="location"
                    label="Location"
                    fullWidth
                    value={location}
                    margin="dense"
                    readOnly
                    onChange={this.handleInputChange('location')}
                    disabled={uiLoadingUpdate}
                  />
                  <TextField
                    id="supplier"
                    label="Supplier"
                    fullWidth
                    value={supplier}
                    margin="dense"
                    disabled
                  />
                  <TextField
                    id="supplierCode"
                    label="Supplier Code"
                    fullWidth
                    value={supplierCode}
                    margin="dense"
                    disabled
                  />
                  <TextField
                    id="costExVat"
                    label="Cost (ex VAT)"
                    fullWidth
                    value={`£${Number(costExVat).toFixed(2)}`}
                    margin="dense"
                    disabled
                  />
                  <TextField
                    // type="date"
                    id="dateAdded"
                    label="Date Added"
                    fullWidth
                    value={formatDate(dateAdded)}
                    margin="dense"
                    disabled
                  />
                  <TextField
                    // type="date"
                    id="dateUpdated"
                    label="Date Updated"
                    fullWidth
                    value={formatDate(dateUpdated)}
                    margin="dense"
                    disabled
                  />
                </Paper>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Paper className={join(' ', [classes.paper, classes.column])}>
                  <Toolbar disableGutters>
                    <Typography className={classes.flex} variant="subtitle1">
                      {'Product Stock'}
                    </Typography>
                  </Toolbar>
                  <Divider className={classes.divider} />
                  <TextField
                    id="quantityAvailable"
                    label="Available Qty"
                    fullWidth
                    value={quantityAvailable}
                    margin="dense"
                    disabled
                  />
                  <TextField
                    error={quantityReserved > 0}
                    id="quantityReserved"
                    label="Reserved Qty"
                    fullWidth
                    value={quantityReserved}
                    margin="dense"
                    disabled
                  />
                  <TextField
                    id="quantityRequired"
                    label="Required Qty"
                    fullWidth
                    value={quantityRequired}
                    margin="dense"
                    disabled
                  />
                  <TextField
                    id="quantityNotified"
                    label="Notified Qty"
                    fullWidth
                    value={quantityNotified}
                    margin="dense"
                    disabled
                  />
                  <TextField
                    id="quantityAllocated"
                    label="Allocated Qty"
                    fullWidth
                    value={quantityAllocated}
                    margin="dense"
                    disabled
                  />
                  <TextField
                    id="quantityShipped"
                    label="Shipped Qty"
                    fullWidth
                    value={quantityShipped}
                    margin="dense"
                    disabled
                  />
                  <TextField
                    id="quantityReturned"
                    label="Returned Qty"
                    fullWidth
                    value={quantityReturned}
                    margin="dense"
                    disabled
                  />
                  <TextField
                    id="quantityLost"
                    label="Lost/Stolen Qty"
                    fullWidth
                    value={quantityLost}
                    margin="dense"
                    disabled
                  />
                </Paper>
              </Grid>
              <Grid item xs={12} className={classes.buttons}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={this.handleUpdateSubmit}
                  disabled={uiLoadingUpdate}
                >
                  {uiLoadingUpdate
                    ? <CircularProgress color="inherit" size={20} />
                    : 'Save'
                  }
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Paper className={classes.paper}>
                  <Toolbar disableGutters>
                    <Typography className={classes.flex} variant="subtitle1">
                      {'Stock Adjustments'}
                    </Typography>
                    <Tooltip
                      id="tooltip-stock-adjustment"
                      title="Add/Remove stock"
                      placement="left"
                    >
                      <IconButton onClick={this.toggleAdjusting}>
                        <IconAdd />
                      </IconButton>
                    </Tooltip>
                  </Toolbar>
                  <Divider className={classes.divider} />
                  <ReactGrid
                    rows={stockAdjustments}
                    columns={stockAdjustmentsColumns.asMutable()}
                  >
                    <GridDateTypeProvider
                      columns={['created_at']}
                    />
                    <DataTypeProvider
                      formatterComponent={
                        ({ row }) => {
                          const rowId = prop('id', row)
                          const orderReference = path(['order', 'order_reference'], row)
                          const reservedOrderReference = prop('reserved_order_reference', row)
                          const kit = pathOr('', ['order_item', 'kit'], row)
                          const shipmentId = prop('order_shipment_id', row)
                          const shipmentReference = path(['order_shipment', 'shipment_reference'], row)
                          const deliveryId = path(['delivery', 'id'], row)
                          const deliverySupplierId = path(['delivery', 'supplier_id'], row)
                          const deliverySupplier = path(['delivery', 'supplier'], row)
                          const receivedDeliveryId = path(['received_delivery', 'id'], row)
                          const receivedDeliverySupplierId = path(['received_delivery', 'supplier_id'], row)
                          const receivedDeliverySupplier = path(['received_delivery', 'supplier'], row)
                          let comment = []

                          if (row.comment) {
                            comment.push(<div key={`comment-${rowId}`}>{row.comment}</div>)
                          }

                          if (orderReference) {
                            comment.push(
                              <div key={`order-${rowId}`}>
                                Order: <Link to={`/orders/${orderReference}`} state={{ from: pathname }}>{orderReference}</Link>
                              </div>
                            )
                          } else if (reservedOrderReference) {
                            comment.push(
                              <div key={`order-${rowId}`}>
                                Order: {reservedOrderReference}
                              </div>
                            )
                          }

                          if (!isEmpty(kit)) {
                            comment.push(
                              <div key={`kit-${rowId}`}>
                                Kit: {kit.description}
                              </div>
                            )
                          }

                          if (shipmentId && shipmentReference) {
                            comment.push(
                              <div key={`shipment-${rowId}`}>
                                Shipment: <Link to={`/shipments/${shipmentId}`} state={{ from: pathname }}>{shipmentReference}</Link>
                              </div>
                            )
                          }

                          if (deliveryId && deliverySupplierId && deliverySupplier) {
                            comment.push(
                              <div key={`delivery-${rowId}`}>
                                Delivery: <Link to={`/delivery/${deliverySupplierId}/${deliveryId}`} state={{ from: pathname }}>{`${deliverySupplier} #${deliveryId}`}</Link>
                              </div>
                            )
                          } else if (receivedDeliveryId && receivedDeliverySupplierId && receivedDeliverySupplier) {
                            comment.push(
                              <div key={`delivery-${rowId}`}>
                                Delivery: <Link to={`/delivery/${receivedDeliverySupplierId}/${receivedDeliveryId}`} state={{ from: pathname }}>{`${receivedDeliverySupplier} #${receivedDeliveryId}`}</Link>
                              </div>
                            )
                          }

                          return <div>{comment}</div>
                        }
                      }
                      for={['comment']}
                    />
                    <FilteringState
                      filters={stockAdjustmentsFilters.asMutable()}
                      onFiltersChange={stockAdjustmentsOnFiltersChange}
                    />
                    <SortingState
                      sorting={stockAdjustmentsSorting.asMutable()}
                      onSortingChange={this.handleGridStockAdjustmentSorting}
                    />
                    <PagingState
                      currentPage={stockAdjustmentsCurrentPage}
                      onCurrentPageChange={stockAdjustmentsOnCurrentPageChange}
                      pageSize={stockAdjustmentsPageSize}
                    />
                    <IntegratedFiltering
                      columnExtensions={[{
                        columnName: 'comment',
                        predicate: searchInString
                      }]}
                    />
                    <IntegratedSorting />
                    <IntegratedPaging />
                    <Table
                      columnExtensions={stockAdjustmentsColumnExtensions.asMutable()}
                      rowComponent={this.rowComponent}
                      cellComponent={this.cellComponent}
                      noDataCellComponent={this.cellComponentNoData}
                    />
                    <TableHeaderRow
                      showSortingControls
                      cellComponent={this.headerCellComponent}
                    />
                    <TableFilterRow
                      cellComponent={this.filterCellComponent}
                    />
                    <PagingPanel />
                  </ReactGrid>
                </Paper>
              </Grid>
            </Grid>
          )
          : (
            error && <Empty message={error || 'No data.'} />
          )
        }
        {productId && (
          <Dialog
            fullScreen={fullScreen}
            open={adjusting}
            onClose={this.toggleAdjusting}
            disableBackdropClick={uiLoadingAdjust}
            disableEscapeKeyDown={uiLoadingAdjust}
          >
            <DialogTitle>Add Stock Adjustment</DialogTitle>
            <DialogContent>
              <DialogContentText>
                To adjust stock please enter quantity (+ or -), select reason and add comment.
              </DialogContentText>
              <TextField
                error={has('items', errors)}
                helperText={has('items', errors) && prop('items', errors)}
                id="items"
                label="Quantity"
                type="number"
                value={items}
                margin="normal"
                onChange={this.handleInputChange('items')}
                disabled={uiLoadingAdjust}
              />
              <div>
                <TextField
                  id="reason"
                  label="Reason"
                  select
                  value={reason}
                  margin="normal"
                  onChange={this.handleInputChange('reason')}
                  disabled={uiLoadingAdjust}
                >
                  {adjustmentReasons.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
              {equals(reason, 'Reserved') && (
                <TextField
                  id="orderReference"
                  label="Order Reference"
                  value={orderReference}
                  margin="normal"
                  onChange={this.handleInputChange('orderReference')}
                  disabled={uiLoadingAdjust}
                />
              )}
              <TextField
                error={has('comment', errors)}
                id="comment"
                // label="Comment"
                placeholder="Why are you adjusting stock?"
                multiline
                rows="3"
                fullWidth
                value={comment}
                margin="normal"
                onChange={this.handleInputChange('comment')}
                disabled={uiLoadingAdjust}
              />
            </DialogContent>
            <DialogActions>
              <Button
                onClick={this.toggleAdjusting}
                disabled={uiLoadingAdjust}
              >
                {'Cancel'}
              </Button>
              <Button
                variant="contained"
                style={{ marginLeft: '10px'}}
                color="primary"
                onClick={this.handleAdjustSubmit}
                disabled={uiLoadingAdjust || (Number(items) === 0)}
              >
                {uiLoadingAdjust
                  ? <CircularProgress color="inherit" size={20} />
                  : 'Submit'
                }
              </Button>
            </DialogActions>
          </Dialog>
        )}
        {loading && <Loading message={loading} />}
      </div>
    )
  }
}

Product.propTypes = {
  classes: PropTypes.object.isRequired,
  goBack: PropTypes.func.isRequired,
  fullScreen: PropTypes.bool.isRequired,
  // Grid
  getProductAttempt: PropTypes.func.isRequired,
  updateProductAttempt: PropTypes.func.isRequired,
  adjustStockAttempt: PropTypes.func.isRequired,
  unreserveStockAttempt: PropTypes.func.isRequired,
  deallocateProductAttempt: PropTypes.func.isRequired,
  uiLoadingGet: PropTypes.bool.isRequired,
  uiLoadingUpdate: PropTypes.bool.isRequired,
  uiLoadingAdjust: PropTypes.bool.isRequired,
  uiLoadingDeallocate: PropTypes.bool.isRequired,
  data: PropTypes.object.isRequired,
  stockAdjustments: PropTypes.array.isRequired,
  stockAdjustmentsColumns: PropTypes.array.isRequired,
  stockAdjustmentsSorting: PropTypes.array.isRequired,
  stockAdjustmentsOnSortingChange: PropTypes.func.isRequired,
  stockAdjustmentsFilters: PropTypes.array.isRequired,
  stockAdjustmentsOnFiltersChange: PropTypes.func.isRequired,
  stockAdjustmentsCurrentPage: PropTypes.number.isRequired,
  stockAdjustmentsOnCurrentPageChange: PropTypes.func.isRequired,
  stockAdjustmentsPageSize: PropTypes.number.isRequired,
  stockAdjustmentsColumnExtensions: PropTypes.array.isRequired,
  resetProductState: PropTypes.func.isRequired,
}

const {
  getProductAttempt,
  setStockAdjustmentsGridState,
  updateProductAttempt,
  adjustStockAttempt,
  unreserveStockAttempt,
  deallocateProductAttempt,
  resetProductState,
} = Actions

const mapStateToProps = state => ({
  ...state.product,
  data: productSelector(state),
  stockAdjustments: productStockAdjustmentsSelector(state),
})

const mapDispatchToProps = dispatch => bindActionCreators({
  goBack,
  getProductAttempt,
  updateProductAttempt,
  adjustStockAttempt,
  unreserveStockAttempt,
  deallocateProductAttempt,
  stockAdjustmentsOnSortingChange: sorting => setStockAdjustmentsGridState('stockAdjustmentsSorting', sorting),
  stockAdjustmentsOnFiltersChange: filters => setStockAdjustmentsGridState('stockAdjustmentsFilters', filters),
  stockAdjustmentsOnCurrentPageChange: currentPage => setStockAdjustmentsGridState('stockAdjustmentsCurrentPage', currentPage),
  resetProductState,
}, dispatch)

export default compose(
  withMobileDialog(), withStyles(styles), connect(mapStateToProps, mapDispatchToProps)
)(Product)
