import React from 'react'
import PropTypes from 'prop-types'

import {
  prop, length, equals,
} from 'ramda'

// Material UI
import { withStyles } from '@material-ui/core/styles'
import { yellow } from '@material-ui/core/colors'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import TextField from '@material-ui/core/TextField'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'

// Components
import CourierFormParcels from './CourierFormParcels'

const instructionLength = 30

const styles = theme => ({
  legend: {
    marginBottom: theme.spacing(1),
  },
  warning: {
    color: yellow['A400'],
  },
})

const CourierFormDhl = ({
  classes,
  uiLoading,
  collectionJobNumber,
  onCollectionJobNumberChange,
  services,
  onServicesChange,
  serviceCode,
  parcels,
  onParcelChange,
  onAddParcel,
  onRemoveParcel,
  instructions,
  instructions1,
  onInstructions1Change,
  instructions2,
  onInstructions2Change,
  safePlace1,
  onSafePlace1Change,
  safePlace2,
  onSafePlace2Change,
  onCancel,
  onSubmit,
}) => ([
  <DialogContent key="offlineForm">
    <Grid container spacing={3}>
      <Grid item xs={6}>
        <FormControl component="fieldset" margin="dense" fullWidth>
          <FormLabel component="legend" className={classes.legend}>{'Collection Job Number'}</FormLabel>
          <TextField
            id="collectionJobNumber"
            helperText="Do you have one?"
            value={collectionJobNumber}
            error={!collectionJobNumber ? true : false}
            onChange={onCollectionJobNumberChange}
            disabled={uiLoading}
          />
          <br />
          {
            length(services)
            ? (
              <RadioGroup
                name="serviceCode"
                value={serviceCode}
                margin="normal"
                onChange={onServicesChange}
              >
                {services.map((option, key) => {
                  const label = prop('name', option) || ''
                  const value = prop('code', option) || ''
                  
                  return (
                    <FormControlLabel
                      key={key}
                      label={label}
                      value={value}
                      control={<Radio />}
                      disabled={uiLoading}
                    />
                  )
                })}
              </RadioGroup>
            )
            : (
              <Typography variant="body1">{'No services available'}</Typography>
            )
          }
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <CourierFormParcels
          uiLoading={uiLoading}
          parcels={parcels}
          onParcelChange={onParcelChange}
          onAddParcel={onAddParcel}
          onRemoveParcel={onRemoveParcel}
        />
      </Grid>
    </Grid>
    {instructions && (
      <Typography className={classes.warning} variant="body1">
        {`Instructions: ${instructions}`}
      </Typography>
    )}
    {
      equals(serviceCode, '210')
      ? ([
        <TextField
          key="dhlSafePlace1"
          id="safePlace1"
          label="Leave Safe 1"
          placeholder="eg Leave safe if no answer"
          helperText={`${length(safePlace1)}/${instructionLength}`}
          error={length(safePlace1) > instructionLength}
          fullWidth
          value={safePlace1}
          margin="normal"
          onChange={onSafePlace1Change}
          disabled={uiLoading}
          variant="outlined"
        />,
        <TextField
          key="dhlSafePlace2"
          id="safePlace2"
          label="Leave Safe 2"
          placeholder="eg in porch"
          helperText={`${length(safePlace2)}/${instructionLength}`}
          error={length(safePlace2) > instructionLength}
          fullWidth
          value={safePlace2}
          margin="normal"
          onChange={onSafePlace2Change}
          disabled={!safePlace1 || uiLoading}
          variant="outlined"
        />
      ])
      : ([
        <TextField
          key="dhlInstructions1"
          id="instructions1"
          label="Instructions 1"
          placeholder="eg Leave with neighbour if no answer"
          helperText={`${length(instructions1)}/${instructionLength}`}
          error={length(instructions1) > instructionLength}
          fullWidth
          value={instructions1}
          margin="normal"
          onChange={onInstructions1Change}
          disabled={uiLoading}
          variant="outlined"
        />,
        <TextField
          key="dhlInstructions2"
          id="instructions2"
          label="Instructions 2"
          placeholder="eg house nr #80"
          helperText={`${length(instructions2)}/${instructionLength}`}
          error={length(instructions2) > instructionLength}
          fullWidth
          value={instructions2}
          margin="normal"
          onChange={onInstructions2Change}
          disabled={!instructions1 || uiLoading}
          variant="outlined"
        />
      ])
    }
  </DialogContent>,
  <DialogActions key="offlineActions">
    <Button
      onClick={onCancel}
      disabled={uiLoading}
    >
      {'Cancel'}
    </Button>
    <Button
      variant="contained"
      color="primary"
      onClick={onSubmit}
      disabled={!collectionJobNumber || !serviceCode || uiLoading}
    >
      {uiLoading
        ? <CircularProgress color="inherit" size={20} />
        : 'Submit'
      }
    </Button>
  </DialogActions>
])

CourierFormDhl.propTypes = {
  classes: PropTypes.object.isRequired,
  uiLoading: PropTypes.bool.isRequired,
  collectionJobNumber: PropTypes.string.isRequired,
  onCollectionJobNumberChange: PropTypes.func.isRequired,
  services: PropTypes.array.isRequired,
  onServicesChange: PropTypes.func.isRequired,
  serviceCode: PropTypes.string.isRequired,
  parcels: PropTypes.array.isRequired,
  onParcelChange: PropTypes.func.isRequired,
  onAddParcel: PropTypes.func.isRequired,
  onRemoveParcel: PropTypes.func.isRequired,
  instructions: PropTypes.string.isRequired,
  instructions1: PropTypes.string.isRequired,
  onInstructions1Change: PropTypes.func.isRequired,
  instructions2: PropTypes.string.isRequired,
  onInstructions2Change: PropTypes.func.isRequired,
  safePlace1: PropTypes.string.isRequired,
  onSafePlace1Change: PropTypes.func.isRequired,
  safePlace2: PropTypes.string.isRequired,
  onSafePlace2Change: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

export default withStyles(styles)(CourierFormDhl)
