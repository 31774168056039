import { path, prop, isEmpty, contains } from 'ramda'
import {
  take, put, call,
  fork, cancel, cancelled,
  // delay
} from 'redux-saga/effects'
import { Types, Creators as Actions } from '../actions'
import * as jsPDF from 'jspdf'
import moment from 'moment'


export default api => {
  // GET Order
  function* getOrder(orderReference) {
    try {
      // yield call(delay, 3000)
      const orderResp = yield call(api.getOrder, orderReference)

      // Did order load?
      if (prop('ok', orderResp)) {
        const shipmentsResp = yield call(api.getOrderShipments, orderReference)
        const historyResp = yield call(api.getOrderHistory, orderReference)
        const data = path(['data', 'data'], orderResp) || {}
        const shipments = path(['data', 'data'], shipmentsResp) || []
        const history = path(['data', 'data'], historyResp) || []
        const success = path(['data', 'message'], orderResp) || '[200] Order loaded.'
        yield put(Actions.getOrderSuccess(data, shipments, history, success))
      } else {
        const error = path(['data', 'message'], orderResp) || '[500] Failed to load order.'
        const problem = prop('problem', orderResp)
        const status = prop('status', orderResp)
        yield put(Actions.getOrderFailure(error, problem, status))
      }
    } catch (error) {
      yield put(Actions.getOrderFailure(error))
    } finally {
      if (yield cancelled()) {
        // TODO task cancelled
        // NOTE Nothing to do here, product state cleared on LOCATION_CHANGE
        console.log('GET Order task cancelled.')
      }
    }
  }

  function* getFlow() {
    let lastTask
    while (true) {
      const { orderReference } = yield take(Types.GET_ORDER_ATTEMPT)

      if (lastTask) {
        yield cancel(lastTask)
      }

      lastTask = yield fork(getOrder, orderReference)
    }
  }

  // POST Order
  // function* postFlow() {
  //
  // }

  // PUT Order
  // function* putFlow() {
  //
  // }


  // SYNC Order
  function* syncOrder(orderId) {
    try {
      // yield call(delay, 3000)
      const orderResp = yield call(api.syncOrder, orderId)

      // Did order sync?
      if (prop('ok', orderResp)) {
        const success = path(['data', 'message'], orderResp) || '[200] Order synced.'
        yield put(Actions.syncOrderSuccess(success))
      } else {
        const error = path(['data', 'message'], orderResp) || '[500] Failed to sync order.'
        const problem = prop('problem', orderResp)
        const status = prop('status', orderResp)
        yield put(Actions.syncOrderFailure(error, problem, status))
      }
    } catch (error) {
      yield put(Actions.syncOrderFailure(error))
    } finally {
      if (yield cancelled()) {
        // TODO task cancelled
        // NOTE Nothing to do here, order state cleared on LOCATION_CHANGE
        console.log('SYNC order task cancelled.')
      }
    }
  }

  function* syncFlow() {
    let lastTask
    while (true) {
      const { orderId } = yield take(Types.SYNC_ORDER_ATTEMPT)

      if (lastTask) {
        yield cancel(lastTask)
      }

      lastTask = yield fork(syncOrder, orderId)
    }
  }


  // DELETE Order
  function* deleteOrder(orderId, skus) {
    try {
      // yield call(delay, 3000)
      const orderResp = yield call(api.deleteOrder, orderId, { unreserve: skus })

      // Did order delete?
      if (prop('ok', orderResp)) {
        const success = path(['data', 'message'], orderResp) || '[200] Order deleted.'
        yield put(Actions.deleteOrderSuccess(success))
      } else {
        const error = path(['data', 'message'], orderResp) || '[500] Failed to delete order.'
        const problem = prop('problem', orderResp)
        const status = prop('status', orderResp)
        yield put(Actions.deleteOrderFailure(error, problem, status))
      }
    } catch (error) {
      yield put(Actions.deleteOrderFailure(error))
    } finally {
      if (yield cancelled()) {
        // TODO task cancelled
        // NOTE Nothing to do here, order state cleared on LOCATION_CHANGE
        console.log('DELETE order task cancelled.')
      }
    }
  }

  function* deleteFlow() {
    let lastTask
    while (true) {
      const { orderId, skus } = yield take(Types.DELETE_ORDER_ATTEMPT)

      if (lastTask) {
        yield cancel(lastTask)
      }

      lastTask = yield fork(deleteOrder, orderId, skus)
    }
  }

  // POST Change Order Status
  function* changeOrderStatus(orderReference, change) {
    try {
      // yield call(delay, 3000)
      const statusChangeResp = yield call(api.changeOrderStatus, orderReference, change)
      const historyResp = yield call(api.getOrderHistory, orderReference)

      // Did status change?
      if (prop('ok', statusChangeResp)) {
        const orderStatusId = prop('order_status_id', change)
        const history = path(['data', 'data'], historyResp) || []
        const success = path(['data', 'message'], statusChangeResp) || '[200] Order status updated.'
        yield put(Actions.changeOrderStatusSuccess(orderStatusId, history, success))
      } else {
        const error = path(['data', 'message'], statusChangeResp) || '[500] Failed to update order status.'
        const problem = prop('problem', statusChangeResp)
        const status = prop('status', statusChangeResp)
        yield put(Actions.changeOrderStatusFailure(error, problem, status))
      }
    } catch (error) {
      yield put(Actions.changeOrderStatusFailure(error))
    } finally {
      if (yield cancelled()) {
        // TODO task cancelled
        // NOTE Nothing to do here, order state cleared on LOCATION_CHANGE
        console.log('POST Change Order Status task cancelled.')
      }
    }
  }

  function* statusChangeFlow() {
    let lastTask
    while (true) {
      const { orderReference, change } = yield take(Types.CHANGE_ORDER_STATUS_ATTEMPT)

      if (lastTask) {
        yield cancel(lastTask)
      }

      lastTask = yield fork(changeOrderStatus, orderReference, change)
    }
  }

  // POST Allocate/Deallocate order lines
  function* adjustOrderItemsStock(orderReference, products, deallocate) {
    try {
      // yield call(delay, 3000)
      let adjustResp
      const body = { products: products }
      if (deallocate) {
        adjustResp = yield call(api.deallocateStock, orderReference, body)
      } else {
        adjustResp = yield call(api.allocateStock, orderReference, body)
      }

      // Did stock adjust?
      if (prop('ok', adjustResp)) {
        const historyResp = yield call(api.getOrderHistory, orderReference)
        const data = path(['data', 'data'], adjustResp) || []
        const history = path(['data', 'data'], historyResp) || []
        const success = path(['data', 'message'], adjustResp) || '[200] Stock adjusted.'
        yield put(Actions.adjustOrderItemsStockSuccess(data, history, success))
      } else {
        const error = path(['data', 'message'], adjustResp) || '[500] Failed to adjust stock.'
        const problem = prop('problem', adjustResp)
        const status = prop('status', adjustResp)
        yield put(Actions.adjustOrderItemsStockFailure(error, problem, status))
      }
    } catch (error) {
      yield put(Actions.adjustOrderItemsStockFailure(error))
    } finally {
      if (yield cancelled()) {
        // TODO task cancelled
        // NOTE Nothing to do here, product state cleared on LOCATION_CHANGE
        console.log('POST Allocate/Deallocate Order Items stock task cancelled.')
      }
    }
  }

  function* adjustOrderItemsStockFlow() {
    let lastTask
    while (true) {
      const { orderReference, products, deallocate } = yield take(Types.ADJUST_ORDER_ITEMS_STOCK_ATTEMPT)

      if (lastTask) {
        yield cancel(lastTask)
      }

      lastTask = yield fork(adjustOrderItemsStock, orderReference, products, deallocate)
    }
  }

  // GET Courier Services
  function* getCourierServices(courier, shipment) {
    try {
      // yield call(delay, 2000)
      const servicesResp = yield call(api.getCourierServices, courier, shipment)


      // Did courier services load?
      if (prop('ok', servicesResp)) {
        const services = path(['data', 'data'], servicesResp) || []

        if (contains(courier, ['packfleet'])) {
          const collectionsResp = yield call(api.getCourierCollections, courier, {
            start_date: moment().format('YYYY-MM-DD')
          })

          if (prop('ok', collectionsResp)) {
            const collections = path(['data', 'data'], collectionsResp) || []
            yield put(Actions.getCourierServicesSuccess(courier, services, collections))
          } else {
            const error = path(['data', 'message'], collectionsResp) || '[500] Failed to load services.'
            const problem = prop('problem', collectionsResp)
            const status = prop('status', collectionsResp)
            const errors = path(['data', 'errors'], collectionsResp)
            yield put(Actions.getCourierServicesFailure(error, problem, status, errors))
          }
        } else {
          yield put(Actions.getCourierServicesSuccess(courier, services, []))
        }
      } else {
        const error = path(['data', 'message'], servicesResp) || '[500] Failed to load services.'
        const problem = prop('problem', servicesResp)
        const status = prop('status', servicesResp)
        const errors = path(['data', 'errors'], servicesResp)
        yield put(Actions.getCourierServicesFailure(error, problem, status, errors))
      }
    } catch (error) {
      yield put(Actions.getCourierServicesFailure(error))
    } finally {
      if (yield cancelled()) {
        // TODO task cancelled
        console.log('GET Courier services task cancelled.')
      }
    }
  }

  function* getCourierServicesFlow() {
    let lastTask
    while (true) {
      const { courier, shipment } = yield take(Types.GET_COURIER_SERVICES_ATTEMPT)

      if (lastTask) {
        yield cancel(lastTask)
      }

      lastTask = yield fork(getCourierServices, courier, shipment)
    }
  }

  // POST Ship Order
  function* shipOrder(courier, shipment) {
    try {
      // yield call(delay, 3000)
      const { order_reference: orderReference } = shipment
      const shipmentResp = yield call(api.createShipment, courier, shipment)

      // Did order ship?
      if (prop('ok', shipmentResp)) {
        const orderResp = yield call(api.getOrder, orderReference)
        const historyResp = yield call(api.getOrderHistory, orderReference)
        const data = path(['data', 'data'], orderResp) || {}
        const shipment = path(['data', 'data'], shipmentResp) || {}
        const history = path(['data', 'data'], historyResp) || []
        const success = path(['data', 'message'], shipmentResp) || '[200] Shipment created.'
        yield put(Actions.shipOrderSuccess(data, shipment, history, success))
      } else {
        const error = path(['data', 'message'], shipmentResp) || '[500] Failed to create shipment.'
        const problem = prop('problem', shipmentResp)
        const status = prop('status', shipmentResp)
        const errors = path(['data', 'errors'], shipmentResp)
        yield put(Actions.shipOrderFailure(error, problem, status, errors))
      }
    } catch (error) {
      yield put(Actions.shipOrderFailure(error))
    } finally {
      if (yield cancelled()) {
        // TODO task cancelled
        // NOTE Nothing to do here, product state cleared on LOCATION_CHANGE
        console.log('POST Ship Order task cancelled.')
      }
    }
  }

  function* shipOrderFlow() {
    let lastTask
    while (true) {
      const { courier, shipment } = yield take(Types.SHIP_ORDER_ATTEMPT)

      if (lastTask) {
        yield cancel(lastTask)
      }

      lastTask = yield fork(shipOrder, courier, shipment)
    }
  }

  // GET Order Shipment Labels
  function* getOrderShipmentLabels(courier, shipmentId) {
    try {
      // yield call(delay, 3000)
      const shipmentLabelsResp = yield call(api.getShipmentLabels, courier, shipmentId)

      // Did get labels?
      if (prop('ok', shipmentLabelsResp)) {

        // TODO temporary download labels
        if (courier === 'apc') {
          const consignmentNumber = path(['data', 'data', 'courier_reference'], shipmentLabelsResp) || null
          const shipmentLabels = path(['data', 'data', 'labels'], shipmentLabelsResp) || []
          let labels = []

          shipmentLabels.forEach(parcel => {
            const label = path(['Label', 'Content'], parcel) || null
            if (label) {
              labels.push(`data:image/png;charset=UTF-8;base64,${label}`)
            }
          })

          if (!isEmpty(labels)) {
            let labelsPdf = new jsPDF({
              orientation: 'p',
              unit: 'in',
              // NOTE custom format not working
              // format: [6, 4],
              format: 'a6'
            })

            labels.forEach((label, key) => {
              if (key !== 0) labelsPdf.addPage()
              labelsPdf.addImage(label, 'PNG', 0, 0, 4, 6)
            })

            labelsPdf.save(`${consignmentNumber}.pdf`)
          }
        } else if (contains(courier, ['parcelforce', 'packfleet'])) {
          const shipmentLabels = path(['data'], shipmentLabelsResp) || null
          if (shipmentLabels) {
            const file = new Blob([shipmentLabels], { type: 'application/pdf; charset=UTF-8' })
            window.open(window.URL.createObjectURL(file), '_blank', 'width=500, height=750, location=1, status=1, scrollbars=1, resizable=1, directories=1, toolbar=1, titlebar=1')
          }
        }

        const success = path(['data', 'message'], shipmentLabelsResp) || '[200] Labels downloaded.'
        yield put(Actions.getOrderShipmentLabelsSuccess(success))
      } else {
        const error = path(['data', 'message'], shipmentLabelsResp) || '[500] Failed to download labels.'
        const problem = prop('problem', shipmentLabelsResp)
        const status = prop('status', shipmentLabelsResp)
        yield put(Actions.getOrderShipmentLabelsFailure(error, problem, status))
      }
    } catch (error) {
      yield put(Actions.getOrderShipmentLabelsFailure(error))
    } finally {
      if (yield cancelled()) {
        // TODO task cancelled
        // NOTE Nothing to do here, product state cleared on LOCATION_CHANGE
        console.log('GET Order Shipment Labels task cancelled.')
      }
    }
  }

  function* getOrderShipmentLabelsFlow() {
    let lastTask
    while (true) {
      const { courier, shipmentId } = yield take(Types.GET_ORDER_SHIPMENT_LABELS_ATTEMPT)

      if (lastTask) {
        yield cancel(lastTask)
      }

      lastTask = yield fork(getOrderShipmentLabels, courier, shipmentId)
    }
  }

  // GET Order Shipment Documents
  function* getOrderShipmentDocuments(courier, shipmentId) {
    try {
      // yield call(delay, 3000)
      const shipmentDocumentsResp = yield call(api.getShipmentDocuments, courier, shipmentId)

      // Did get documents?
      if (prop('ok', shipmentDocumentsResp)) {

        // TODO temporary download documents
        if (courier === 'parcelforce') {
          const shipmentDocuments = path(['data'], shipmentDocumentsResp) || null
          if (shipmentDocuments) {
            const file = new Blob([shipmentDocuments], { type: 'application/pdf; charset=UTF-8' })
            window.open(window.URL.createObjectURL(file), '_blank', 'width=500, height=750, location=1, status=1, scrollbars=1, resizable=1, directories=1, toolbar=1, titlebar=1')
          }
        }

        // TODO should be data.data.message
        const success = path(['data', 'message'], shipmentDocumentsResp) || '[200] Documents downloaded.'
        yield put(Actions.getOrderShipmentDocumentsSuccess(success))
      } else {
        const error = path(['data', 'message'], shipmentDocumentsResp) || '[500] Failed to download documents.'
        const problem = prop('problem', shipmentDocumentsResp)
        const status = prop('status', shipmentDocumentsResp)
        yield put(Actions.getOrderShipmentDocumentsFailure(error, problem, status))
      }
    } catch (error) {
      yield put(Actions.getOrderShipmentDocumentsFailure(error))
    } finally {
      if (yield cancelled()) {
        // TODO task cancelled
        // NOTE Nothing to do here, product state cleared on LOCATION_CHANGE
        console.log('GET Order Shipment Documents task cancelled.')
      }
    }
  }

  function* getOrderShipmentDocumentsFlow() {
    let lastTask
    while (true) {
      const { courier, shipmentId } = yield take(Types.GET_ORDER_SHIPMENT_DOCUMENTS_ATTEMPT)

      if (lastTask) {
        yield cancel(lastTask)
      }

      lastTask = yield fork(getOrderShipmentDocuments, courier, shipmentId)
    }
  }

  return {
    getFlow,
    syncFlow,
    deleteFlow,
    statusChangeFlow,
    adjustOrderItemsStockFlow,
    getCourierServicesFlow,
    shipOrderFlow,
    getOrderShipmentLabelsFlow,
    getOrderShipmentDocumentsFlow,
  }
}
