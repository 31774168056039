import { path, prop, map } from 'ramda'
import {
  take, put, call, fork,
  cancel, cancelled, select,
  delay
} from 'redux-saga/effects'
import { Types, Creators as Actions } from '../actions'


export default api => {
  // GET Products
  // TODO temporary throttle/debounce
  function* watchProductsFilter() {
    yield delay(500)
    yield put(Actions.getProductsAttempt())
  }
  
  function* watchFlow() {
    let lastTask
    while (true) {
      yield take(Types.WATCH_PRODUCTS_FILTER)
      
      if (lastTask) {
        yield cancel(lastTask)
      }
      
      lastTask = yield fork(watchProductsFilter)
    }
  }
  
  function* getProducts() {
    try {
      const filters = yield select(state => state.products.filters)
      const sorting = yield select(state => state.products.sorting)
      const pageSize = yield select(state => state.products.pageSize)
      const currentPage = yield select(state => state.products.currentPage)

      let params = {}

      map((s) => {
        params[s.columnName] = s.value
        params[s.columnName + '_operator'] = s.operation
      }, filters)

      map((s) => {
        params.sort = s.columnName
        params.order = s.direction
      }, sorting)

      params.limit = pageSize
      params.page = (currentPage + 1)

      // yield call(delay, 3000)
      const productsResp = yield call(api.getProducts, params)
      
      // Did products load?
      if (prop('ok', productsResp)) {
        const products = path(['data', 'data', 'products'], productsResp) || []
        const totalCount = path(['data', 'data', 'total'], productsResp) || 0
        const success = path(['data', 'message'], productsResp) || '[200] Products loaded.'
        yield put(Actions.getProductsSuccess({
          data: products,
          totalCount: totalCount
        }, success))
      } else {
        const error = path(['data', 'message'], productsResp) || '[500] Failed to load products.'
        const problem = prop('problem', productsResp)
        const status = prop('status', productsResp)
        yield put(Actions.getProductsFailure(error, problem, status))
      }
    } catch (error) {
      yield put(Actions.getProductsFailure(error))
    } finally {
      if (yield cancelled()) {
        // TODO task cancelled
        console.log('GET Products task cancelled.')
        yield put(Actions.getProductsFailure())
      }
    }
  }
  
  function* getFlow() {
    let lastTask
    while (true) {
      yield take(Types.GET_PRODUCTS_ATTEMPT)
      
      if (lastTask) {
        yield cancel(lastTask)
      }
      
      lastTask = yield fork(getProducts)
    }
  }
  
  return {
    watchFlow,
    getFlow,
  }
}
