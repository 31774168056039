import { call, take, select, put, fork } from 'redux-saga/effects'
import { eventChannel } from 'redux-saga'
import { Types, Creators as Actions } from '../actions'
import { REHYDRATE } from 'redux-persist'
import Pusher from 'pusher-js'
import Echo from 'laravel-echo'

const getToken = state => state.auth.token


// this function creates an event channel from a given socket
// Setup subscription to incoming `ping` events
function createSocketChannel(socket) {
  // `eventChannel` takes a subscriber function
  // the subscriber function takes an `emit` argument to put messages onto the channel
  return eventChannel(emit => {
    // setup the subscription
    socket.channel('private-data')
      .subscribed(() => {
        console.log('subscribed to data channel');
      })
      .on('supplier.created', event => {
        emit({ type: 'supplier.created', payload: event })
      })
      .on('supplier.updated', event => {
        emit({ type: 'supplier.updated', payload: event })
      })

    socket.channel('private-orders')
      .subscribed(() => {
        console.log('subscribed to orders channel');
      })
      .on('orders.shipped', event => {
        emit({ type: 'orders.shipped', payload: event })
      })

    socket.channel('private-jobs')
      .subscribed(() => {
        console.log('subscribed to jobs channel');
      })
      .on('jobs.queued', event => {
        emit({ type: 'jobs.queued', payload: event })
      })
      .on('jobs.processed', event => {
        emit({ type: 'jobs.processed', payload: event })
      })
      .on('jobs.failed', event => {
        emit({ type: 'jobs.failed', payload: event })
      })

    // the subscriber must return an unsubscribe function
    // this will be invoked when the saga calls `channel.close` method
    const unsubscribe = () => {
      // TODO need better sagas for this to work
      console.log('pusher unsubscribe');
      socket.leaveChannel('private-data')
      socket.leaveChannel('private-orders')
      socket.leaveChannel('private-jobs')
    }

    return unsubscribe
  })
}


export default api => {
  function* startBroadcasting(echo) {
    const socketChannel = yield call(createSocketChannel, echo)
    while (true) {
      try {
        const { type, payload } = yield take(socketChannel)
        console.log('pusher payload:', type, payload);
        switch (type) {
          case 'supplier.created':
          case 'supplier.updated':
            yield put(Actions.addSupplier(payload.supplier))
            break
          case 'orders.shipped':
            yield put(Actions.orderShipped(payload.order_reference))
            break
          case 'jobs.queued':
            yield put(Actions.addJob(payload.job))
            break
          case 'jobs.processed':
            yield put(Actions.removeJob(payload.job_id, payload.processed_at))
            break
          case 'jobs.failed':
            yield put(Actions.addFailedJobs(payload.job_id, payload.failed_jobs))
            break
        }
      } catch(error) {
        console.error('pusher error:', error)
        // socketChannel is still open in catch block
        // if we want end the socketChannel, we need close it explicitly
        // socketChannel.close()
      }
    }
  }

  // Broadcasting
  function* broadcastingFlow() {
    let echo = null
    while (true) {
      yield take([
        REHYDRATE,
        Types.LOGIN_AUTH_SUCCESS,
        Types.LOGOUT,
      ])
      const token = yield select(getToken)

      if (token) {
        // Start broadcasting
        const pusherOptions = {
          broadcaster: 'pusher',
          key: process.env.REACT_APP_PUSHER_KEY,
          cluster: 'eu',
          forceTLS: true,
          encrypted: true,
          authEndpoint: `${process.env.REACT_APP_API_BASE_URL}/broadcasting/auth`,
          auth: {
            headers: {
              Authorization: token,
              Accept: 'application/json'
            }
          }
        }
        if (!echo) echo = new Echo(pusherOptions)

        yield fork(startBroadcasting, echo)
      } else {
        // Stop broadcasting
        console.log('stop broadcasting');
        if (echo) {
          echo.leaveChannel('private-data')
          echo.leaveChannel('private-orders')
          echo.leaveChannel('private-jobs')
          echo = null
        }
      }
    }
  }

  return {
    broadcastingFlow,
  }
}
