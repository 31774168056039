import React from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import { PersistGate } from 'redux-persist/es/integration/react'
import configureStore, { history } from './config/store'
import Index from './containers/Index'

const { persistor, store } = configureStore()
// console.log('store', store.getState())
// console.log('persistor', persistor.getState())

// if (process.env.NODE_ENV !== 'production') {
//   history.listen(location => console.log('LOCATION ::', location.pathname))
// }

const App = () => (
  <Provider store={store}>
    <PersistGate
      loading="Loading..."
      // TODO take some action before the gate lifts ???
      // onBeforeLift
      persistor={persistor}
    >
      <ConnectedRouter history={history} noInitialPop>
        <Index />
      </ConnectedRouter>
    </PersistGate>
  </Provider>
)

const rootElement = document.querySelector('#root')

if (rootElement) {
  render(<App />, rootElement)
}
