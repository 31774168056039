import moment from 'moment'
import {
  reject, propEq, head,
} from 'ramda'
import { Types } from '../actions'
import Immutable from 'seamless-immutable'
import { createReducer } from 'reduxsauce'

const INITIAL_STATE = Immutable({
  // Grid
  uiLoadingGet: false,
  jobs: [],
  failedJobs: [],
  lastJobProcessedDate: moment().format(),
  columns: [
    { name: 'id', title: 'ID' },
    { name: 'queue', title: 'Queue' },
    { name: 'name', title: 'Name' },
    { name: 'reference', title: 'Reference' },
    { name: 'description', title: 'Description' },
    { name: 'attempts', title: 'Attempts' },
    { name: 'reserved_at', title: 'Date Reserved' },
    { name: 'created_at', title: 'Date Added' },
    { name: 'available_at', title: 'Date Available' },
    { name: 'action', title: null, getCellValue: row => null },
  ],
  columnExtensions: [
    { columnName: 'id', width: 90 },
    { columnName: 'queue', width: 90 },
    { columnName: 'name', width: 160 },
    { columnName: 'action', width: 60, align: 'right' },
  ],
  visibilityColumnExtensions: [
    { columnName: 'id', togglingEnabled: false },
    { columnName: 'queue', togglingEnabled: false },
    { columnName: 'name', togglingEnabled: false },
    { columnName: 'available_at', togglingEnabled: false },
  ],
  hiddenColumnNames: [
    'attempts', 'created_at',
  ],
  sorting: [
    { columnName: 'available_at', direction: 'asc' },
  ],
  currentPage: 0,
  pageSize: 25,
  filters: [],
  failedColumns: [
    { name: 'id', title: 'ID' },
    { name: 'queue', title: 'Queue' },
    { name: 'name', title: 'Name' },
    { name: 'reference', title: 'Reference' },
    { name: 'description', title: 'Description' },
    { name: 'failed_at', title: 'Date Failed' },
    { name: 'action', title: null, getCellValue: row => row.id },
  ],
  failedColumnExtensions: [
    { columnName: 'id', width: 90 },
    { columnName: 'queue', width: 90 },
    { columnName: 'name', width: 160 },
    { columnName: 'action', width: 130, align: 'right' },
  ],
  failedSorting: [
    { columnName: 'failed_at', direction: 'asc' },
  ],
  failedFilters: [],
  failedCurrentPage: 0,
  failedPageSize: 25,
})

// GET jobs
const getJobsAttempt = state => {
  return state.merge({
    uiLoadingGet: true,
  })
}

const getJobsSuccess = (state, { data }) => {
  return state.merge({
    uiLoadingGet: false,
    jobs: data.jobs,
    failedJobs: data.failed_jobs,
    lastJobProcessedDate: data.last_job_processed_at || moment().format()
  })
}

const getJobsFailure = state => {
  return state.merge({
    uiLoadingGet: false,
  })
}

// RETRY failed job
const retryFailedJobAttempt = state => {
  return state.merge({
    uiLoadingGet: true,
  })
}

const retryFailedJobSuccess = (state, { failedJobUuid }) => {
  const jobs = reject(propEq('uuid', failedJobUuid), state.failedJobs)
  return state.merge({
    uiLoadingGet: false,
    failedJobs: jobs,
  })
}

const retryFailedJobFailure = state => {
  return state.merge({
    uiLoadingGet: false,
  })
}

// DELETE failed job
const deleteFailedJobAttempt = state => {
  return state.merge({
    uiLoadingGet: true,
  })
}

const deleteFailedJobSuccess = (state, { failedJobUuid }) => {
  const jobs = reject(propEq('uuid', failedJobUuid), state.failedJobs)
  return state.merge({
    uiLoadingGet: false,
    failedJobs: jobs,
  })
}

const deleteFailedJobFailure = state => {
  return state.merge({
    uiLoadingGet: false,
  })
}

// Broadcasting
const addJob = (state, { job }) => {
  const newJobs = reject(propEq('id', job.id), state.jobs)
  const jobs = [
    ...newJobs,
    job
  ]
  return state.merge({
    jobs: jobs,
  })
}

const removeJob = (state, { jobId, lastJobProcessedDate }) => {
  const newJobs = reject(propEq('id', jobId), state.jobs)
  return state.merge({
    jobs: newJobs,
    lastJobProcessedDate: lastJobProcessedDate || moment().format()
  })
}

const addFailedJobs = (state, { jobId, failedJobs }) => {
  const newJobs = reject(propEq('id', jobId), state.jobs)
  return state.merge({
    jobs: newJobs,
    failedJobs: failedJobs,
  })
}

const setJobsGridState = (state, { name, value }) => {
  return state.set(name, value)
}


const logout = state => INITIAL_STATE

// map our types to our handlers
const ACTION_HANDLERS = {
  // GET jobs
  [Types.GET_JOBS_ATTEMPT]: getJobsAttempt,
  [Types.GET_JOBS_SUCCESS]: getJobsSuccess,
  [Types.GET_JOBS_FAILURE]: getJobsFailure,
  // RETRY failed job
  [Types.RETRY_FAILED_JOB_ATTEMPT]: retryFailedJobAttempt,
  [Types.RETRY_FAILED_JOB_SUCCESS]: retryFailedJobSuccess,
  [Types.RETRY_FAILED_JOB_FAILURE]: retryFailedJobFailure,
  // DELETE failed job
  [Types.DELETE_FAILED_JOB_ATTEMPT]: deleteFailedJobAttempt,
  [Types.DELETE_FAILED_JOB_SUCCESS]: deleteFailedJobSuccess,
  [Types.DELETE_FAILED_JOB_FAILURE]: deleteFailedJobFailure,
  // Broadcasting
  [Types.ADD_JOB]: addJob,
  [Types.REMOVE_JOB]: removeJob,
  [Types.ADD_FAILED_JOBS]: addFailedJobs,
  [Types.SET_JOBS_GRID_STATE]: setJobsGridState,
  // Reset
  [Types.LOGOUT]: logout,
}

export default createReducer(INITIAL_STATE, ACTION_HANDLERS)
