import { fork, take, put, cancel } from 'redux-saga/effects'
import { Creators as Actions } from '../actions'


export default api => {
  // Unauthorized
  // function* watchUnauthorized({ status }) {
  //   if (status === 401) {
  //     // TODO catch 401 error for reauthenticate modal
  //     yield put(Actions.logout())
  //   }
  // }
  
  // Notify user
  // function* watchNotification({ error, success }) {
  //   if (error) {
  //     yield put(Actions.setNotification(error, 'error'))
  //   } else if (success) {
  //     yield put(Actions.setNotification(success, 'success'))
  //   }
  // }
  
  // function* watchersFlow() {
  //   yield fork(takeLatest, '*', watchUnauthorized)
  //   yield fork(takeLatest, '*', watchNotification)
  // }
  
  // TODO temp watch using take instead of takeLatest
  function* watch({ status, error, success }) {
    // Unauthorized
    if (status === 401) {
      // TODO catch 401 error for reauthenticate modal
      yield put(Actions.logout())
    }
    // Notify user
    if (error) {
      yield put(Actions.setNotification(error, 'error'))
    } else if (success) {
      yield put(Actions.setNotification(success, 'success'))
    }
  }
  
  function* watchersFlow() {
    let lastTask
    while (true) {
      const payload = yield take('*')
      
      if (lastTask) {
        yield cancel(lastTask)
      }
      
      lastTask = yield fork(watch, payload)
    }
  }
  
  return {
    watchersFlow,
  }
}
