import { take, select, put } from 'redux-saga/effects'
import { Types, Creators as Actions } from '../actions'
import { REHYDRATE } from 'redux-persist'

const getToken = state => state.auth.token
const getUser = state => state.auth.user

const getConfigLoaded = state => state.startup.configLoaded
const getUsersLoaded = state => state.startup.usersLoaded
const getOrderStatusesLoaded = state => state.startup.orderStatusesLoaded
const getSuppliersLoaded = state => state.startup.suppliersLoaded
const getCouriersLoaded = state => state.startup.couriersLoaded


export default api => {
  // Booting
  function* bootingFlow() {
    while (true) {
      yield take([
        REHYDRATE,
        Types.LOGIN_AUTH_SUCCESS,
        Types.LOGIN_USER_SUCCESS,
      ])
      let token = yield select(getToken)
      const user = yield select(getUser)
      // console.log(!!token, !!user);

      // Start booting
      const configLoaded = yield select(getConfigLoaded)
      if (!configLoaded) yield put(Actions.getConfigAttempt())

      if (token) {
        api.setAuthHeader(token)
        // console.log('SET auth header')

        // Run booting tasks
        if (user) {
          const usersLoaded = yield select(getUsersLoaded)
          if (!usersLoaded) yield put(Actions.getUsersAttempt())

          const orderStatusesLoaded = yield select(getOrderStatusesLoaded)
          if (!orderStatusesLoaded) yield put(Actions.getOrderStatusesAttempt())

          const suppliersLoaded = yield select(getSuppliersLoaded)
          if (!suppliersLoaded) yield put(Actions.getSuppliersAttempt())

          const couriersLoaded = yield select(getCouriersLoaded)
          if (!couriersLoaded) yield put(Actions.getCouriersAttempt())

          yield put(Actions.getJobsAttempt())
          yield put(Actions.getOrdersDatedDeliveryAttempt())
        }
      }
    }
  }

  return {
    bootingFlow,
  }
}
