import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { and } from 'ramda'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import { push } from 'connected-react-router'
import { Creators as Actions } from '../actions'

// Material UI
import { withStyles } from '@material-ui/core/styles'

// Components
import Loading from '../components/Loading'
import Login from '../components/Login'
import Booting from '../components/Booting'

const styles = theme => ({
  auth: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
})


class Auth extends Component {
  constructor(props) {
    super(props)

    this.navigate = this.navigate.bind(this)
  }

  state = {
    username: '',
    password: '',
  }

  navigate = to => () => {
    this.props.push(to)
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    })
  }

  handleSubmit = event => {
    event.preventDefault()
    const { loginAttempt } = this.props
    const { username, password } = this.state

    // TODO redux forms ???
    if (event.target.checkValidity()) {
      loginAttempt({ username, password })
    }
  }

  render() {
    const {
      classes,
      config,
      configError,
      uiLoading,
      isAuthenticated,
      isBooted,
      tasks,
      tasksFailed,
      logout,
    } = this.props
    const { username, password } = this.state

    if (!config.app_name || configError) {
      return (
        <Loading
          variant="absolute"
          message={configError || 'Loading...'}
        />
      )
    }

    return (
      <div className={classes.auth}>
        {!isAuthenticated && (
          <Login
            appName={config.app_name || 'IMS'}
            uiLoading={uiLoading}
            username={username}
            password={password}
            onChange={this.handleChange}
            onSubmit={this.handleSubmit}
            navigate={this.navigate}
          />
        )}
        {isAuthenticated && !isBooted && (
          <Booting
            tasks={tasks}
            tasksFailed={tasksFailed}
            onFailed={logout}
          />
        )}
      </div>
    )
  }
}

Auth.propTypes = {
  classes: PropTypes.object.isRequired,
}

const {
  loginAttempt,
  logout,
} = Actions

const mapStateToProps = ({
  startup: { booted, configError, usersLoaded, orderStatusesLoaded, suppliersLoaded, couriersLoaded, tasksFailed },
  app: { config },
  auth: { uiLoading, token, user },
}) => ({
  config,
  configError,
  uiLoading,
  isAuthenticated: and(!!token, !!user),
  isBooted: booted,
  tasks: [usersLoaded, orderStatusesLoaded, suppliersLoaded, couriersLoaded],
  tasksFailed: tasksFailed,
})

const mapDispatchToProps = dispatch => bindActionCreators({
  push,
  loginAttempt,
  logout,
}, dispatch)

export default compose(
  withStyles(styles), connect(mapStateToProps, mapDispatchToProps)
)(Auth)
