import React from 'react'
import PropTypes from 'prop-types'

// Material UI
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import IconRefresh from '@material-ui/icons/Refresh'


const RefreshButton = ({
  onClick,
}) => (
  <Tooltip
    id="tooltip-refresh"
    title="Refresh this page"
    placement="left"
  >
    <IconButton onClick={onClick}>
      <IconRefresh />
    </IconButton>
  </Tooltip>
)

RefreshButton.propTypes = {
  onClick: PropTypes.func.isRequired,
}

export default RefreshButton
