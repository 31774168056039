import { create } from 'jss'
import preset from 'jss-preset-default'
import { createMuiTheme } from '@material-ui/core/styles'
import { indigo, pink, blueGrey, green, yellow, red } from '@material-ui/core/colors'
import { createGenerateClassName } from '@material-ui/core/styles'

const colorPrimary = process.env.REACT_APP_COLOR_PRIMARY || indigo[500]
const colorSecondary = process.env.REACT_APP_COLOR_SECONDARY || pink['A400']
const drawerZindex = 998

const theme = createMuiTheme({
  drawerWidth: 240,
  typography: {
    useNextVariants: true,
    htmlFontSize: 20,
  },
  palette: {
    type: 'dark',
    primary: {
      main: colorPrimary,
    },
    secondary: {
      main: colorSecondary,
    },
    error: {
      main: red['A400'],
    },
    background: {
      default: blueGrey[800],
      paper: blueGrey[900],
    },
    common: {
      black: blueGrey[900],
    },
  },
  zIndex: {
    drawer: drawerZindex,
  },
  overrides: {
    MuiAppBar: {
      root: {
        zIndex: 999,
      },
      positionStatic: {
        boxShadow: 'none',
      },
    },
    MuiDrawer: {
      modal: {
        zIndex: drawerZindex,
      }
    },
    MuiCollapse: {
      container: {
        outline: 'none',
      }
    },
    MuiListItem: {
      root: {
        '&$disabled': {
          opacity: 1,
        }
      }
    },
    MuiCircularProgress: {
      colorPrimary: {
        color: 'white',
      },
    },
    MuiIconButton: {
      sizeSmall: {
        padding: 6
      },
    },
    MuiSvgIcon: {
      colorPrimary: {
        color: green['A400']
      },
      colorDisabled: {
        color: yellow['A400']
      },
    },
    MuiChip: {
      root: {
        backgroundColor: colorPrimary,
      },
    },
    MuiTooltip: {
      tooltip: {
        backgroundColor: colorPrimary,
      }
    },
    MuiTable: {
      root: {
        borderCollapse: 'separate',
      }
    },
    MuiTableRow: {
      hover: {
        '&:hover': {
          backgroundColor: 'rgba(255, 255, 255, .05)',
        },
      },
    },
    MuiTableCell: {
      root: {
        borderBottomColor: 'rgba(255, 255, 255, .12)'
      },
      head: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
      },
    },
    TableDetailCell: {
      active: {
        backgroundColor: 'rgba(0, 0, 0, 0.2)',
      },
    },
    MuiCheckbox: {
      colorSecondary: {
        '&$checked': {
          color: 'white',
        },
      },
    },
    MuiRadio: {
      colorSecondary: {
        '&$checked': {
          color: 'white',
        },
      },
    },
  },
})

// Configure JSS
let jss = create(preset())
jss.options.createGenerateClassName = createGenerateClassName

export default function createContext() {
  if (process.env.NODE_ENV !== 'production') {
    console.log('THEME', theme)
  }

  return {
    jss,
    theme,
  }
}
