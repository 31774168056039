import React from 'react'
import PropTypes from 'prop-types'

import {
  compose, toLower, prop, pipe, pluck, flatten, reject, sortWith, ascend, sum,
  pick, map, groupBy, values, reduce, isEmpty
} from 'ramda'
import { encode } from 'html-entities'

// Material UI
import { withStyles } from '@material-ui/core/styles'
import { red } from '@material-ui/core/colors'
import Grid from '@material-ui/core/Grid'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'

const highlight = string => {
  const canHighlight = !!string.match(/\(([^)]+)\)/)
  const stringHtmlEntities = encode(string)

  if (canHighlight) {
    return stringHtmlEntities.replaceAll('(', `<span style="color: ${red['A400']}">(`).replaceAll(')', ')</span>')
  }

  return stringHtmlEntities
}

const styles = theme => ({
  container: {
    pageBreakAfter: 'always',
    padding: theme.spacing(2),
    margin: 0,
    width: '100%',
  },
  cell: {
    fontSize: 10,
  },
  cellLast: {
    paddingTop: theme.spacing(),
    paddingRight: 0,
    paddingBottom: theme.spacing(),
    paddingLeft: 0,
  },
  cellInfo: {
    fontSize: 8,
  },
  noWrap: {
    whiteSpace: 'nowrap',
  },
  bold: {
    fontWeight: 700,
  },
  error: {
    color: theme.palette.error.main,
  },
})


const PrintOrderSummary = ({
  classes,
  orders,
}) => {
  // Group all products and sum allocated
  const sortedItems = pipe(
    pluck('order_items'),
    flatten,
    map(pick([
      'sku', 'description', 'supplier', 'location', 'items_in_stock',
      'items_reserved', 'items_allocated', 'allocated'
    ])),
    groupBy(prop('sku')),
    values,
    map(reduce(
      (acc, entry) => {
        return {
          ...entry,
          allocated: (acc.allocated || 0) + entry.allocated
        }
      },
      {}
    )),
    sortWith([
      ascend(prop('supplier')),
      ascend(compose(toLower, prop('description')))
    ]),
    reject(item => item.allocated === 0)
  )(orders)
  const totalToPick = pipe(
    pluck('allocated'),
    sum
  )(sortedItems)
  // items.map(item => {
  //   console.log(item.product_id, item.allocated, item.supplier, item.description);
  // })

  if (isEmpty(sortedItems)) return null

  return (
    <Grid className={classes.container} container spacing={2}>
      <Grid item xs={12}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell classes={{ root: classes.cell }} className={classes.noWrap} padding="none">
                {'SKU'}
              </TableCell>
              <TableCell classes={{ root: classes.cell }} className={classes.noWrap} padding="none">
                {'Description'}
              </TableCell>
              {
              // <TableCell classes={{ root: classes.cell }} className={classes.noWrap} padding="none">
              //   {'Location'}
              // </TableCell>
              }
              <TableCell classes={{ root: classes.cell }} className={classes.noWrap} padding="none">
                {'Supplier'}
              </TableCell>
              <TableCell classes={{ root: classes.cell }} className={classes.noWrap} padding="none" align="right">
                {'Warehouse'}
              </TableCell>
              <TableCell classes={{ root: classes.cell }} className={`${classes.noWrap} ${classes.error}`} padding="none" align="right">
                {`To pick (${totalToPick})`}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedItems.map(item => (
              <TableRow key={item.sku}>
                <TableCell classes={{ root: classes.cell }} className={classes.noWrap} padding="none">
                  {item.sku}
                </TableCell>
                <TableCell
                  classes={{ root: classes.cell }}
                  padding="none"
                  dangerouslySetInnerHTML={{ __html: highlight(item.description) }}
                />
                {
                // <TableCell classes={{ root: classes.cell }} className={classes.noWrap} padding="none">
                //   {item.location}
                // </TableCell>
                }
                <TableCell classes={{ root: classes.cell }} className={classes.noWrap} padding="none">
                  {item.supplier}
                  {item.location && (
                    <div className={classes.cellInfo}><i className={classes.error}>({item.location})</i></div>
                  )}
                </TableCell>
                <TableCell classes={{ root: classes.cell }} className={classes.cellLast} align="right">
                  {sum([item.items_in_stock, item.items_allocated, item.items_reserved])}
                </TableCell>
                <TableCell classes={{ root: classes.cell }} className={`${classes.error} ${classes.bold} ${classes.cellLast}`} align="right">
                  {item.allocated}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Grid>
    </Grid>
  )
}

PrintOrderSummary.propTypes = {
  classes: PropTypes.object.isRequired,
  orders: PropTypes.array.isRequired,
}

export default withStyles(styles)(PrintOrderSummary)
