import { path } from 'ramda'
import { Types } from '../actions'
import Immutable from 'seamless-immutable'
import { createReducer } from 'reduxsauce'
import moment from 'moment'

const INITIAL_STATE = Immutable({
  uiLoading: false,
  token: null,
  googleToken: null,
  googleTokenExpires: null,
  user: null,
  error: null,
})

// AUTH
const loginAttempt = state => {
  const newState = Immutable(state)
  return newState.merge({
    uiLoading: true,
  })
}

const loginAuthSuccess = (state, { auth }) => {
  const token = `${path(['token_type'], auth)} ${path(['access_token'], auth)}`
  return state.merge({
    token: token,
  })
}

const loginUserSuccess = (state, { user }) => {
  return state.merge({
    uiLoading: false,
    user: user,
  })
}

const loginFailure = state => {
  return state.merge({
    uiLoading: false,
  })
}

const forgotPasswordAttempt = state => {
  const newState = Immutable(state)
  return newState.merge({
    uiLoading: true,
    error: INITIAL_STATE.error,
  })
}

const forgotPasswordSuccess = state => {
  return state.merge({
    uiLoading: false,
  })
}

const forgotPasswordFailure = (state, { error }) => {
  return state.merge({
    uiLoading: false,
    error: error,
  })
}

const resetPasswordAttempt = state => {
  const newState = Immutable(state)
  return newState.merge({
    uiLoading: true,
    error: INITIAL_STATE.error,
  })
}

const resetPasswordSuccess = state => {
  return state.merge({
    uiLoading: false,
  })
}

const resetPasswordFailure = (state, { error }) => {
  return state.merge({
    uiLoading: false,
    error: error,
  })
}

const setGoogleToken = (state, { token })  => {
  const newState = Immutable(state)
  return newState.merge({
    googleToken: token,
    googleTokenExpires: moment().add(1, 'hours').format(),
  })
}

const logout = state => INITIAL_STATE

// map our types to our handlers
const ACTION_HANDLERS = {
  // AUTH
  [Types.LOGIN_ATTEMPT]: loginAttempt,
  [Types.LOGIN_AUTH_SUCCESS]: loginAuthSuccess,
  [Types.LOGIN_USER_SUCCESS]: loginUserSuccess,
  [Types.LOGIN_FAILURE]: loginFailure,
  [Types.FORGOT_PASSWORD_ATTEMPT]: forgotPasswordAttempt,
  [Types.FORGOT_PASSWORD_SUCCESS]: forgotPasswordSuccess,
  [Types.FORGOT_PASSWORD_FAILURE]: forgotPasswordFailure,
  [Types.RESET_PASSWORD_ATTEMPT]: resetPasswordAttempt,
  [Types.RESET_PASSWORD_SUCCESS]: resetPasswordSuccess,
  [Types.RESET_PASSWORD_FAILURE]: resetPasswordFailure,
  [Types.SET_GOOGLE_TOKEN]: setGoogleToken,
  // Reset
  [Types.LOGOUT]: logout,
}

export default createReducer(INITIAL_STATE, ACTION_HANDLERS)
