// import {
//
// } from 'ramda'
import { Types } from '../actions'
import Immutable from 'seamless-immutable'
import { createReducer } from 'reduxsauce'

const INITIAL_STATE = Immutable({
  // Grid
  uiLoadingGet: false,
  data: [],
  columns: [
    { name: 'received', title: 'Received', getCellValue: row => row.received ? 'Yes' : 'No' },
    { name: 'supplier', title: 'Supplier' },
    { name: 'total_products', title: 'Total Products' },
    { name: 'total_items', title: 'Total Quantity' },
    {
      name: 'total_cost_ex_vat',
      title: 'Total Cost (ex VAT)',
      getCellValue: row => Number(row.invoice_total_cost_ex_vat) ? row.invoice_total_cost_ex_vat : row.total_cost_ex_vat
    },
    {
      name: 'audited',
      title: 'Invoice Cost (ex VAT)',
      getCellValue: row => row.invoice_cost_ex_vat
    },
    { name: 'total_orders', title: 'Total Orders' },
    { name: 'expected_at', title: 'Date Expected' },
    { name: 'created_at', title: 'Date Added' },
    { name: 'created_by', title: 'Added By' },
    { name: 'updated_at', title: 'Date Modified' },
    { name: 'updated_by', title: 'Modified By' },
    { name: 'action', title: null, getCellValue: row => row.id },
  ],
  columnExtensions: [
    { columnName: 'received', width: 105 },
    { columnName: 'total_cost_ex_vat', width: 145 },
    { columnName: 'audited', width: 155 },
    { columnName: 'action', width: 110, align: 'right' },
  ],
  visibilityColumnExtensions: [
    { columnName: 'received', togglingEnabled: false },
    { columnName: 'supplier', togglingEnabled: false },
    { columnName: 'action', togglingEnabled: false },
  ],
  hiddenColumnNames: [
    'audited', 'created_by', 'updated_at', 'updated_by',
  ],
  sorting: [
    { columnName: 'created_at', direction: 'desc' },
  ],
  selection: [],
  filters: [],
  currentPage: 0,
  totalCount: 0,
  pageSizes: [10, 25, 50],
  pageSize: 10,
})

// GET Deliveries
const watchDeliveriesFilter = (state, { params }) => {
  return state.merge({
    ...params
  })
}

const getDeliveriesAttempt = (state, { params }) => {
  return state.merge({
    uiLoadingGet: true,
    ...params,
    selection: []
  })
}

const getDeliveriesSuccess = (state, { data }) => {
  return state.merge({
    uiLoadingGet: false,
    ...data
  })
}

const getDeliveriesFailure = state => {
  return state.merge({
    uiLoadingGet: false,
  })
}

const setDeliveriesGridState = (state, { name, value }) => {
  return state.set(name, value)
}

const logout = state => INITIAL_STATE

// map our types to our handlers
const ACTION_HANDLERS = {
  // GET Deliveries
  [Types.WATCH_DELIVERIES_FILTER]: watchDeliveriesFilter,
  [Types.GET_DELIVERIES_ATTEMPT]: getDeliveriesAttempt,
  [Types.GET_DELIVERIES_SUCCESS]: getDeliveriesSuccess,
  [Types.GET_DELIVERIES_FAILURE]: getDeliveriesFailure,
  [Types.SET_DELIVERIES_GRID_STATE]: setDeliveriesGridState,
  // Reset
  [Types.LOGOUT]: logout,
}

export default createReducer(INITIAL_STATE, ACTION_HANDLERS)
