import React from 'react'
import PropTypes from 'prop-types'

import { formatDate } from '../util/helpers'

// React Grid
import {
  DataTypeProvider,
} from '@devexpress/dx-react-grid'

const GridDateTypeProvider = ({ columns, format }) => (
  <DataTypeProvider
    formatterComponent={({ value }) => formatDate(value, format)}
    for={columns}
  />
)

GridDateTypeProvider.propTypes = {
  columns: PropTypes.array.isRequired,
}

export default GridDateTypeProvider
