import React from 'react'
import PropTypes from 'prop-types'

import { path, equals } from 'ramda'

// Material UI
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import IconKeyboardBackspace from '@material-ui/icons/KeyboardBackspace'


const GoBackButton = ({
  onBack,
  history,
}) => {
  const referer = path(['location', 'state', 'from'], history)
  
  // No go back on page refresh
  if (equals(referer, '/login')) {
    return null
  }
  
  return (
    <Tooltip
      title="Go back"
      placement="left"
    >
      <IconButton onClick={onBack}>
        <IconKeyboardBackspace />
      </IconButton>
    </Tooltip>
  )
}

GoBackButton.propTypes = {
  onBack: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
}

export default GoBackButton
