import {
  map, assoc,
  curry, when, propEq,
} from 'ramda'
import { Types } from '../actions'
import Immutable from 'seamless-immutable'
import { createReducer } from 'reduxsauce'

const INITIAL_STATE = Immutable({
  // Grid
  uiLoadingGet: false,
  data: [],
  columns: [
    {
      name: 'completed',
      title: 'Completed',
      getCellValue: row => Math.round((row.items_fulfilled / row.items_ordered) * 100),
    },
    { name: 'order_reference', title: 'Order No.' },
    { name: 'order_date', title: 'Order Date' },
    { name: 'customer_billing_contact', title: 'Customer' },
    { name: 'customer_delivery_contact', title: 'Consignee' },
    { name: 'status', title: 'Status' },
    { name: 'customer_delivery_city', title: 'City' },
    { name: 'customer_delivery_country', title: 'Country' },
    { name: 'customer_delivery_postcode', title: 'Postcode' },
    { name: 'action', title: 'View', getCellValue: row => row.order_reference },
  ],
  columnExtensions: [
    { columnName: 'completed', width: 125 },
    { columnName: 'action', width: 70, align: 'right' },
  ],
  visibilityColumnExtensions: [
    { columnName: 'order_reference', togglingEnabled: false },
    { columnName: 'customer_delivery_contact', togglingEnabled: false },
    { columnName: 'action', togglingEnabled: false },
  ],
  hiddenColumnNames: [
    'customer_billing_contact', 'customer_delivery_country'
  ],
  sorting: [
    { columnName: 'order_date', direction: 'desc' },
  ],
  selection: [],
  filters: [],
  currentPage: 0,
  totalCount: 0,
  pageSizes: [10, 25, 50],
  pageSize: 10,
  // Order Items Grid
  orderItemsColumns: [
    { name: 'sku', title: 'SKU Code' },
    { name: 'description', title: 'Description' },
    // { name: 'location', title: 'Location' },
    { name: 'supplier', title: 'Supplier' },
    { name: 'cost_ex_vat', title: 'Cost (ex VAT)' },
    { name: 'ordered', title: 'Ordered Qty' },
    { name: 'items_in_stock', title: 'Available Qty' },
    { name: 'notified', title: 'Notified Qty' },
    {
      name: 'allocated',
      title: 'Allocated Qty',
      getCellValue: row => `${row.allocated} (${row.ordered - row.allocated - row.shipped})`
    },
    { name: 'shipped', title: 'Shipped Qty' },
    { name: 'action', title: 'View', getCellValue: row => row.sku },
  ],
  orderItemsColumnExtensions: [
    { columnName: 'sku', width: 110 },
    { columnName: 'cost_ex_vat', width: 115 },
    { columnName: 'ordered', width: 105 },
    { columnName: 'items_in_stock', width: 110 },
    { columnName: 'notified', width: 110 },
    { columnName: 'allocated', width: 115 },
    { columnName: 'shipped', width: 110 },
    { columnName: 'action', width: 110, align: 'right' },
  ],
  orderItemsSorting: [],
  // Change Status
  uiLoadingChangeStatus: false,
  statusChanges: [],
})

// GET Orders
const watchOrdersFilter = (state, { params }) => {
  return state.merge({
    ...params
  })
}

const getOrdersAttempt = (state, { params }) => {
  return state.merge({
    uiLoadingGet: true,
    ...params,
    selection: []
  })
}

const getOrdersSuccess = (state, { data }) => {
  return state.merge({
    uiLoadingGet: false,
    ...data
  })
}

const getOrdersFailure = state => {
  return state.merge({
    uiLoadingGet: false,
  })
}

const setOrdersGridState = (state, { name, value }) => {
  return state.set(name, value)
}

// POST Change Orders Status
const changeOrdersStatusAttempt = (state, { orders }) => {
  const statusChanges = map(
    orderReference => ({
      orderReference,
      statusChanged: false,
    }),
    orders
  )

  return state.merge({
    uiLoadingChangeStatus: true,
    statusChanges: statusChanges,
  })
}

const changeOrderStatusResult = (state, { orderReference, orderStatusId, statusChanged }) => {
  // Alter status changed true/false
  const alterChanged = curry((orderReference, statusChanged) => map(
    when(
      propEq('orderReference', orderReference),
      assoc('statusChanged', statusChanged)
    ),
    state.statusChanges
  ))

  // Alter orders list with new status
  let alteredData = state.data
  if (statusChanged) {
    const alterData = curry((orderReference, orderStatusId) => map(
      when(
        propEq('order_reference', orderReference),
        assoc('order_status_id', orderStatusId),
      ),
      state.data
    ))

    alteredData = alterData(orderReference, orderStatusId)
  }

  return state.merge({
    statusChanges: alterChanged(orderReference, statusChanged),
    data: alteredData,
  })
}

const changeOrdersStatusSuccess = state => {
  return state.merge({
    uiLoadingChangeStatus: false,
    selection: INITIAL_STATE.selection,
  })
}

const changeOrdersStatusFailure = state => {
  return state.merge({
    uiLoadingChangeStatus: false,
    selection: INITIAL_STATE.selection,
    statusChanges: INITIAL_STATE.statusChanges,
  })
}

const logout = state => INITIAL_STATE

// map our types to our handlers
const ACTION_HANDLERS = {
  // GET Orders
  [Types.WATCH_ORDERS_FILTER]: watchOrdersFilter,
  [Types.GET_ORDERS_ATTEMPT]: getOrdersAttempt,
  [Types.GET_ORDERS_SUCCESS]: getOrdersSuccess,
  [Types.GET_ORDERS_FAILURE]: getOrdersFailure,
  [Types.SET_ORDERS_GRID_STATE]: setOrdersGridState,
  // POST Change Orders Status
  [Types.CHANGE_ORDERS_STATUS_ATTEMPT]: changeOrdersStatusAttempt,
  [Types.CHANGE_ORDER_STATUS_RESULT]: changeOrderStatusResult,
  [Types.CHANGE_ORDERS_STATUS_SUCCESS]: changeOrdersStatusSuccess,
  [Types.CHANGE_ORDERS_STATUS_FAILURE]: changeOrdersStatusFailure,
  [Types.CHANGE_ORDERS_STATUS_TERMINATE]: changeOrdersStatusFailure,
  // Reset
  [Types.LOGOUT]: logout,
}

export default createReducer(INITIAL_STATE, ACTION_HANDLERS)
