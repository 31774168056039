import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import loadScript from 'load-script'
import moment from 'moment'
import * as pdfjs from 'pdfjs-dist/legacy/build/pdf.js'
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry'
import { head, values } from 'ramda'

// Material UI
import IconButton from '@material-ui/core/IconButton'
import FolderIcon from '@material-ui/icons/Folder'

const GOOGLE_API_URL = '//apis.google.com/js/api.js'
const GOOGLE_CLIENT_URL = '//accounts.google.com/gsi/client'

let scriptLoadingStarted = false;

let googleClient = null

// TODO refresh token, only possible with backend...

pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker

function Pdf2TextClass() {
  const self = this
  this.complete = 0

  this.extractProducts = function (data, callbackAllDone) {
    console.assert(data instanceof ArrayBuffer || typeof data === 'string')

    const loadingTask = pdfjs.getDocument(data)

    loadingTask.promise.then(function (pdf) {
      const totalPages = pdf._pdfInfo.numPages
      console.log('Total pages:', totalPages)
      let pages = {}

      for (let i = 1; i <= totalPages; i++) {
        pdf.getPage(i).then(function (page) {
          const pageNumber = page.pageNumber
          page.getTextContent().then(function (textContent) {
            if (textContent.items !== null) {
              let blocks = []

              for (let b = 0; b < textContent.items.length; b++) {
                const row = textContent.items[b]
                const string = row.str

                if (string) {
                  blocks.push(string.trim())
                }
              }

              textContent !== null && console.log(`Page finished: ${pageNumber}`)
              pages[pageNumber] = blocks
            }
            ++self.complete;

            if (self.complete === totalPages) {
              const pdf = values(pages)
              console.log('pdf', pdf);

              callbackAllDone(pdf)
            }
          })
        })
      }
    })
  }
}

class GoogleDrivePicker extends PureComponent {
  state = {
    triggerPicker: false
  }

  componentDidMount() {
    if (this.isGoogleClientReady() && this.isGooglePickerReady()) {
      // init immediately
      this.onApiLoad()
      this.onClientLoad()
    } else if (!scriptLoadingStarted) {
      // load google apis and client
      scriptLoadingStarted = true;
      loadScript(GOOGLE_API_URL, this.onApiLoad)
      loadScript(GOOGLE_CLIENT_URL, this.onClientLoad)
    } else {
      // is loading
    }

    // this.handlePickerChange()
  }

  isGoogleClientReady = () => {
    return !!(window.google && window.google.accounts)
  }

  isGooglePickerReady = () => {
    return !!(window.google && window.google.picker)
  }

  hasToken = () => {
    return this.props.accessToken && !moment().isAfter(this.props.tokenExpires)
  }

  onApiLoad = () => {
    // console.log('onApiLoad', window.gapi, window.gapi.client);
    window.gapi.load('picker')
    this.forceUpdate()
  }

  onClientLoad = () => {
    // console.log('onClientLoad', window.google);
    const {
      clientId,
      apiKey,
      scope,
      onAuth,
      onAuthFailed
    } = this.props

    googleClient = window.google.accounts.oauth2.initTokenClient({
      client_id: clientId,
      api_key: apiKey,
      scope: scope,
      callback: (response) => {
        if (response.access_token) {
          onAuth(response)
          if (this.state.triggerPicker) {
            this.setState({
              triggerPicker: false
            }, () => {
              this.createPicker(response.access_token)
            })
          }
        } else {
          onAuthFailed(response)
        }
      }
    })

    this.forceUpdate()
  }

  requestAuth = () => {
    googleClient.requestAccessToken()
  }

  openDrive = () => {
    if (!this.isGoogleClientReady() || !this.isGooglePickerReady()) {
      return
    }

    if (this.hasToken()) {
      this.createPicker(this.props.accessToken)
    } else {
      // trigger picker after auth
      this.setState({
        triggerPicker: true
      }, () => {
        this.requestAuth()
      })
    }
  }

  handlePickerChange = data => {
    const { action, docs } = data

    if (action === 'picked' && docs) {
    // if (true) {
      const doc = head(docs)
      const invoice = {
        google_drive_id: doc.id,
        mime_type: doc.mimeType,
        modified_at: moment(doc.lastEditedUtc).utc().format(),
        filename: doc.name,
        url: doc.url
      }
      // const invoice = {
      //   google_drive_id: '1S76XNMiJ-pCD65oGOsMqQGYzDe5AHowu',
      //   mime_type: 'application/pdf',
      //   modified_at: 1654436206000,
      //   filename: 'Sales-Invoice SN7418026.pdf',
      //   url: 'https://drive.google.com/file/d/1S76XNMiJ-pCD65oGOsMqQGYzDe5AHowu/view?usp=drive_web'
      // }

      const url = `https://www.googleapis.com/drive/v3/files/${invoice.google_drive_id}?alt=media`
      const setHeaders = new Headers();
      setHeaders.append('Authorization', 'Bearer ' + this.props.accessToken);
      setHeaders.append('Content-Type', invoice.mime_type);

      const setOptions = {
        method: 'GET',
        headers: setHeaders
      }

      fetch(url, setOptions)
        .then(async response => {
          if (response.ok) {
            const blob = await response.blob()
            return blob
          } else {
            throw new Error('Can\'t download file.')
          }
        })
        .then(blob => {
          const url = URL.createObjectURL(blob)
          const pdf = new Pdf2TextClass()

          pdf.extractProducts(url, pdf => {
            this.props.onChange(invoice, pdf)
          })
        })
        .catch(error => {
          alert(error.message)
        })
    }
  }

  createPicker = accessToken => {
    const googleViewId = window.google.picker.ViewId[this.props.viewId]
    const view = new window.google.picker.DocsView(googleViewId)
    view.setMode(window.google.picker.DocsViewMode.LIST)
    view.setEnableDrives(true)
    view.setIncludeFolders(true)

    if (this.props.parentId) {
      view.setParent(this.props.parentId)
    }

    if (this.props.mimeTypes) {
      view.setMimeTypes(this.props.mimeTypes.join(','))
    }

    if (this.props.query) {
      view.setQuery(this.props.query)
    }

    if (!view) {
      throw new Error('Can\'t find view by viewId.')
    }

    const picker = new window.google.picker.PickerBuilder()
      .setDeveloperKey(this.props.apiKey)
      .setOAuthToken(accessToken)
      .addView(view)
      .setCallback(this.handlePickerChange)

    picker.enableFeature(window.google.picker.Feature.SUPPORT_DRIVES)

    if (this.props.navHidden) {
      picker.enableFeature(window.google.picker.Feature.NAV_HIDDEN)
    }

    if (this.props.multiselect) {
      picker.enableFeature(window.google.picker.Feature.MULTISELECT_ENABLED)
    }

    picker.build().setVisible(true)
  }

  render() {
    return (
      <IconButton
        onClick={this.openDrive}
      >
        <FolderIcon color="action" />
      </IconButton>
    )
  }
}

GoogleDrivePicker.propTypes = {
  apiKey: PropTypes.string.isRequired,
  clientId: PropTypes.string.isRequired,
  accessToken: PropTypes.string,
  tokenExpires: PropTypes.string,
  scope: PropTypes.string,
  viewId: PropTypes.string,
  parentId: PropTypes.string,
  query: PropTypes.string,
  mimeTypes: PropTypes.array,
  multiselect: PropTypes.bool,
  navHidden: PropTypes.bool,
  onAuth: PropTypes.func,
  onAuthFailed: PropTypes.func,
  onChange: PropTypes.func
}

GoogleDrivePicker.defaultProps = {
  accessToken: null,
  tokenExpires: moment().format(),
  scope: 'https://www.googleapis.com/auth/drive.readonly',
  viewId: 'DOCS',
  parentId: '',
  query: '',
  mimeTypes: ['application/pdf'],
  multiselect: false,
  navHidden: false,
  onAuth: () => {},
  onAuthFailed: () => {},
  onChange: () => {}
}

export default GoogleDrivePicker
