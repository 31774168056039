import { map, sortBy, prop, propEq, reject } from 'ramda'
import { Types } from '../actions'
import Immutable from 'seamless-immutable'
import { createReducer } from 'reduxsauce'

const INITIAL_STATE = Immutable({
  config: {},
  users: [],
  orderStatuses: [],
  suppliers: [],
  couriers: [],
})

// GET Config
const getConfigSuccess = (state, { data }) => {
  const newState = Immutable(state)
  return newState.merge({
    config: data,
  })
}

// GET Users
const getUsersSuccess = (state, { data }) => {
  const newState = Immutable(state)
  return newState.merge({
    users: data,
  })
}

// GET Order Statuses
const getOrderStatusesSuccess = (state, { data }) => {
  const newState = Immutable(state)
  return newState.merge({
    orderStatuses: data,
  })
}

// GET/PUT Suppliers
const getSuppliersSuccess = (state, { data }) => {
  const newState = Immutable(state)
  return newState.merge({
    suppliers: sortBy(prop('name'), data),
  })
}

const updateSupplierSuccess = (state, { data }) => {
  const updatedData = map(
    supplier => (
      data.id === supplier.id ? { ...supplier, ...data } : supplier
    ),
    state.suppliers,
  )
  return state.merge({
    suppliers: updatedData,
  })
}

const addSupplier = (state, { supplier }) => {
  const newState = Immutable(state)
  const suppliers = reject(propEq('id', supplier.id), newState.suppliers)
  return newState.merge({
    suppliers: sortBy(prop('name'), [
      ...suppliers,
      supplier
    ]),
  })
}

// GET Couriers
const getCouriersSuccess = (state, { data }) => {
  const newState = Immutable(state)
  return newState.merge({
    couriers: sortBy(prop('name'), data),
  })
}

const logout = state => {
  const newState = Immutable(state)
  return newState.merge({
    ...INITIAL_STATE,
    config: state.config,
  })
}

// map our types to our handlers
const ACTION_HANDLERS = {
  // GET Config
  [Types.GET_CONFIG_SUCCESS]: getConfigSuccess,
  // GET Users
  [Types.GET_USERS_SUCCESS]: getUsersSuccess,
  // GET Order Statuses
  [Types.GET_ORDER_STATUSES_SUCCESS]: getOrderStatusesSuccess,
  // GET/PUT Suppliers
  [Types.GET_SUPPLIERS_SUCCESS]: getSuppliersSuccess,
  [Types.UPDATE_SUPPLIER_SUCCESS]: updateSupplierSuccess,
  [Types.ADD_SUPPLIER]: addSupplier,
  // GET Couriers
  [Types.GET_COURIERS_SUCCESS]: getCouriersSuccess,
  // Reset
  [Types.LOGOUT]: logout,
}

export default createReducer(INITIAL_STATE, ACTION_HANDLERS)
