import {
  prop, propOr, map, head, join, toLower, reject, isNil, isEmpty,
  pipe, sum, length, toString, path, includes, contains, equals, replace,
} from 'ramda'
import { Types } from '../actions'
import Immutable from 'seamless-immutable'
import { createReducer } from 'reduxsauce'

const INITIAL_STATE = Immutable({
  uiLoadingGet: false,
  uiLoadingChangeStatus: false,
  uiLoadingAdjust: false,
  uiLoadingCourier: false,
  uiLoadingShip: false,
  uiLoadingLabels: false,
  uiLoadingSync: false,
  uiLoadingDelete: false,
  data: {},
  courierServices: [],
  courierCollections: [],
  error: '',
  errors: {},
  // Order Reserved Grid
  orderReservedColumns: [
    { name: 'sku', title: 'SKU Code' },
    { name: 'description', title: 'Description' },
    { name: 'supplier', title: 'Supplier' },
    { name: 'cost_ex_vat', title: 'Cost (ex VAT)' },
    { name: 'reserved', title: 'Reserved Qty' },
    { name: 'action', title: 'View', getCellValue: row => row.sku },
  ],
  // Order Items Grid
  orderItems: [],
  orderItemsColumns: [
    { name: 'sku', title: 'SKU Code' },
    { name: 'description', title: 'Description' },
    // { name: 'location', title: 'Location' },
    { name: 'supplier', title: 'Supplier' },
    { name: 'cost_ex_vat', title: 'Cost (ex VAT)' },
    { name: 'ordered', title: 'Ordered Qty' },
    { name: 'items_in_stock', title: 'Available Qty' },
    { name: 'notified', title: 'Notified Qty' },
    {
      name: 'allocated',
      title: 'Allocated Qty',
      getCellValue: row => `${row.allocated} (${row.ordered - row.allocated - row.shipped})`
    },
    { name: 'shipped', title: 'Shipped Qty' },
    { name: 'action', title: 'View', getCellValue: row => row.sku },
  ],
  orderItemsSorting: [],
  orderItemsSelection: [],
  orderItemsColumnExtensions: [
    { columnName: 'sku', width: 110 },
    { columnName: 'cost_ex_vat', width: 115 },
    { columnName: 'ordered', width: 105 },
    { columnName: 'items_in_stock', width: 110 },
    { columnName: 'notified', width: 110 },
    { columnName: 'allocated', width: 115 },
    { columnName: 'shipped', width: 110 },
    { columnName: 'reserved', width: 110 },
    { columnName: 'action', width: 110, align: 'right' },
  ],
  // Shipments Grid
  orderShipments: [],
  orderShipmentsColumns: [
    { name: 'delivered_at', title: 'Delivered', getCellValue: row => row.delivered_at ? 'Yes' : 'No' },
    { name: 'courier_reference', title: 'Courier Ref.' },
    { name: 'created_at', title: 'Date Added' },
    { name: 'courier', title: 'Courier' },
    {
      name: 'parcels',
      title: 'Parcels',
      getCellValue: row => {
        const parcels = pipe(prop('shipment_items'), length)(row)
        const weight = pipe(prop('shipment_items'), map(prop('weight')), sum)(row)
        return `${parcels} (${weight}kg)`
      },
    },
    {
      name: 'tracking',
      title: 'Tracking',
      getCellValue: row => join('\n', row.tracking)
    },
    // TODO need to build a third party app for Mac/Win for sending raw data directly to thermal printer
    {
      name: 'labels',
      title: 'Labels',
      getCellValue: row => includes(row.courier_id, ['parcelforce', 'packfleet']) ? true : false
    },
    {
      name: 'documents',
      title: 'Docs',
      getCellValue: row => includes(row.courier_id, ['parcelforce']) ? true : false
    },
    { name: 'action', title: 'View', getCellValue: row => row.id },
  ],
  orderShipmentsColumnExtensions: [
    { columnName: 'delivered_at', width: 100 },
    { columnName: 'labels', width: 70, align: 'center' },
    { columnName: 'documents', width: 70, align: 'center' },
    { columnName: 'action', width: 70, align: 'right' },
  ],
  // History Grid
  orderHistory: [],
  orderHistoryColumns: [
    { name: 'created_at', title: 'Date Added' },
    { name: 'title', title: 'Status' },
    { name: 'comment', title: 'Comment' },
    { name: 'created_by', title: 'Added By' },
  ],
  orderHistoryPageSize: 10,
})

// GET Order
const getOrderAttempt = state => {
  return state.merge({
    uiLoadingGet: true,
  })
}

const getOrderSuccess = (state, { data, shipments, history }) => {
  return state.merge({
    uiLoadingGet: false,
    data: data,
    orderItems: propOr([], 'order_items', data),
    orderShipments: shipments,
    orderHistory: history,
  })
}

const getOrderFailure = (state, { error }) => {
  return state.merge({
    uiLoadingGet: false,
    error: error,
  })
}

const setOrderGridState = (state, { name, value }) => {
  return state.set(name, value)
}

// POST Change Order Status
const changeOrderStatusAttempt = state => {
  return state.merge({
    uiLoadingChangeStatus: true,
    error: INITIAL_STATE.error,
    errors: INITIAL_STATE.errors,
  })
}

const changeOrderStatusSuccess = (state, { orderStatusId, history }) => {
  const newState = Immutable.setIn(state, ['data', 'order_status_id'], orderStatusId)
  return newState.merge({
    uiLoadingChangeStatus: false,
    orderHistory: history,
  })
}

const changeOrderStatusFailure = (state, { error }) => {
  return state.merge({
    uiLoadingChangeStatus: false,
    error: error,
  })
}

// POST Allocate/Deallocate
const adjustOrderItemsStockAttempt = state => {
  return state.merge({
    uiLoadingAdjust: true,
    error: INITIAL_STATE.error,
    errors: INITIAL_STATE.errors,
  })
}

const adjustOrderItemsStockSuccess = (state, { data, history }) => {
  return state.merge({
    uiLoadingAdjust: false,
    data: data,
    orderItems: propOr([], 'order_items', data),
    orderHistory: history,
  })
}

const adjustOrderItemsStockFailure = (state, { error, errors }) => {
  // TODO POST adjustOrderItemsStock errors ???
  const cleanedErrors = errors ? map(head, errors) : INITIAL_STATE.errors
  return state.merge({
    uiLoadingAdjust: false,
    error: error,
    errors: cleanedErrors,
  })
}

// GET Courier Services
const getCourierServicesAttempt = state => {
  return state.merge({
    uiLoadingCourier: true,
    courierServices: INITIAL_STATE.courierServices,
    // courierCollections: INITIAL_STATE.courierCollections,
    error: INITIAL_STATE.error,
    errors: INITIAL_STATE.errors,
  })
}

const getCourierServicesSuccess = (state, { courier, data, collections }) => {
  let services

  switch (courier) {
    case 'apc':
      services = map(service => ({
        code: prop('ProductCode', service),
        collectionDate: prop('CollectionDate', service),
        name: prop('ServiceName', service),
      }))(data)
    break
    case 'dhl':
      services = map(service => ({
        code: toString(prop('code', service)),
        name: prop('name', service),
      }))(data)
    break
    case 'dpd': {
      let expresspakServices = []
      let parcelServices = []
      pipe(
        map(service => {
          const product = path(['product', 'productDescription'], service)
          const code = path(['network', 'networkCode'], service)
          const name = path(['network', 'networkDescription'], service)

          if (
            contains('expresspak', toLower(product))
            && !service.invoiceRequired
          ) {
            expresspakServices.push({
              code: code,
              name: replace('EXPRESSPAK', 'EXPAK', name),
            })
          }

          if (
            equals(toLower(product), 'parcel')
            && !service.invoiceRequired
          ) {
            parcelServices.push({
              code: code,
              name: name,
            })
          }
        }),
        reject(isNil)
      )(data)
      services = !isEmpty(expresspakServices) ? expresspakServices : parcelServices
    }
    break
    case 'parcelforce':
      services = data
    break
    case 'packfleet':
      services = map(service => {
        const latestTime = prop('latestTime', service)
        const amount = prop('amount', service)
        let code = prop('serviceType', service)
        let name = 'Next Day (Anytime)'
        if (latestTime) {
          code = `${code}-${latestTime}`
          name = `Next Day (${latestTime})`
        }
        return {
          code: code,
          name: `${name} ${amount}`
        }
      })(data)
    break
    default:
      services = []
    break
  }

  return state.merge({
    uiLoadingCourier: false,
    courierServices: services,
    courierCollections: collections
  })
}

const getCourierServicesFailure = (state, { error, errors }) => {
  return state.merge({
    uiLoadingCourier: false,
    error: error,
    errors: errors,
  })
}

const resetCourierServices = (state, { data }) => {
  return state.merge({
    courierServices: INITIAL_STATE.courierServices,
    courierCollections: INITIAL_STATE.courierCollections,
  })
}

// POST Ship Order
const shipOrderAttempt = state => {
  return state.merge({
    uiLoadingShip: true,
    error: INITIAL_STATE.error,
    errors: INITIAL_STATE.errors,
  })
}

const shipOrderSuccess = (state, { data, shipment, history }) => {
  return state.merge({
    uiLoadingShip: false,
    data: data,
    orderItems: propOr([], 'order_items', data),
    orderItemsSelection: [],
    orderShipments: [
      shipment,
      ...state.orderShipments,
    ],
    orderHistory: history,
  })
}

const shipOrderFailure = (state, { error, errors }) => {
  return state.merge({
    uiLoadingShip: false,
    error: error,
    errors: errors,
  })
}

// GET Order Shipment Labels
const getOrderShipmentLabelsAttempt = state => {
  return state.merge({
    uiLoadingLabels: true,
    error: INITIAL_STATE.error,
    errors: INITIAL_STATE.errors,
  })
}

const getOrderShipmentLabelsSuccess = state => {
  return state.merge({
    uiLoadingLabels: false,
  })
}

const getOrderShipmentLabelsFailure = (state, { error }) => {
  return state.merge({
    uiLoadingLabels: false,
    error: error,
  })
}

// GET Order Shipment Documents
const getOrderShipmentDocumentsAttempt = state => {
  return state.merge({
    uiLoadingDocuments: true,
    error: INITIAL_STATE.error,
    errors: INITIAL_STATE.errors,
  })
}

const getOrderShipmentDocumentsSuccess = state => {
  return state.merge({
    uiLoadingDocuments: false,
  })
}

const getOrderShipmentDocumentsFailure = (state, { error }) => {
  return state.merge({
    uiLoadingDocuments: false,
    error: error,
  })
}

// SYNC Order
const syncOrderAttempt = state => {
  return state.merge({
    uiLoadingSync: true,
  })
}

const syncOrderSuccess = state => {
  return state.merge({
    uiLoadingSync: false,
  })
}

const syncOrderFailure = state => {
  return state.merge({
    uiLoadingSync: false,
  })
}

// DELETE Order
const deleteOrderAttempt = state => {
  return state.merge({
    uiLoadingDelete: true,
  })
}

const deleteOrderSuccess = state => INITIAL_STATE

const deleteOrderFailure = (state, { error }) => {
  return state.merge({
    uiLoadingDelete: false,
    error: error,
  })
}

// Reset reducer
const resetOrderState = () => INITIAL_STATE

// map our types to our handlers
const ACTION_HANDLERS = {
  // GET Order
  [Types.GET_ORDER_ATTEMPT]: getOrderAttempt,
  [Types.GET_ORDER_SUCCESS]: getOrderSuccess,
  [Types.GET_ORDER_FAILURE]: getOrderFailure,
  [Types.SET_ORDER_GRID_STATE]: setOrderGridState,
  // POST Change Order Status
  [Types.CHANGE_ORDER_STATUS_ATTEMPT]: changeOrderStatusAttempt,
  [Types.CHANGE_ORDER_STATUS_SUCCESS]: changeOrderStatusSuccess,
  [Types.CHANGE_ORDER_STATUS_FAILURE]: changeOrderStatusFailure,
  // POST Allocate/Deallocate
  [Types.ADJUST_ORDER_ITEMS_STOCK_ATTEMPT]: adjustOrderItemsStockAttempt,
  [Types.ADJUST_ORDER_ITEMS_STOCK_SUCCESS]: adjustOrderItemsStockSuccess,
  [Types.ADJUST_ORDER_ITEMS_STOCK_FAILURE]: adjustOrderItemsStockFailure,
  // GET Courier Services
  [Types.GET_COURIER_SERVICES_ATTEMPT]: getCourierServicesAttempt,
  [Types.GET_COURIER_SERVICES_SUCCESS]: getCourierServicesSuccess,
  [Types.GET_COURIER_SERVICES_FAILURE]: getCourierServicesFailure,
  [Types.RESET_COURIER_SERVICES]: resetCourierServices,
  // POST Ship Order
  [Types.SHIP_ORDER_ATTEMPT]: shipOrderAttempt,
  [Types.SHIP_ORDER_SUCCESS]: shipOrderSuccess,
  [Types.SHIP_ORDER_FAILURE]: shipOrderFailure,
  // GET Order Shipment Labels
  [Types.GET_ORDER_SHIPMENT_LABELS_ATTEMPT]: getOrderShipmentLabelsAttempt,
  [Types.GET_ORDER_SHIPMENT_LABELS_SUCCESS]: getOrderShipmentLabelsSuccess,
  [Types.GET_ORDER_SHIPMENT_LABELS_FAILURE]: getOrderShipmentLabelsFailure,
  // GET Order Shipment Documents
  [Types.GET_ORDER_SHIPMENT_DOCUMENTS_ATTEMPT]: getOrderShipmentDocumentsAttempt,
  [Types.GET_ORDER_SHIPMENT_DOCUMENTS_SUCCESS]: getOrderShipmentDocumentsSuccess,
  [Types.GET_ORDER_SHIPMENT_DOCUMENTS_FAILURE]: getOrderShipmentDocumentsFailure,
  // SYNC Order
  [Types.SYNC_ORDER_ATTEMPT]: syncOrderAttempt,
  [Types.SYNC_ORDER_SUCCESS]: syncOrderSuccess,
  [Types.SYNC_ORDER_FAILURE]: syncOrderFailure,
  // DELETE Order
  [Types.DELETE_ORDER_ATTEMPT]: deleteOrderAttempt,
  [Types.DELETE_ORDER_SUCCESS]: deleteOrderSuccess,
  [Types.DELETE_ORDER_FAILURE]: deleteOrderFailure,
  // Reset reducer
  [Types.RESET_ORDER_STATE]: resetOrderState,
}

export default createReducer(INITIAL_STATE, ACTION_HANDLERS)
